import React from 'react';
import { Component, Fragment } from 'react';

import { Collapse, ListGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarList from './SidebarList';

class SidebarItemGroup extends Component {
    
    render() {
        const { depth, menu, current, event, trigger } = this.props;

        return (
            <Fragment>
                <ListGroup.Item action className={["list-group-item", (depth > 0 && 'bg-light')].join(' ')} onClick={event}>
                    <span className={"d-flex justify-content-between align-items-center"}>
                        <span className={`pl-${depth || 0}`}>{menu.name}</span>
                        <FontAwesomeIcon icon={current === menu.id ? "chevron-up" : "chevron-down"} />
                    </span>
                </ListGroup.Item>
                <Collapse in={current === menu.id} id={`submenu-${menu.id}`}>
                    <ListGroup.Item className="p-0">
                        <SidebarList depth={depth + 2} menus={menu.items} trigger={trigger} />
                    </ListGroup.Item>
                </Collapse>
            </Fragment>
        )
    }
}

export default SidebarItemGroup;
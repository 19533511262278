import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import NavigationBreadcrumb from '../../../../navigation/NavigationBreadcrumb';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import BarraAcessibilidade from '../BarraAcessibilidade/barraAcessibilidade';
import Footer from '../Footer/footer';
import HeaderPortal from '../Header/header';
import './grupoCategoria.scss';

export default function GrupoCategoria() {
  const { grupoNomeUrl } = useParams();
  const currentlyLocation = useLocation();
  const { nomeUrl, todasCategorias, portal, grupoComCategoria, portalCliente } = useCliente();
  const [categoriasDoGrupo, setCategoriasDoGrupo] = useState(null);
  const [nomeDoGrupo, setNomeDoGrupo] = useState(null);
  const [location, setLocation] = useState();

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentlyLocation]);

  useEffect(() => {
    let categorias = [];
    todasCategorias &&
      todasCategorias.forEach((categoria) => {
        if (categoria.grupoCategoriaPublicacao.nomeUrl === grupoNomeUrl) {
          categorias.push(categoria);
        }
      });
    if (categorias.length > 0) {
      setNomeDoGrupo(categorias[0].grupoCategoriaPublicacao.nome);
      setCategoriasDoGrupo(categorias);
    }
  }, [todasCategorias, grupoNomeUrl]);

  return portal && grupoComCategoria && portalCliente ? (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />

      <Container className='p-0 m-0 pb-3' style={{ minHeight: '600px' }} fluid>
        {nomeDoGrupo && categoriasDoGrupo && (
          <Container className='content'>
            <section className='content-left'>
              <header>
                <h2 className='grupo-nome'>{nomeDoGrupo}</h2>
              </header>

              {categoriasDoGrupo
                .sort((a, b) => (a.ordemExibicao > b.ordemExibicao ? 1 : -1))
                .map((categoria, index) =>
                  categoria.listado ? (
                    categoria.linkExterno ? (
                      <a href={categoria.linkUrl} target='__blank' className='lista-categoria' key={index}>
                        <h6 className='categoria'>{categoria.nome}</h6>
                      </a>
                    ) : (
                      <NavLink
                        to={`${location}/detail/${grupoNomeUrl}/${categoria.nomeUrl}`}
                        className='lista-categoria'
                        key={index}
                      >
                        <h6 className='categoria'>{categoria.nome}</h6>
                      </NavLink>
                    )
                  ) : null
                )}
            </section>

            <section className='content-right'>
              <NavLink exact to={`/${nomeUrl}`} className='btn'>
                Voltar
              </NavLink>
            </section>
          </Container>
        )}
      </Container>
      <Footer />
    </>
  ) : (
    <Loading />
  );
}

import React, { Fragment, useState } from 'react';
import { Col, Form, FormLabel, Image, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [{ header: 'Nome do Layout', property: 'nome', filter: true }];

export default function LayoutPage() {
  const [img, setImg] = useState(false);

  const color = (handleChange) => {
    return (e) => {
      const inputName = e.target.name;
      const cor = e.target.value;
      handleChange({
        target: {
          name: inputName,
          value: cor,
        },
      });
    };
  };

  const handleData = (handleChange) => {
    return (file) => {
      handleChange({
        target: {
          name: 'logotipo',
          value: file.data.data,
        },
      });
      setImg(true);
    };
  };

  return (
    <Crud
      header='Layout'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/layout`}
      rows={20}
      schema={schema}
      form={({ state, handleChange, values, touched, isValid, errors }) => (
        <Fragment>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Brasão do Topo</FormLabel>
                <Dropzone route={`${config.api_path}/logo`} onUpload={handleData(handleChange)} />
                <Input.Text
                  id='topBrasao'
                  value={values.topBrasao}
                  onChange={handleChange}
                  isInvalid={errors.topBrasao}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='pageBgColor'
                    value={values.pageBgColor}
                    onChange={handleChange}
                    isInvalid={errors.pageBgColor}
                  />
                  <input name='pageBgColor' value={values.pageBgColor} type='color' onChange={color(handleChange)} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.pageBgColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da fonte da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='pageFontColor'
                    value={values.pageFontColor}
                    onChange={handleChange}
                    isInvalid={errors.pageFontColor}
                  />
                  <input
                    name='pageFontColor'
                    value={values.pageFontColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.pageFontColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor do topo da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='topBgColor'
                    value={values.topBgColor}
                    onChange={handleChange}
                    isInvalid={errors.topBgColor}
                  />
                  <input name='topBgColor' value={values.topBgColor} type='color' onChange={color(handleChange)} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.topBgColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor do fundo do botão de categoria</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='categoriaButtonBgColor'
                    value={values.categoriaButtonBgColor}
                    onChange={handleChange}
                    isInvalid={errors.categoriaButtonBgColor}
                  />
                  <input
                    name='categoriaButtonBgColor'
                    value={values.categoriaButtonBgColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.categoriaButtonBgColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da fonte do botão de categoria</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='categoriaButtonFontColor'
                    value={values.categoriaButtonFontColor}
                    onChange={handleChange}
                    isInvalid={errors.categoriaButtonFontColor}
                  />
                  <input
                    name='categoriaButtonFontColor'
                    value={values.categoriaButtonFontColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.categoriaButtonFontColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da fonte do texto do grupo categoria</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='grupoCategoriaLabelFontColor'
                    value={values.grupoCategoriaLabelFontColor}
                    onChange={handleChange}
                    isInvalid={errors.grupoCategoriaLabelFontColor}
                  />
                  <input
                    name='grupoCategoriaLabelFontColor'
                    value={values.grupoCategoriaLabelFontColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.grupoCategoriaLabelFontColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor do menu da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='navbarBgColor'
                    value={values.navbarBgColor}
                    onChange={handleChange}
                    isInvalid={errors.navbarBgColor}
                  />
                  <input
                    name='navbarBgColor'
                    value={values.navbarBgColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.navbarBgColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da fonte do menu da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='navbarFontColor'
                    value={values.navbarFontColor}
                    onChange={handleChange}
                    isInvalid={errors.navbarFontColor}
                  />
                  <input
                    name='navbarFontColor'
                    value={values.navbarFontColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.navbarFontColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor do footer da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='footerBgColor'
                    value={values.footerBgColor}
                    onChange={handleChange}
                    isInvalid={errors.footerBgColor}
                  />
                  <input
                    name='footerBgColor'
                    value={values.footerBgColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.footerBgColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Cor da fonte do footer da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='footerFontColor'
                    value={values.footerFontColor}
                    onChange={handleChange}
                    isInvalid={errors.footerFontColor}
                  />
                  <input
                    name='footerFontColor'
                    value={values.footerFontColor}
                    type='color'
                    onChange={color(handleChange)}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.footerFontColor}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome do portal ao topo da página</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='topNomePortal'
                    value={values.topNomePortal}
                    onChange={handleChange}
                    isInvalid={errors.topNomePortal}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.topNomePortal}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {img ? (
            <Row>
              <Col>
                <Image src={`${config.api_path}/logo`} roundedCircle width={300} />
              </Col>
            </Row>
          ) : (
            ''
          )}

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';

import { fetchSession } from '../actions';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mapStateToProperties = store => ({
    loading: store.sessionState.loading
});

const mapDispatchToProperties = dispatch => bindActionCreators({ fetchSession }, dispatch);

class OfflinePage extends Component {
    render () {
        const { loading, fetchSession } = this.props;

        return (
            <Loading.Container as={Container} loading={loading} fluid className="h-100 pt-5 bg-light">
                <h1 className="text-center display-4">
                    <span className="fa-layers fa-fw" style={{width: '1.5em'}}>
                        <FontAwesomeIcon icon="wifi" size="xs"/>
                        <FontAwesomeIcon icon="ban" size="lg" style={{color: 'tomato'}}/>
                    </span>
                    <span className="ml-2">Sem Conexão</span>
                </h1>
                <p className="lead text-center">
                    Desculpe a página que você está tentando acessar não pode ser exibida no momento.
                </p>
                <p className="text-center">
                    Houve uma falha de conexão com os nossos servidores, verifique sua conexão com a internet ou tente novamente mais tarde.
                </p>
                <div className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => fetchSession()}><FontAwesomeIcon icon="sync-alt"/> Tentar Novamente</Button>
                </div>
            </Loading.Container>
        );
    }
}

export default connect(mapStateToProperties, mapDispatchToProperties)(OfflinePage);
//Login Reducer
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_SESSION = 'SET_SESSION';
export const HANDLE_SESSION_ERROR = 'HANDLE_SESSION_ERROR';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const SET_CONTEXT_LOADING = 'SET_CONTEXT_LOADING';

//Person Reducer
export const LOAD_PERSON = 'LOAD_PERSON';
export const SET_PERSON = 'SET_PERSON';
export const PERSON_ERROR = 'PERSON_ERROR';
export const REMOVE_PERSON = 'REMOVE_PERSON';

//Config Reducer
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_SERVICEWORKER = 'UPDATE_SERVICEWORKER';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from '../../../../config';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import Foto from '../../../../sem_foto.png';
import {
  EstruturaOrganizacionalGestao,
  EstruturaOrganizacionalService,
} from '../utils/useServices';
import './estrutura.scss';

let nivelFinal = 1;

export default function EstruturaOrganizacional() {
  const { identificador, listaClientes } = useCliente();
  const [estruturaOrganizacional, setEstruturaOrganizacional] = useState();
  const [exibirCard, setExibirCard] = useState(false);
  // const [nivel, setNivel] = useState(0);
  const [dadosGestor, setDadosGestor] = useState({
    nome: '',
    cargo: '',
    id: 0,
  });
  const [estruturaOrganizacionalGestao, setEstruturaOrganizacionalGestao] =
    useState();

  useEffect(() => {
    const fetch = async () => {
      await EstruturaOrganizacionalService(identificador).then((res) =>
        setEstruturaOrganizacional(res.data.data)
      );
      await EstruturaOrganizacionalGestao(identificador).then((res) =>
        setEstruturaOrganizacionalGestao(res.data.data)
      );
    };
    fetch();
  }, [identificador]);

  const renderizarImg = (estrutura) => {
    return (
      estruturaOrganizacionalGestao &&
      estruturaOrganizacionalGestao.map((gestao) => {
        if (gestao.estruturaOrganizacional.id === estrutura.id) {
          return (
            <>
              <Row className='ml-2'>
                {/* <img
                  onMouseOver={() => {
                    const newDados = {
                      nome: gestao.nome,
                      cargo: gestao.cargo,
                      id: gestao.id,
                      estruturaId: estrutura.id,
                    };
                    setDadosGestor(newDados);
                    setExibirCard(true);
                  }}
                  onMouseOut={() => setExibirCard(false)}
                  className='img-gestor'
                  src={
                    gestao.nomeFoto
                      ? `${config.api_path}/public/v1/${identificador}/estrutura-organizacional-gestao/download/${gestao.id}`
                      : Foto
                  }
                  alt=''
                /> */}
                {exibirCard && dadosGestor.estruturaId === estrutura.id && (
                  <div>
                    <Card className='card-popup'>
                      <Card.Img
                        className='img-gestor'
                        variant='top'
                        src={
                          gestao.nomeFoto
                            ? `${config.api_path}/public/v1/${identificador}/estrutura-organizacional-gestao/download/${dadosGestor.id}`
                            : Foto
                        }
                      />
                      <Card.Body className='card-body'>
                        <Card.Title>{dadosGestor.nome}</Card.Title>
                        <Card.Text>Cargo: {dadosGestor.cargo}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Row>
            </>
          );
        }
        return null;
      })
    );
  };

  const renderizarFilhos = (estrutura, nivelInicial) => {
    const nivel = nivelInicial;
    const filhos = estruturaOrganizacional.filter(
      (subEstrutura) => subEstrutura.estruturaPai.id === estrutura.id
    );
    if (filhos.length === 0) {
      return null;
    }
    nivelFinal = nivel;
    return (
      <Col className='filho'>
        {filhos.map((subEstrutura) => (
          <Col key={subEstrutura.id} className='center'>
            <div
              className={`level-${nivelInicial} ${
                renderizarFilhos(subEstrutura, nivelInicial + 1) === null
                  ? 'wobefore'
                  : ''
              } rectangle`}
            >
              <b>{subEstrutura.nome}</b>
              {renderizarImg(subEstrutura)}
            </div>
            {renderizarFilhos(subEstrutura, nivelInicial + 1)}
          </Col>
        ))}
      </Col>
    );
  };

  const renderizarCliente = (cliente) => {
    if (estruturaOrganizacional) {
      const todosClientes = estruturaOrganizacional.map(
        (estrutura) => estrutura.cliente.id === cliente.id
      );

      if (todosClientes.some((cliente) => cliente)) {
        return true;
      }
    }
  };

  useEffect(
    () => console.log(estruturaOrganizacional),
    [estruturaOrganizacional]
  );

  return (
    <Container className='pt-4' fluid>
      <Container className='estrutura' fluid>
        <Container className='content-top p-0 m-0'>
          <h2>Estrutura Organizacional</h2>
        </Container>
        <Container className='content-mid p-0 m-0'>
          {Array.isArray(listaClientes) &&
          estruturaOrganizacional &&
          listaClientes ? (
            listaClientes.map((cliente) => {
              if (renderizarCliente(cliente)) {
                return (
                  <Col key={cliente.id}>
                    <h3>{cliente.nome}</h3>
                    {estruturaOrganizacional.map((estrutura, key) =>
                      estrutura.cliente.id === cliente.id &&
                      !estrutura.estruturaPai.hasOwnProperty('id') ? (
                        <Col key={key} className='ml-4'>
                          <Row className='gap-2'>
                            <h3>{estrutura.nome}</h3>
                            <Col>{renderizarImg(estrutura)}</Col>
                          </Row>
                          {renderizarFilhos(estrutura, 1)}
                        </Col>
                      ) : null
                    )}
                  </Col>
                );
              }
              return null;
            })
          ) : (
            <Col>
              <h3>{listaClientes.nome}</h3>
              {estruturaOrganizacional && listaClientes ? (
                estruturaOrganizacional.map((estrutura, key) =>
                  estrutura.cliente.id === listaClientes.id ? (
                    <Col key={key} className='ml-4'>
                      {!estrutura.estruturaPai.hasOwnProperty('id')
                        ? estrutura.nome
                        : ''}
                      {renderizarFilhos(estrutura, 0)}
                    </Col>
                  ) : (
                    ''
                  )
                )
              ) : (
                <></>
              )}
            </Col>
          )}
        </Container>
      </Container>
      <Container className='content-right'>
        <Link exact className='btn'>
          Voltar
        </Link>
      </Container>
    </Container>
  );
}

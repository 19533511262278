import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Axios from 'axios';

import { Container, Button } from 'react-bootstrap';
import { setContext } from '../actions'
import Input from '../components/Input';
import config from '../config';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mapStateToProperties = store => ({
    context: store.sessionState.context,
    loadingContext: store.sessionState.loadingContext
});

const mapDispatchToProperties = dispatch => bindActionCreators({ setContext }, dispatch);

class EscolherCliente extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            contexts: [],
            loading: false,
            selected: props.context,
            hadAClient: !!props.context,
            redirect: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const { selected } = this.state;
        
        const params = new URLSearchParams(location.search); 
        const client = params.get('client');
    
        this.setState({
            ...this.state,
            loading: true
        });

        Axios.get(`${config.api_path}/session/context`, {
            cancelToken: new Axios.CancelToken((c) => {
                this.cancelContextRequest = c;
            })
        }).then(res => {
            let json = res.data;
            let nSelected = null;

            if(json.data) {
                if(client) {
                    json.data.forEach((context) => {
                        if(context.client.identificador === client) nSelected = context;
                    });
                } else {
                    nSelected = selected || json.data[0];
                }
            }

            this.setState({
                ...this.state,
                contexts: json.data,
                selected: nSelected,
                loading: false,
                error: json.error,
                messages: json.messages
            }, () => {
                this.cancelContextRequest = undefined;
                if(this.state.contexts && (this.state.contexts.length === 1 || client)) {
                    this.handleClick();
                }
            });
        });
    }

    componentWillUnmount() {
        if(this.cancelContextRequest) this.cancelContextRequest("Operation canceled by the user");
    }

    handleChange(e) {
        this.setState({
            ...this.state,
            selected: e.target.value
        });
    }

    handleClick() {
        const { hadAClient } = this.state;
        this.props.setContext(this.state.selected, () => {
            if(hadAClient) {
                this.setState({
                    ...this.state,
                    redirect: true
                })
            }
        });
    }

    render () {
        const { contexts, selected, loading, redirect } = this.state;
        const { context, loadingContext } = this.props;

        if(redirect) return (
            <Redirect to="/" />
        )

        return (
            <Loading.Container as={Container} loading={loading || loadingContext} className="py-4">
                <h1 className="display-4">Selecione um Cliente</h1>
                {contexts && 
                    
                    <Input.Select id="pick-one-cliente" options={contexts} 
                        // eslint-disable-next-line
                        label='${client.cidade} - ${client.nome}'
                        value={selected} rowKey="client.id"
                        onChange={this.handleChange} disabled={loadingContext}
                    />
                }
                <Button variant="primary" block className="mt-4" size="lg" onClick={this.handleClick} disabled={!selected || loadingContext}>
                    {
                        context ? 
                        <span><FontAwesomeIcon icon="exchange-alt"/> Trocar</span>
                        :
                        <span><FontAwesomeIcon icon="sign-in-alt"/> Entrar</span>
                    }
                </Button>
            </Loading.Container>
        );
    }
}

export default withRouter(connect(mapStateToProperties, mapDispatchToProperties)(EscolherCliente));
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Form, Formik } from 'formik';
import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert';
import { Button, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import DownloadProgress from '../../../components/DownloadProgress';
import FormCol from '../../../components/FormCol';
import Input from '../../../components/Input';
import config from '../../../config';

const schema = Yup.object({
  documentoPessoa: Yup.string().required('Informe seu CPF ou CNPJ'),
  nomePessoa: Yup.string().required('Informe o seu nome ou razão social'),
});

class DocumentsModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    loading: false,
    documents: null,
    errors: null,

    pessoa: null,

    downloading: false,
    downloadLength: 0,
    downloadProgress: 0,
  };

  componentDidMount() {
    const { licitacao } = this.props;
    if (licitacao) this.fecthDocuments(licitacao);
  }

  componentDidUpdate(oldProps) {
    const { licitacao } = this.props;
    if (licitacao && oldProps.licitacao !== licitacao)
      this.fecthDocuments(licitacao);
  }

  async fecthDocuments(licitacao) {
    const { match } = this.props;
    await this.setState({ ...this.state, loading: true });
    try {
      let res = await Axios.get(
        `${config.api_path}/public/v1/${match.params.cliente}/licitacao/${licitacao.id}/documentos`
      );
      let json = res.data;
      if (!json.error) {
        await this.setState({
          ...this.state,
          documents: json.data,
        });
      } else {
        await this.setState({
          ...this.state,
          errors: json.messages,
        });
      }
    } catch (err) {
      await this.setState({
        ...this.state,
        errors: [{ code: 3000, description: err.message }],
      });
    } finally {
      await this.setState({
        ...this.state,
        loading: false,
      });
    }
  }

  handleSubmit(object) {
    this.setState({
      ...this.state,
      pessoa: object,
    });
  }

  async download(doc) {
    const { alert, match } = this.props;
    const { pessoa } = this.state;

    const url = `${config.api_path}/public/v1/${
      match.params.cliente
    }/licitacao/documento/${doc.id}?documento=${encodeURI(
      pessoa.documentoPessoa
    )}&nome=${encodeURI(pessoa.nomePessoa)}`;

    const filename = doc.arquivo.substring(
      doc.arquivo.lastIndexOf('/') + 1,
      doc.arquivo.lastIndexOf('.')
    );
    const type = doc.arquivo.substring(doc.arquivo.lastIndexOf('.') + 1);

    await this.setState({
      ...this.state,
      downloading: true,
    });

    let response = await fetch(url);
    const reader = response.body.getReader();
    const contentType = response.headers.get('Content-Type');
    const contentLength = +response.headers.get('Content-Length');
    let progress = 0;

    await this.setState({
      ...this.state,
      downloadLength: contentLength,
      downloadProgress: progress,
    });

    let chunks = [];

    while (true) {
      const { done, value } = await reader.read();

      if (done) break;

      chunks.push(value);
      progress += value.length;

      this.setState({
        ...this.state,
        downloadProgress: progress,
      });
    }

    if (contentType.indexOf('application/json') > -1) {
      let allChunks = new Uint8Array(progress);
      let position = 0;
      for (let chunk of chunks) {
        allChunks.set(chunk, position);
        position += chunk.length;
      }

      let result = new TextDecoder('utf-8').decode(allChunks);
      let json = JSON.parse(result);

      json.messages.forEach((msg) =>
        msg.code > 2999
          ? alert.error(msg.description)
          : msg.code > 1999
          ? alert.show(msg.description, { type: 'warning' })
          : msg.code > 999
          ? alert.show(msg.description)
          : null
      );
    } else {
      let blob = new Blob(chunks, { type: contentType });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }

      let data = window.URL.createObjectURL(blob);

      var link = document.createElement('a');
      link.href = data;
      link.download = `${filename}.${type}`;
      link.click();

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    }

    this.setState({
      ...this.state,
      downloading: false,
      downloadLength: 0,
      downloadProgress: 0,
    });
  }

  render() {
    const { licitacao, show, onHide } = this.props;
    const {
      pessoa,
      loading,
      errors,
      documents,
      downloading,
      downloadLength,
      downloadProgress,
    } = this.state;

    return (
      <Modal
        show={(show && !!licitacao) || downloading}
        onHide={downloading ? undefined : onHide}
        size='lg'
      >
        {licitacao && (
          <Fragment>
            <Modal.Header closeButton={!downloading}>
              <div className='flex-fill'>
                <h4 className='text-center'>
                  Comissão Permanente de Licitação. CPL
                </h4>
                <h5 className='text-center'>Solicitação de Edital</h5>
              </div>
            </Modal.Header>
            <Modal.Body>
              {!pessoa ? (
                <Formik validationSchema={schema} onSubmit={this.handleSubmit}>
                  {({ handleSubmit, handleChange, errors, values }) => (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                      <Row>
                        <FormCol
                          id='documentoPessoa'
                          formLabel='CPF/CNPJ'
                          xs={12}
                          md={6}
                          component={Input.Mask}
                          maskChar={''}
                          mask={
                            !values.documentoPessoa ||
                            values.documentoPessoa.replace(/\D/g, '').length <=
                              11
                              ? '999.999.999-999'
                              : '99.999.999/9999-99'
                          }
                          values={values}
                          onChange={handleChange}
                          errors={errors}
                        />
                        <FormCol
                          id='nomePessoa'
                          formLabel='Nome'
                          xs={12}
                          md={6}
                          component={Input.Text}
                          values={values}
                          onChange={handleChange}
                          errors={errors}
                        />
                      </Row>
                      <Row>
                        <Col className='d-flex w-100 justify-content-end'>
                          <Button type='submit'>Confirmar</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              ) : (
                <ListGroup>
                  {loading ? (
                    <ListGroup.Item>
                      <FontAwesomeIcon icon='cog' spin /> Carregando...
                    </ListGroup.Item>
                  ) : documents && documents.length > 0 ? (
                    documents.map((doc, i) => (
                      <ListGroup.Item
                        key={i}
                        className='d-flex w-100 align-items-center justify-content-between'
                      >
                        <div>
                          <div>
                            <strong>{doc.nome}</strong>
                          </div>
                          <div>
                            <small className='ml-2 text-muted'>
                              Data de publicação:{' '}
                              {new Date(
                                doc.dataHoraPublicacao
                              ).toLocaleString()}
                            </small>
                          </div>
                        </div>
                        <Button
                          disabled={downloading}
                          onClick={() => this.download(doc)}
                        >
                          <FontAwesomeIcon icon='download' /> Download
                        </Button>
                      </ListGroup.Item>
                    ))
                  ) : errors && errors.length > 0 ? (
                    errors.map((err, i) => (
                      <ListGroup.Item key={i}>{err.description}</ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item className='text-muted'>
                      Nenhum documento publicado
                    </ListGroup.Item>
                  )}
                </ListGroup>
              )}
              {downloading && (
                <div className='mt-2'>
                  <DownloadProgress
                    variant='primary'
                    animated
                    max={downloadLength}
                    now={downloadProgress}
                  />
                </div>
              )}
            </Modal.Body>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default withAlert()(withRouter(DocumentsModal));

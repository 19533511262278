import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';

class FormCol extends Component {

    valueOf(property, object) {
        var ppts = property.split('.');        
        var result = object;
        for(var i = 0; i < ppts.length; i++) {
            result = result[ppts[i]];
            if(!result) return null;
        }
        return result;
    }

    render () {
        const { id, name, formLabel, className, component : Component,  //Field Properties
            xs, sm, md, lg, xl, //Column Sizes
            values, errors, handleChange, handleBlur, //FormikBag
            ...rest} = this.props; //Others

        return (
            <Form.Group as={Col} {...{xs, sm, md, lg, xl}} >
                {formLabel && <Form.Label>{formLabel}</Form.Label> }
                <Component id={id} name={name} onChange={handleChange} onBlur={handleBlur} value={this.valueOf(id||name, values) || ''}
                    className={`${className} ${this.valueOf(id||name, errors) && 'is-invalid'}`} {...rest}/>
                <Form.Control.Feedback type="invalid">{this.valueOf(id||name, errors)}</Form.Control.Feedback>
            </Form.Group>
        );
    }
}

export default FormCol;
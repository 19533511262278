import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';

import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import AlertTemplate from './utils/AlertTemplate';

import { HashRouter as Router } from 'react-router-dom';

import { buildLibrary } from './utils/fa.librarby';

import { updateServiceWorker } from './actions';
import './components/theme/systema.scss';

buildLibrary();

const alertOptions = {
  offset: '.6rem',
  timeout: 5000,
  transition: transitions.FADE,
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AlertProvider
        position={positions.TOP_RIGHT}
        {...alertOptions}
        template={AlertTemplate}
        containerStyle={{ marginTop: '.6rem', marginRight: '.6rem', zIndex: 9999 }}
      >
        <Router hashType={'slash'}>
          <App />
        </Router>
      </AlertProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//     immediate: true,
//     onUpdate: registration => {
//         const waitingServiceWorker = registration.waiting;
//         if(waitingServiceWorker) {
//             waitingServiceWorker.addEventListener("statechange", event => {
//                 if (event.target.state === "activated") {
//                     store.dispatch(updateServiceWorker(true));
//                 }
//             });
//             waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//         }
//     }
// });

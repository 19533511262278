import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSession } from './actions';

import { routes } from './Routes';
import InstallPromotion from './components/InstallPromotion';
import Menu from './components/Menu';
import { buildMenuTree } from './menu';

import config from './config';
import LogoutPage from './pages/LogoutPage';
import NoClientPage from './pages/NoClientPage';
import PublicRouter from './pages/PublicRouter';
import InscricaoPage from './pages/public/InscricaoPage';
import protectComponent from './utils/protectComponent';
import MaintenancePage from './pages/public/MaintenancePage';

const mapStateToProperties = (store) => ({
  isAuthenticated: store.sessionState.isAuthenticated,

  context: store.sessionState.context,
  user: store.sessionState.user,
  isOffline: store.sessionState.isOffline,

  error: store.sessionState.error,

  config: store.configState,
});

const mapDispatchToProperties = (dispatch) =>
  bindActionCreators({ fetchSession }, dispatch);

class App extends Component {
  constructor(props) {
    super(props);
    this.routes = [...routes];
  }

  componentWillMount() {
    this.props.fetchSession();
  }

  componentDidMount() {
    const { context } = this.props;
    this.refreshRoutes(context);

    if (this.props.config.darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.props.fetchSession();
    }

    if (this.props.context !== nextProps.context) {
      const { context } = nextProps;
      this.refreshRoutes(context);
    }

    if (this.props.config !== nextProps.config) {
      if (nextProps.config.darkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }
  }

  refreshRoutes(context) {
    this.routes = [...routes];
    if (context && context.menus) {
      context.menus.forEach((menu) => {
        if (
          menu.acaoSistema &&
          menu.acaoSistema.solicitacao &&
          menu.acaoSistema.solicitacao.indexOf('.xhtml') > 0
        ) {
          this.routes.push({
            path: `/${menu.acaoSistema.solicitacao}`,
            iframe: `${config.jsf_path}/${menu.acaoSistema.solicitacao}`,
          });
        }
      });
    }
  }

  render() {
    const { error, isAuthenticated, context, user, isOffline } = this.props;

    return (
      <Fragment>
        <Switch>
          <Route path='/gestao'>
            <Menu
              home='#/gestao'
              header='Transparência'
              menus={buildMenuTree(isAuthenticated, context, user)}
            >
              {this.routes.map(
                ({
                  path,
                  component: Component,
                  exact,
                  isPrivate,
                  requiresContext,
                  allowOffline,
                }) => (
                  <Route path={path} key={path} exact={exact}>
                    {({ match }) => (
                      <CSSTransition
                        in={match !== null}
                        classNames='page'
                        timeout={300}
                        unmountOnExit
                      >
                        {protectComponent(Component, {
                          route: { isPrivate, requiresContext, allowOffline },
                          session: { context, isAuthenticated, isOffline },
                        })}
                      </CSSTransition>
                    )}
                  </Route>
                )
              )}
            </Menu>
          </Route>

          <Route path='/logout' component={LogoutPage} />
          <Route path='/' exact component={NoClientPage} />
          <Route path='/inscricao' component={InscricaoPage} />
          <Route path='/maintenance' component={MaintenancePage} />
          <Route path='/:cliente' component={PublicRouter} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProperties, mapDispatchToProperties)(App)
);

import React from 'react';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SidebarItem extends Component {
    render() {
        const { depth, menu, trigger } = this.props;

        if(menu.href) {
            return (
                <a id={menu.id} href={menu.href} onClick={trigger} className={["list-group-item list-group-item-action", (depth > 0 && 'bg-light')].join(' ')} >
                    <span className={"d-flex justify-content-between align-items-center"}>
                        <span className={`pl-${depth || 0}`}>{menu.name}</span>
                        {menu.icon && <FontAwesomeIcon icon={menu.icon}/>}
                    </span>
                </a>
            )
        } else if(menu.to) {
            return (
                <NavLink id={menu.id} to={menu.to} onClick={trigger} className={["list-group-item list-group-item-action", (depth > 0 && 'bg-light')].join(' ')} activeClassName="selected">
                    <span className={"d-flex justify-content-between align-items-center"}>
                        <span className={`pl-${depth || 0}`}>{menu.name}</span>
                        {menu.icon && <FontAwesomeIcon icon={menu.icon}/>}
                    </span>
                </NavLink>
            )
        } else return null;

    }
}

export default SidebarItem;
import React from 'react';

const Switch = ({ id, label, variant, alignRight, className, value, onChange, onSwitch, ...rest}) => {
    
    function handleChange(e) {
        console.log(e.target);
        console.log(e.target.value);

        if(onChange) onChange(e);
        if(onSwitch) onSwitch(!value, e);
    }

    return (
        <div className={`switch switch-${variant || 'primary'} align-${alignRight ? 'right' : 'left'}`}>
            <input id={id} type="checkbox" className={`switch-input ${className}`} checked={value || undefined} value={value} onChange={handleChange} {...rest} />
            <label htmlFor={id} className="switch-label">{label}</label>
        </div>
    );
};

export default Switch;
import axios from 'axios';
import config from '../../../../config';

export const CategoriaPublicacao = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/categoria_publicacao`;
  return axios.get(url);
};

export const CategoriaPublicacaoTipo = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/categoria_publicacao_tipo`;
  return axios.get(url);
};

export const GrupoCategoriaPublicacao = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/grupo_categoria_publicacao`;
  return axios.get(url);
};

export const Publicacao = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/publicacao`;
  return axios.get(url);
};

export const PublicacaoArquivo = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/publicacao_arquivo`;
  return axios.get(url);
};

export const EstruturaOrganizacionalGestao = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/estrutura-organizacional-gestao`;
  return axios.get(url);
};

export const EstruturaOrganizacionalService = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/estrutura-organizacional`;
  return axios.get(url);
};

export const PortalClienteService = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/transparencia/portal_cliente`;
  return axios.get(url);
};

export const FaqService = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/faq`;
  return axios.get(url);
};

export const DeclaracaoInexistenciaService = (identificador) => {
  const url = `${config.api_path}/public/v1/${identificador}/declaracao-inexistencia`;
  return axios.get(url);
};

import Axios from "axios";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Col, Form, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import Crud from "../../../components/Crud"
import Input from "../../../components/Input"
import config from '../../../config'
import schema from "./schema"

const columns = [
    { header: "Nome", property: "nome", filter: true },
]


export default function ModeloPortalPage() {

    return (
        <Crud
            header="Modelo de Portal"
            columns={columns}
            path={`${config.api_path}/v1/transparencia/modelo/portal`}
            rows={20}
            schema={schema}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>

                    <Row>
                        <Col md={5}>
                            <Form.Group>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nome" value={values.nome} onChange={handleChange} isInvalid={errors.nome} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>

            )}

        ></Crud>
    )
}
import React from 'react';
import LoginPage from '../pages/LoginPage';
import EscolherClientePage from '../pages/EscolherClientePage';
import OfflinePage from '../pages/OfflinePage';

export default (Component, options) => {
    if(!options.route.allowOffline && options.session.isOffline) return <OfflinePage />

    if(options.route.isPrivate && !options.session.isAuthenticated) return <LoginPage />
    if(options.route.requiresContext && !options.session.context) return <EscolherClientePage />

    return <Component />;
}
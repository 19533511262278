import React, { Fragment } from 'react';
import { Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Codigo', property: 'codigo', filter: true },
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'Estrutura pai', property: 'estruturaPai.nome', filter: true },
  { header: 'Cliente', property: 'cliente.nome', filter: true },
];

export default function EstruturaOrganizacionalPage() {
  return (
    <Crud
      header='Estrutra organizacional'
      columns={columns}
      path={`${config.api_path}/v1/estrutura-organizacional`}
      rows={20}
      schema={schema}
      form={({ state, handleChange, values, touched, isValid, errors }) => (
        <Fragment>
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Cliente</FormLabel>
                <Input.AsyncSelect
                  id='clienteGlobal'
                  value={values.clienteGlobal}
                  onChange={handleChange}
                  isInvalid={errors.clienteGlobal}
                  path={`${config.api_path}/v1/transparencia/clientesiorconp`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um Cliente'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.clienteGlobal}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Estrutura pai</FormLabel>
                <Input.AsyncSelect
                  id='estruturaPai'
                  value={values.estruturaPai}
                  onChange={handleChange}
                  isInvalid={errors.estruturaPai}
                  path={`${config.api_path}/v1/estrutura-organizacional`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma estrutura pai'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.estruturaPai}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group>
                <FormLabel>Codigo</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='codigo'
                    value={values.codigo}
                    onChange={handleChange}
                    isInvalid={errors.codigo}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.codigo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleChange}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { NavLink, useRouteMatch } from 'react-router-dom';
import BarraAcessibilidade from '../PortalTransparenciaPage/BarraAcessibilidade/barraAcessibilidade';
import Footer from '../PortalTransparenciaPage/Footer/footer';
import HeaderPortal from '../PortalTransparenciaPage/Header/header';
import NavigationBreadcrumb from '../../../navigation/NavigationBreadcrumb';

export default function ServicoPage() {
  const match = useRouteMatch();

  return (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='mt-4 mb-4' style={{ minHeight: '700px' }}>
        <h2 className='display-4 text-center'>Carta de Serviço</h2>
        <p className='lead text-justify'>
          A <b>Carta de Serviços</b> ao Cidadão é um documento elaborado por
          uma organização pública que visa informar aos cidadãos quais os
          serviços prestados por ela, como acessar e obter esses serviços e
          quais são os compromissos com o atendimento e os padrões de
          atendimento estabelecidos.
        </p>
        <h3>O que é carta de serviços? </h3>
        <p className='text-justify'>
          A <b>Carta de Serviços</b> informa ao público quais são os serviços
          prestados ao cidadão por uma determinada organização pública,
          especificando padrões de qualidade como tempo de espera para o
          atendimento, prazos para o provimento dos serviços, orientações
          quanto aos mecanismos de comunicação com os usuários, procedimentos
          para acolhimento de sugestões e para o recebimento, atendimento e
          gestão de reclamações.
        </p>
        <p className='text-justify'>
          Além de ser um poderoso instrumento de cidadania a{' '}
          <b>Carta de Serviços</b> entende que quanto mais informações
          disponíveis, mais facilmente o cidadão consegue reivindicar seus
          direitos. A <b>Carta de Serviços</b> desencadeia inevitáveis
          mudanças dentro das organizações públicas, que passam a trabalhar
          sob a perspectiva de melhoria contínua. Para oferecer serviços de
          qualidade e sem complicação, muitas vezes essas organizações
          precisam rever seus processos de trabalho, eliminando etapas
          desnecessárias e otimizando aquelas de que não se pode prescindir. A{' '}
          <b>Carta de Serviços</b> é, portanto, poderoso instrumento de
          indução da melhoria de gestão das organizações, tendo por objetivo
          informar o cidadão dos serviços prestados pelo órgão ou entidade,
          das formas de acesso a esses serviços e dos respectivos compromissos
          e padrões de qualidade de atendimento ao público, devendo trazer
          informações claras e precisas em relação a cada um dos serviços
          prestados, em especial as relacionadas com: o serviço oferecido; os
          requisitos, documentos e informações necessários para acessar o
          serviço; as principais etapas para processamento do serviço; o prazo
          máximo para a prestação do serviço; a forma de prestação do serviço;
          a forma de comunicação com o solicitante do serviço; e os locais e
          formas de acessar o serviço.
        </p>

        <ListGroup>
          <ListGroup.Item action as={NavLink} to={`${match.url}/estrutura`}>
            <div className='d-flex w-100 justify-content-between align-items-center'>
              <span>Ver Carta de Serviços</span>
              <FontAwesomeIcon icon={'arrow-right'} />
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Container>
      <Footer />
    </>
  )
}

// class ServicoPage extends Component {
//   state = {
//     estruturas: [],
//     loading: false,
//     cliente: {},
//   };

//   componentDidMount() {
//     this.fetchUnidades();
//   }

//   async fetchUnidades() {
//     const { match } = this.props;

//     await this.setState({ ...this.state, loading: true });

//     let url = `${config.api_path}/public/v1/${match.params.cliente}/servico/estrutura`;
//     await PortalClienteHook(match.params.cliente).then((res) =>
//       this.setState({ ...this.state, cliente: res.data.data[0] })
//     );

//     try {
//       let res = await Axios.get(url);

//       if (!res.data.error) {
//         this.setState({
//           ...this.state,
//           estruturas: res.data.data,
//         });
//       }
//     } catch (e) {
//       console.log(e);
//     } finally {
//       await this.setState({ ...this.state, loading: false });
//     }
//   }

//   render() {
//     const { match } = this.props;
//     console.log(this.state.cliente);
//     return (
//       <>
//         <BarraAcessibilidade />
//         <HeaderPortal />
//         <Container className='mt-4 mb-4'>
//           <h2 className='display-4 text-center'>Carta de Serviço</h2>
//           <p className='lead text-justify'>
//             A <b>Carta de Serviços</b> ao Cidadão é um documento elaborado por
//             uma organização pública que visa informar aos cidadãos quais os
//             serviços prestados por ela, como acessar e obter esses serviços e
//             quais são os compromissos com o atendimento e os padrões de
//             atendimento estabelecidos.
//           </p>
//           <h3>O que é carta de serviços? </h3>
//           <p className='text-justify'>
//             A <b>Carta de Serviços</b> informa ao público quais são os serviços
//             prestados ao cidadão por uma determinada organização pública,
//             especificando padrões de qualidade como tempo de espera para o
//             atendimento, prazos para o provimento dos serviços, orientações
//             quanto aos mecanismos de comunicação com os usuários, procedimentos
//             para acolhimento de sugestões e para o recebimento, atendimento e
//             gestão de reclamações.
//           </p>
//           <p className='text-justify'>
//             Além de ser um poderoso instrumento de cidadania a{' '}
//             <b>Carta de Serviços</b> entende que quanto mais informações
//             disponíveis, mais facilmente o cidadão consegue reivindicar seus
//             direitos. A <b>Carta de Serviços</b> desencadeia inevitáveis
//             mudanças dentro das organizações públicas, que passam a trabalhar
//             sob a perspectiva de melhoria contínua. Para oferecer serviços de
//             qualidade e sem complicação, muitas vezes essas organizações
//             precisam rever seus processos de trabalho, eliminando etapas
//             desnecessárias e otimizando aquelas de que não se pode prescindir. A{' '}
//             <b>Carta de Serviços</b> é, portanto, poderoso instrumento de
//             indução da melhoria de gestão das organizações, tendo por objetivo
//             informar o cidadão dos serviços prestados pelo órgão ou entidade,
//             das formas de acesso a esses serviços e dos respectivos compromissos
//             e padrões de qualidade de atendimento ao público, devendo trazer
//             informações claras e precisas em relação a cada um dos serviços
//             prestados, em especial as relacionadas com: o serviço oferecido; os
//             requisitos, documentos e informações necessários para acessar o
//             serviço; as principais etapas para processamento do serviço; o prazo
//             máximo para a prestação do serviço; a forma de prestação do serviço;
//             a forma de comunicação com o solicitante do serviço; e os locais e
//             formas de acessar o serviço.
//           </p>

//           <ListGroup>
//             <ListGroup.Item action as={NavLink} to={`${match.url}/estrutura`}>
//               <div className='d-flex w-100 justify-content-between align-items-center'>
//                 <span>Ver Carta de Serviços</span>
//                 <FontAwesomeIcon icon={'arrow-right'} />
//               </div>
//             </ListGroup.Item>
//           </ListGroup>
//         </Container>
//         <Footer />
//       </>
//     );
//   }
// }

// export default withRouter(ServicoPage);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';

import Input from '../../../components/Input';
import Switch from '../../../components/Input/Switch';
import config from '../../../config';

class ImportPage extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.import = this.import.bind(this);
  }

  state = {
    value: null,
    file: null,
    allowUnknownColumns: false,
    data: null,
    undMap: {},
  };

  componentDidMount() {}

  handleChange(e) {
    console.log(e.target.value);
    this.setState({
      ...this.state,
      value: e.target.value,
    });
    const reader = new FileReader();
    reader.onload = () => {
      let result = reader.result;
      let linhas = [];
      let start = 0;
      let str = false;
      for (let i = 0; i < result.length; i++) {
        let char = result[i];
        if (char === '"') str = !str;
        else if (char === '\n' && !str) {
          let linha = result.substring(start, i);
          let cells = [];
          let cellStart = 0;
          let cellStr = false;
          for (let j = 0; j < linha.length; j++) {
            let cellChar = linha[j];
            if (cellChar === '"') cellStr = !cellStr;
            else if (cellChar === ';' && !cellStr) {
              cells.push(linha.substring(cellStart, j));
              cellStart = j + 1;
            }
          }

          if (cells[1].trim() !== '') linhas.push(cells);
          start = i + 1;
        }
      }
      this.setState({
        ...this.state,
        data: linhas.map((row) => ({
          status: 'Pendente',
          nomeUnd: row[0],
          data: {
            estruturaOrganizacional: null,
            nome: row[1],
            descricao: row[2],
            exigencias: row[3],
            pagamentoTaxas: row[4],
            prazoAtendimento: row[5],
            formaAcompanhamento: '',
            agendamentoPrevio: false,
            atendimentoDomiciliar: false,
            documentos: [
              ...(row[6] && row[6].trim() !== ''
                ? row[6].split(',').map((doc) => ({
                    nome: doc,
                    obrigatorio: true,
                    observacao: '',
                  }))
                : []),
              ...(row[7] && row[7].trim() !== ''
                ? row[7].split(',').map((doc) => ({
                    nome: doc,
                    obrigatorio: false,
                    observacao: '',
                  }))
                : []),
            ],
            formasSolicitacao: [
              ...(row[8] && row[8].trim() !== ''
                ? [
                    {
                      valor: row[8],
                      tipoSolicitacao: { id: 1 },
                    },
                  ]
                : []),
              ...(row[9] && row[9].trim() !== ''
                ? row[9].split(',').map((tel) => ({
                    valor: tel,
                    tipoSolicitacao: { id: 2 },
                  }))
                : []),
              ...(row[10] && row[10].trim() !== ''
                ? row[10].split(',').map((mail) => ({
                    valor: mail,
                    tipoSolicitacao: { id: 3 },
                  }))
                : []),
              ...(row[11] && row[11].trim() !== ''
                ? [
                    {
                      valor: row[11],
                      tipoSolicitacao: { id: 4 },
                    },
                  ]
                : []),
              ...(row[12] && row[12].trim() !== ''
                ? [
                    {
                      valor: row[12],
                      tipoSolicitacao: { id: 5 },
                    },
                  ]
                : []),
            ],
          },
        })),
      });
      console.table(linhas);
    };
    reader.readAsText(e.target.files[0], 'UTF-8');
  }

  isUndMapeadas() {
    const { data } = this.state;
    if (!data) return false;
    return data.reduce(
      (prev, row) => prev && row.data.estruturaOrganizacional !== null,
      true
    );
  }

  listUnidades() {
    const { data } = this.state;
    return data
      .map((row) => row.nomeUnd)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  mapUnidades() {
    const { data, undMap } = this.state;
    let nData = data.map((row) => ({
      ...row,
      data: {
        ...row.data,
        estruturaOrganizacional: undMap[row.nomeUnd],
      },
    }));
    this.setState({
      ...this.state,
      data: nData,
    });
  }

  async import() {
    const { data } = this.state;
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      try {
        item.status = 'Enviando';
        let res = await Axios.post(`${config.api_path}/v1/servico`, item.data);
        console.log(res);
        item.status = 'Enviado';
      } catch (err) {
        item.status = 'Erro';
        item.error = err;
      }
      data[i] = item;

      this.setState({
        ...this.state,
        data: data,
      });
    }
  }

  render() {
    const { doReturn } = this.props;
    const { value, allowUnknownColumns, data, undMap } = this.state;

    return (
      <Fragment>
        {data && data.length > 0 ? (
          !this.isUndMapeadas() ? (
            <Fragment>
              <div className='d-flex w-100'>
                <Table>
                  {this.listUnidades().map((und) => (
                    <tr>
                      <th>{und}</th>
                      <td>
                        <Input.AsyncSelect
                          id={`unidade.${und}`}
                          path={`${config.api_path}/v1/estrutura-organizacional`}
                          //eslint-disable-next-line
                          label='${codigo} - ${nome}'
                          value={undMap[und]}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              undMap: {
                                ...undMap,
                                [und]: e.target.value,
                              },
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </Table>
              </div>
              <div className='d-flex w-100 justify-content-center my-2'>
                <Button onClick={() => this.mapUnidades()}>
                  Mapear Unidades
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className='d-flex w-100'>
                <Button onClick={() => this.import()}>
                  Iniciar Importação
                </Button>
              </div>
              <Table responsive bordered className='table-striped table-sm'>
                <tr>
                  <th>Indice</th>
                  <th>Status</th>
                  <th>Unidade</th>
                  <th>Serviço</th>
                </tr>
                {data.map((row, i) => (
                  <tr>
                    <td>{i}</td>
                    <td
                      className={
                        'text-nowrap ' +
                        (row.status === 'Pendente'
                          ? 'text-primary'
                          : row.status === 'Enviando'
                          ? 'text-warning'
                          : row.status === 'Enviado'
                          ? 'text-success'
                          : row.status === 'Erro'
                          ? 'text-danger'
                          : '')
                      }
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={
                            row.status === 'Pendente'
                              ? 'clock'
                              : row.status === 'Enviando'
                              ? 'sync'
                              : row.status === 'Enviado'
                              ? 'check'
                              : row.status === 'Erro'
                              ? 'times'
                              : ''
                          }
                        />{' '}
                        {row.status}
                      </div>
                    </td>
                    <td
                      className={
                        'text-nowrap ' +
                        (!row.data.estruturaOrganizacional && 'text-danger')
                      }
                    >
                      {row.data.estruturaOrganizacional ? (
                        <span>
                          {row.data.estruturaOrganizacional.codigo} -{' '}
                          {row.data.estruturaOrganizacional.nome}
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon icon='exclamation-circle' /> Requer
                          Mapeamento
                        </span>
                      )}
                    </td>
                    <td className='text-nowrap'>{row.data.nome}</td>
                  </tr>
                ))}
              </Table>
            </Fragment>
          )
        ) : (
          <Fragment>
            <Row>
              <Col xs={12}>
                <p>Envie um arquivo CSV com valores separados por ";"</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Input.File
                  onChange={this.handleChange}
                  value={value}
                  accept='.csv, .txt'
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={12}>
                <Switch
                  id='allow-unknown-columns'
                  label='Permitir colunas desconhecidas'
                  alignRight
                  value={allowUnknownColumns}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      allowUnknownColumns: !allowUnknownColumns,
                    })
                  }
                />
              </Col>
            </Row>
            <div className='d-flex w-100 justify-content-center my-2'>
              <Button variant='secondary'>
                <FontAwesomeIcon icon='reply' onClick={doReturn} /> Voltar
              </Button>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ImportPage;

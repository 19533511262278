/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Container } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { useCliente } from '../../../navigation/context/ClienteContext';
import BarraAcessibilidade from './BarraAcessibilidade/barraAcessibilidade';
import Footer from './Footer/footer';
import GroupCard from './GroupCard/groupCard';
import HeaderPortal from './Header/header';
import './styles.scss';

export default function PortalTransparenciaPage() {
  const { grupoComCategoria, grupoCategoria, portal, portalCliente } = useCliente();
  return portal && grupoComCategoria && portalCliente ? (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <Container className='page-color p-0 m-0 pt-4 pb-4' fluid>
        {grupoComCategoria && (
          <Container>
            <div className='radar-banner'>
              <a
                href='https://radardatransparencia.atricon.org.br/radar-da-transparencia-publica.html'
                target='_blank'
                rel='noopener noreferrer'
                className='radar'
              ></a>
            </div>
            <div className='main-card'>
              {Object.keys(grupoComCategoria).map((grupoNome, index) => (
                <GroupCard
                  key={index}
                  nomeGrupo={grupoNome}
                  grupoNomeUrl={grupoCategoria[grupoNome]}
                  categorias={grupoComCategoria[grupoNome]}
                />
              ))}
            </div>
          </Container>
        )}
      </Container>
      <Footer />
    </>
  ) : (
    <Loading />
  );
}

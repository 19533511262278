import React from 'react';
import { Component, Fragment } from 'react';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Navbar, Nav, Form, Dropdown, ListGroup, InputGroup } from 'react-bootstrap';

import SidebarItem from './SidebarItem';
import SidebarList from './SidebarList';

import NavbarItem from './NavbarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faInfoCircle, faLifeRing, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';

const mapStateToProperties = store => ({
    isAuthenticated: store.sessionState.isAuthenticated,
    context: store.sessionState.context,
    user: store.sessionState.user,
});

const indexMenus = (menus) => {
    let index = [];
    menus.forEach((menu) => {
        if(menu.to && !menu.hidden) index.push({realName: menu.name, name: menu.name, to: menu.to, icon: menu.icon});
        if(menu.items){
            indexMenus(menu.items).forEach(child => {
                index.push({realName: child.realName, name: `${menu.name} > ${child.name}`, to: child.to, icon: child.icon});
            });
        }
    });
    return index;
}

class Menu extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleSearch = this.handleSearch.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);

        this.state = {
            toggled: false,
            search: '',
            index: indexMenus(props.menus),
        }
    }


    componentDidUpdate(oldProps) {
        if(oldProps.menus !== this.props.menus){
            this.setState({
                ...this.state,
                index: indexMenus(this.props.menus),
            });
        } 
    }

    hideSidebar() {
        this.setState({
            ...this.state, 
            toggled: false
        });
    }

    pageTitle() {
        const { location } = this.props;
        const { index } = this.state;
        
        let best = "?";
        let bestLength = -1;
        index.forEach(menu => {
            if(menu.to === location.pathname) {
                best = menu.realName;
                return;
            }
            if(location.pathname.startsWith(menu.to) && menu.to.length > bestLength && menu.to.length <= location.pathname.length) {
                best = menu.realName;
                bestLength = menu.to.length;
            }
        });

        return best;
    }

    handleSearch(e) {
        this.setState({
            ...this.state,
            search: e.target.value
        });
    }

    filteredMenus(){
        const { index, search } = this.state;
        return index.filter((menu) => menu.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    }

    render () {
        const { toggled, search } = this.state;
        const { header, children, menus, location, context, user, isAuthenticated, append } = this.props;
        
        const hideMenu = location.search.indexOf('hidemenu') >= 0;
        
        return (
            <Fragment>
                <nav className="d-lg-none border-right bg-white" style={{...styles.sidebar, ...(toggled && styles.sidebarToggled)}}>
                    <Navbar variant="dark" className="header p-0 bg-header sticky-top" style={styles.navbar}>
                        <div className="noise"></div>
                        <Navbar.Brand className="header-content pl-2 text-white">{header}</Navbar.Brand>
                    </Navbar>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon="search" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control value={search} onChange={this.handleSearch} placeholder="Pesquisar" />
                            </InputGroup>
                        </ListGroup.Item>
                        { 
                            search.length <= 0
                            ? <SidebarList depth={0} menus={menus} trigger={this.hideSidebar} />
                            : this.filteredMenus().map((menu, i) => (
                                <SidebarItem key={menu.id} menu={menu} trigger={this.hideSidebar} />
                            ))
                        }
                    </ListGroup>
                </nav>
                <div>
                    <div style={{ display: 'none', ...(toggled && styles.containerOverlay)}} onClick={() => this.setState({...this.state, toggled: false})}></div>
                    { !hideMenu && 
                        <div className="p-0 fixed-top">
                            <div className="header bg-header">
                                <div className="noise"/>
                                <Navbar className="header-content w-100 text-white" variant="dark" style={styles.navbar}>
                                    
                                    <span className="d-flex w-100 d-lg-none justify-content-start align-items-center">
                                        <Button variant={"outline-light"} aria-label="Menu"
                                            onClick={() => {this.setState({...this.state, toggled: !toggled})}}>
                                            <FontAwesomeIcon icon="bars" />
                                        </Button>
                                        <Navbar.Brand className="flex-fill text-center"> { this.pageTitle() } </Navbar.Brand>
                                    </span>

                                    <span className="d-none d-lg-flex w-100">
                                        <Navbar.Brand href="#/" className="text-white">{header}</Navbar.Brand>
                                        <Nav className="flex-fill">
                                            { menus.filter(menu => (!menu.hidden && menu.to !== '/')).map(menu => <NavbarItem key={menu.id} menu={menu} />) }
                                            <Dropdown alignRight>
                                                <Dropdown.Toggle as={Nav.Link}>
                                                    Ajuda
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu rootCloseEvent="mousedown">
                                                    <div>
                                                        <InputGroup size="sm">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text><FontAwesomeIcon icon="search" /></InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control placeholder="Pesquisar" value={search} onChange={this.handleSearch}/>
                                                        </InputGroup>
                                                        { (search && search.length > 0) &&
                                                            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                                {
                                                                    this.filteredMenus().map((menu, i) => (
                                                                        <Dropdown.Item as={NavLink} key={i} exact to={menu.to}>
                                                                            {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name}
                                                                        </Dropdown.Item>
                                                                    ))
                                                                }
                                                                { this.filteredMenus().length === 0 && <Dropdown.Header>Nenhum menu encontrado</Dropdown.Header>}
                                                            </div>
                                                        }
                                                    </div>
                                                    { !(search && search.length > 0) &&
                                                        <>
                                                            <Dropdown.Divider/>
                                                            <Dropdown.Item as={NavLink} to="/help/instructions">
                                                                <FontAwesomeIcon icon={faLifeRing}/> Documentação
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={NavLink} to="/help/about">
                                                                <FontAwesomeIcon icon={faInfoCircle}/> Sobre
                                                            </Dropdown.Item>
                                                        </>
                                                    }   
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {append}
                                        </Nav>
                                        { context ?
                                            <Nav>
                                                <Dropdown>
                                                    <Dropdown.Toggle as={({ onClick }) => (
                                                        <Nav.Link onClick={onClick} dropdown className="p-0">
                                                            <div className="d-flex align-items-center">
                                                                <FontAwesomeIcon icon={faCaretDown}/>
                                                                <div className="ml-2">
                                                                    <small className="d-block">{context.client.nome} {context.exercicio ? '-' : ''} {context.exercicio}</small>
                                                                    <small className="d-block">{user.nome.split(' ').slice(0,3).join(' ')}</small>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    )} />
                                                    <Dropdown.Menu rootCloseEvent="mousedown">    
                                                        <div className="py-1 px-4">
                                                            <strong className="d-block">{user.nome}</strong>
                                                            <small className="d-block">
                                                                @{user.username}
                                                            </small>
                                                        </div>
                                                        <Dropdown.Divider/>
                                                        
                                                        <Dropdown.Item href="https://auth.systemainformatica.com.br/profile" target="_blank">
                                                            <FontAwesomeIcon icon={faUser}/> Alterar Senha/Usuário
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as={NavLink} to="/logout">
                                                            <FontAwesomeIcon icon={faSignOutAlt}/> Sair
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Nav>
                                            : isAuthenticated &&
                                            <>
                                                <Nav.Link as={NavLink} to="/logout">
                                                    <FontAwesomeIcon icon={faSignOutAlt}/> Sair
                                                </Nav.Link>
                                            </>
                                        }
                                    </span>

                                </Navbar>          
                            </div>
                        </div>           
                    }
                    {children && 
                        <main style={styles.container} >
                            {children}
                        </main>
                    }
                </div>
            </Fragment>
        );
    }
}

const styles = {

    sidebarContainer: {
        overflowX: 'hidden',
        height: '100%'
    },

    sidebar: {
        display: 'block',
        marginLeft: '-75vw',
        width: 400,
        maxWidth: '75vw',
        height: '100vh',
        overflowX: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        transition: 'all 0.3s',
        zIndex: 1300
    },

    sidebarToggled : {
        marginLeft: 0,
    },

    containerOverlay: {
        display: 'block',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        background: 'rgba(0, 0, 0, .5)',
        zIndex: 1200
    },

    navbar: {
        minHeight: 40,
        maxHeight: 40,
        height: 40,
        zIndex: 1100
    },
    
    container: {
        paddingTop: 40,
        height: '100vh'
    }

}

export default withRouter(connect(mapStateToProperties)(Menu));
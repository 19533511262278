import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchSession } from '../actions';

import logo from '../logomarca.png'
import config from '../config';
import { Col, Card, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mapStateToProperties = store => ({
    isAuthenticated: store.sessionState.isAuthenticated,
});

const mapDispatchToProperties = dispatch => bindActionCreators({ fetchSession }, dispatch);

class LoginPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.login = this.login.bind(this);
    }

    login() {
        const { fetchSession } = this.props;
        const width = 768;
        const height = 576;

        this.loginPopup = window.open(config.login_url, 'loginWin', `titlebar=no, width=${width}, height=${height}, location=no, top=${(window.screen.height/2) - (height/2)}, left=${(window.screen.width/2) - (width/2)}`);

        this.check = setInterval(() => {
            try {
                if(this.loginPopup.closed || this.loginPopup.location.href.startsWith(config.home)) {
                    if(!this.loginPopup.closed) this.loginPopup.close();
                    clearInterval(this.check);
                    fetchSession();
                }
            } catch(e) { }
        }, 200);
    }

    componentWillUnmount() {
        if(this.check) clearInterval(this.check);
        if(this.loginPopup && !this.loginPopup.closed) this.loginPopup.close();
    }

    render () {
        return (
            <Container className="d-block h-100">
                <div className="text-center py-5">
                    <img src={logo} alt="Systema Infromática"/>
                </div>
                <div className="d-flex justify-content-center">
                    <Col sm={8} lg={6}>
                        <Card className="w-100">
                            <Card.Body>
                                <Button variant="primary" size="lg" block className="mb-3" onClick={this.login} ><FontAwesomeIcon icon="sign-in-alt"/> Entrar</Button>
                                <Link className="btn btn-block btn-lg btn-secondary" to="/vincular-pessoa"><FontAwesomeIcon icon="user-plus"/> Cadastre-se</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
            </Container>
        );
    }
}
export default withRouter(connect(mapStateToProperties, mapDispatchToProperties)(LoginPage));
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';

class LicitacaoItem extends Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    const { onButtonClick } = this.props;
    if (onButtonClick) onButtonClick();
  }

  render() {
    const { licitacao, expand, onClick } = this.props;
    const dataHoraCertame = new Date(licitacao.dataHoraCertame);

    return (
      <Fragment>
        <ListGroup.Item
          action={!expand}
          className={expand && 'border-dark border-top'}
          onClick={expand ? undefined : onClick}
        >
          <Row style={{ fontSize: 18 }}>
            <Col
              xs={12}
              md={4}
              lg={6}
              className='d-flex d-md-block justify-content-between'
            >
              <strong>Modalidade:</strong>{' '}
              <span>{licitacao.modalidadeLicitacao.nome}</span>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={3}
              className='d-flex d-md-block justify-content-between'
            >
              <strong>Número:</strong>{' '}
              <span>
                {licitacao.numeroPorModalidade.toLocaleString('pt-br', {
                  minimumIntegerDigits: 3,
                })}
                /{dataHoraCertame.getFullYear()}
              </span>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={3}
              className='d-flex d-md-block justify-content-between'
            >
              <strong>Processo Nº:</strong>{' '}
              <span>
                {licitacao.numeroProcesso.toLocaleString('pt-br', {
                  minimumIntegerDigits: 3,
                })}
                /{dataHoraCertame.getFullYear()}
              </span>
            </Col>
          </Row>
          {expand ? (
            <Fragment>
              <Row>
                <Col>
                  <p className='text-justify'>
                    <strong>Objeto:</strong> {licitacao.objeto}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div>
                    <div>
                      <strong>Data do Certame:</strong>{' '}
                      {dataHoraCertame.toLocaleDateString()}
                    </div>
                    <div>
                      <strong>Hora do Certame:</strong>{' '}
                      {dataHoraCertame.toLocaleTimeString('pt-br', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}{' '}
                      Horas
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div>
                    <div>
                      <strong>Local do Certame</strong>
                    </div>
                    <div>
                      {licitacao.rua}, {licitacao.numero} - {licitacao.cep}
                    </div>
                    <div>
                      {licitacao.bairro} - {licitacao.cidade}/{licitacao.uf}
                    </div>
                    <div>{licitacao.complemento}</div>
                  </div>
                </Col>
              </Row>
              {licitacao.progoeiro && (
                <Fragment>
                  <hr />
                  <Row>
                    <Col>
                      <div>
                        <div>
                          <strong>Progoeiro</strong>
                        </div>
                        <div>{licitacao.progoeiro}</div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
              <hr />
              <Row>
                <Col>
                  <div>
                    <strong>{licitacao.comissaoLicitacao.nome}</strong>
                  </div>
                  {(licitacao.comissaoLicitacao.telefone1 ||
                    licitacao.comissaoLicitacao.telefone2) && (
                    <div>
                      <strong>Telefone</strong>{' '}
                      {licitacao.comissaoLicitacao.telefone1}
                      {licitacao.comissaoLicitacao.telefone1 &&
                        licitacao.comissaoLicitacao.telefone2 &&
                        '/'}
                      {licitacao.comissaoLicitacao.telefone2}
                    </div>
                  )}
                  {licitacao.comissaoLicitacao.email && (
                    <div>
                      <strong>Email</strong> {licitacao.comissaoLicitacao.email}
                    </div>
                  )}
                  {licitacao.comissaoLicitacao.fax && (
                    <div>
                      <strong>Fax</strong> {licitacao.comissaoLicitacao.fax}
                    </div>
                  )}
                </Col>
              </Row>
              {licitacao.observacao && (
                <Fragment>
                  <hr />
                  <Row>
                    <Col>
                      <p className='text-justify'>
                        <strong>Observação:</strong> {licitacao.observacao}
                      </p>
                    </Col>
                  </Row>
                </Fragment>
              )}
              <hr />
              <Row>
                <Col className='d-flex justify-content-end'>
                  <Button onClick={this.handleButtonClick}>
                    <FontAwesomeIcon icon={'download'} /> Solicitar Documentos
                  </Button>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col>
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <strong>Objeto:</strong> {licitacao.objeto}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <small className='text-muted'>Clique para expandir</small>
                </Col>
              </Row>
            </Fragment>
          )}
        </ListGroup.Item>
      </Fragment>
    );
  }
}

export default LicitacaoItem;

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import config from '../../../../config';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import NavigationBreadcrumb from '../../../../navigation/NavigationBreadcrumb';
import BarraAcessibilidade from '../BarraAcessibilidade/barraAcessibilidade';
import Footer from '../Footer/footer';
import HeaderPortal from '../Header/header';
import './pesquisa.scss';

export default function Pesquisa() {
  const { palavraChave } = useParams();
  const { grupoComCategoria, publicacoes, publicacaoArquivo, nomeUrl, identificador, grupoCategoria } = useCliente();
  const currentlyLocation = useLocation();
  const [location, setLocation] = useState();
  const [filtragem, setFiltragem] = useState({});
  const [listaArquivos, setListaArquivos] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
  }, [currentlyLocation]);

  useEffect(() => {
    if (grupoComCategoria) {
      const grupos = Object.keys(grupoComCategoria);
      const filtro = {};

      grupos.forEach((nomeGrupo) => {
        const categoriasFiltradas = new Set();

        grupoComCategoria[nomeGrupo].forEach((categoria) => {
          if (categoria.nome.toLowerCase().includes(palavraChave) && categoria.listado) {
            categoriasFiltradas.add(categoria);
          }
        });
        if (categoriasFiltradas.size > 0) {
          filtro[nomeGrupo] = filtro[nomeGrupo] || new Set();
          categoriasFiltradas.forEach((categoria) => {
            filtro[nomeGrupo].add(categoria);
          });
        }
      });

      publicacoes.forEach((publicacao) => {
        if (publicacao.tipoPublicacao.nome === 'Arquivo') {
          publicacaoArquivo.forEach((arquivos) => {
            if (arquivos.publicacao.id === publicacao.id && arquivos.nome.toLowerCase().includes(palavraChave)) {
              grupos.forEach((nomeGrupo) => {
                const categoriasFiltradas = new Set();
                grupoComCategoria[nomeGrupo].forEach((categoria) => {
                  if (categoria.nome === arquivos.publicacao.categoriaPublicacao.nome && categoria.listado) {
                    categoriasFiltradas.add(categoria);
                  }
                });
                if (categoriasFiltradas.size > 0) {
                  filtro[nomeGrupo] = filtro[nomeGrupo] || new Set();
                  categoriasFiltradas.forEach((categoria) => {
                    filtro[nomeGrupo].add(categoria);
                  });
                }
              });
            }
          });
        }
      });

      Object.keys(filtro).forEach((grupo) => (filtro[grupo] = [...filtro[grupo]]));
      setFiltragem(filtro);
    }
  }, [palavraChave, grupoComCategoria, publicacaoArquivo, publicacoes]);

  const pdfs = (categoriaNomeUrl) => {
    const publicacaoFilter = {};

    publicacoes.forEach((publicacao) => {
      if (categoriaNomeUrl === publicacao.categoriaPublicacao.nomeUrl) {
        if (publicacao.tipoPublicacao.nome === 'Arquivo') {
          publicacaoArquivo &&
            publicacaoArquivo.forEach((arquivos) => {
              if (arquivos.publicacao.id === publicacao.id && arquivos.nome.toLowerCase().includes(palavraChave)) {
                if (!publicacaoFilter[publicacao.id]) {
                  publicacaoFilter[publicacao.id] = [];
                }
                publicacaoFilter[publicacao.id].push(arquivos);
              }
            });
        }
      }
    });

    return Object.values(publicacaoFilter).map((arrayArquivos, index) => {
      if (arrayArquivos.length > 1) {
        return (
          <div className='arquivo' key={index}>
            <Button
              variant='link'
              className='pdfs'
              onClick={() => {
                setListaArquivos(arrayArquivos);
                handleShow();
              }}
            >
              <i className='mr-2'>
                <FontAwesomeIcon icon={faFilePdf} />{' '}
              </i>
              {`${
                arrayArquivos[0] && arrayArquivos[0].publicacao.categoriaPublicacao.exibeAno
                  ? arrayArquivos[0] && arrayArquivos[0].publicacao.data.slice(0, 4) + ' - '
                  : ''
              }${arrayArquivos[0].publicacao.nome}`}
            </Button>
          </div>
        );
      } else if (arrayArquivos.length > 0) {
        return renderizarArquivo(arrayArquivos[0], index);
      }
      return null;
    });
  };

  const renderizarArquivo = (arquivo, index) => {
    return arquivo ? (
      <div className='arquivo' key={index}>
        <a
          className='pdfs'
          href={
            arquivo.linkExterno
              ? arquivo.caminho
              : `${config.api_path}/public/v1/${identificador}/publicacao/download/${arquivo.nome}`
          }
          target='__blank'
        >
          <i className='mr-2'>
            <FontAwesomeIcon icon={faFilePdf} />{' '}
          </i>
          {`${arquivo.publicacao.categoriaPublicacao.exibeAno ? arquivo.publicacao.data.slice(0, 4) + ' - ' : ''}${
            arquivo.nome
          }`}
        </a>
      </div>
    ) : (
      ''
    );
  };

  return grupoComCategoria && publicacaoArquivo && publicacoes && grupoCategoria ? (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='publicacoes p-0 m-0 pb-3' fluid>
        {listaArquivos && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{listaArquivos[0] ? listaArquivos[0].publicacao.nome : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{listaArquivos.map((arquivo, index) => renderizarArquivo(arquivo, index))}</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Container className='content'>
          <section className='content-left'>
            <header>
              <h2 className='busca'>Resultados da busca por: {palavraChave}</h2>
            </header>
            {filtragem &&
              Object.keys(filtragem).map((nomeGrupo) => (
                <div>
                  <NavLink className='nome-grupo' to={`${location}/detail/${grupoCategoria[nomeGrupo]}`}>
                    <h2>{nomeGrupo}</h2>
                  </NavLink>
                  {filtragem[nomeGrupo].map((categoria, index) => (
                    <div key={index}>
                      {categoria.linkExterno ? (
                        <a href={categoria.linkUrl} target='__blank' className='cards'>
                          <h6 className='nome-categoria'>{categoria.nome}</h6>
                        </a>
                      ) : (
                        <NavLink
                          to={`${location}/detail/${grupoCategoria[nomeGrupo]}/${categoria.nomeUrl}`}
                          className='cards'
                        >
                          <h6 className='nome-categoria'>{categoria.nome}</h6>
                        </NavLink>
                      )}

                      {pdfs(categoria.nomeUrl)}
                    </div>
                  ))}
                </div>
              ))}
          </section>
          <section className='content-right'>
            <NavLink exact to={`/${nomeUrl}`} className='btn'>
              Voltar
            </NavLink>
          </section>
        </Container>
      </Container>
      <Footer />
    </>
  ) : (
    <Loading />
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  InputGroup,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import { NavLink, useRouteMatch } from 'react-router-dom';
import config from '../../../config';
import { useCliente } from '../../../navigation/context/ClienteContext';
import BarraAcessibilidade from '../PortalTransparenciaPage/BarraAcessibilidade/barraAcessibilidade';
import Footer from '../PortalTransparenciaPage/Footer/footer';
import HeaderPortal from '../PortalTransparenciaPage/Header/header';
import NavigationBreadcrumb from '../../../navigation/NavigationBreadcrumb';

export default function EstruturaList() {
  const match = useRouteMatch();
  const { identificador } = useCliente();
  const [loading, setLoading] = useState(false);
  const [estruturas, setEstruturas] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchUnidades = async () => {
      if(identificador) {
        setLoading(true);
        let url = `${config.api_path}/public/v1/${identificador}/servico/estrutura`;
        try {
          let res = await Axios.get(url);
          if (!res.data.error) {
            setEstruturas(res.data.data);
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchUnidades();
  }, [identificador]);

  return (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='pt-4 mb-4' style={{ minHeight: '700px' }}>
        <h2 className='display-4'>Estrutura Organizacional</h2>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon='search' />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder='Filtrar'
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </InputGroup>
        <ListGroup className='mt-2'>
          {!loading &&
            estruturas &&
            estruturas
              .filter((estrutura) =>
                estrutura.nome.match(new RegExp(filter, 'i'))
              )
              .map((estrutura, i) => (
                <ListGroup.Item
                  action
                  key={i}
                  className='text-dark'
                  as={NavLink}
                  to={{
                    pathname: `${match.url}/${estrutura.id}`,
                    estruturaData: estrutura,
                    overrideLabel: {
                      [`${match.url}/${estrutura.id}`]: estrutura.nome,
                    },
                  }}
                >
                  <div className='d-flex w-100 justify-content-between align-items-center'>
                    <h4>{estrutura.nome}</h4>
                    <FontAwesomeIcon icon={'arrow-right'} />
                  </div>
                </ListGroup.Item>
              ))}
          {loading && (
            <ListGroup.Item className='d-flex align-items-center'>
              <Spinner animation='border' />{' '}
              <span className='ml-2'>Carregando...</span>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Container>
      <Footer />
    </>
  );
}

// class EstruturaList extends Component {
//   state = {
//     estruturas: [],
//     loading: false,
//     filter: '',
//   };

//   componentDidMount() {
//     this.fetchUnidades();
//   }

//   async fetchUnidades() {
//     const { match } = this.props;

//     await this.setState({ ...this.state, loading: true });

//     let url = `${config.api_path}/public/v1/${match.params.cliente}/servico/estrutura`;

//     try {
//       let res = await Axios.get(url);

//       if (!res.data.error) {
//         this.setState({
//           ...this.state,
//           estruturas: res.data.data,
//         });
//       }
//     } catch (e) {
//       console.log(e);
//     } finally {
//       await this.setState({ ...this.state, loading: false });
//     }
//   }

//   render() {
//     const { match } = this.props;
//     const { loading, estruturas, filter } = this.state;

//     return (
//       <>
//       <BarraAcessibilidade />
//       <HeaderPortal />

//       <Container>
//         <h2 className='display-4'>Estrutura Organizacional</h2>

//         <InputGroup>
//           <InputGroup.Prepend>
//             <InputGroup.Text>
//               <FontAwesomeIcon icon='search' />
//             </InputGroup.Text>
//           </InputGroup.Prepend>
//           <FormControl
//             placeholder='Filtrar'
//             value={filter}
//             onChange={(e) =>
//               this.setState({ ...this.state, filter: e.target.value })
//             }
//           />
//         </InputGroup>

//         <ListGroup className='mt-2'>
//           {!loading &&
//             estruturas &&
//             estruturas
//               .filter((estrutura) =>
//                 estrutura.nome.match(new RegExp(filter, 'i'))
//               )
//               .map((estrutura, i) => (
//                 <ListGroup.Item
//                   action
//                   key={i}
//                   className='text-dark'
//                   as={NavLink}
//                   to={{
//                     pathname: `${match.url}/${estrutura.id}`,
//                     estruturaData: estrutura,
//                     overrideLabel: {
//                       [`${match.url}/${estrutura.id}`]: estrutura.nome,
//                     },
//                   }}
//                 >
//                   <div className='d-flex w-100 justify-content-between align-items-center'>
//                     <h4>{estrutura.nome}</h4>
//                     <FontAwesomeIcon icon={'arrow-right'} />
//                   </div>
//                 </ListGroup.Item>
//               ))}
//           {loading && (
//             <ListGroup.Item className='d-flex align-items-center'>
//               <Spinner animation='border' />{' '}
//               <span className='ml-2'>Carregando...</span>
//             </ListGroup.Item>
//           )}
//         </ListGroup>
//       </Container>
//       <Footer />
//       </>
//     );
//   }
// }

// export default withRouter(EstruturaList);

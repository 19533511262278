import { faBan, faCheck, faEdit, faFilePdf, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { FieldArray } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormLabel, InputGroup, Modal, Row, Spinner, Table } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/Input';
import Switch from '../../../components/Input/Switch';
import config from '../../../config';
import schema from './schema';

const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric' };
function formatar(val) {
  if (val) {
    if (val.includes('transparencia/publicacao')) {
      const splits = val.split('/');
      const pdfNameComplete = splits.pop();
      const indexOfDash = pdfNameComplete.indexOf('-');
      const pdfName = pdfNameComplete.substring(indexOfDash + 1);
      return pdfName;
    } else {
      return val;
    }
  } else return null;
}

const formatarData = (data) => {
  return new Date(data).toLocaleDateString('pt-BR', opcoes);
};

const columns = [
  { header: 'Categoria', property: 'categoriaPublicacao.nome', filter: true },
  { header: 'Tipo por categoria', property: 'categoriaPublicacaoTipo.nome', filter: true },
  { header: 'Nome', property: 'nome', filter: true },
  {
    header: 'Tipo da Publicação',
    property: 'tipoPublicacao.nome',
    filter: true,
  },
  {
    header: 'URL da Publicacao',
    property: 'urlPublicacao',
    filter: true,
    format: (val) => formatar(val),
  },
  { header: 'Data da publicação', property: 'data', filter: true, format: (data) => formatarData(data) },
  {
    header: 'Data do registro da publicação',
    property: 'dataHoraRegistro',
    filter: true,
    format: (data) => formatarData(data),
  },
];

export default function PublicacaoPage() {
  const [hideUrl, setHideUrl] = useState(true);
  const [hideFile, setHideFile] = useState(true);
  const [hideCategoria, setHideHtml] = useState(true);
  const [hideVideo, setHideVideo] = useState(true);
  const [codeYt, setCodeYt] = useState('');
  const [type, setType] = useState('');
  const [url, setUrl] = useState('');
  const [categoriaId, setCategoriId] = useState();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [temTipo, setTemTipo] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [justOne, setJustOne] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [idArquivoDelete, setIdArquivoDelete] = useState();
  const [arquivosPublicacao, setArquivosPublicacao] = useState([]);
  const [idArquivoEditar, setIdArquivoEditar] = useState();
  const [novoNomeArquivo, setNovoNomeArquivo] = useState('');

  const hide = () => {
    setArquivosPublicacao([]);
    setVisivel(false);
  };

  const show = async (licitacao) => {
    getArquivos(licitacao.id);
    setVisivel(true);
  };

  let filter = {
    'categoriaPublicacao.id': {
      $eq: `long:${categoriaId}`,
    },
  };

  useEffect(() => {
    if (type) {
      if (type.id === 1) {
        setHideUrl(true);
        setUrl('');
        setHideFile(false);
        setHideVideo(true);
        setHideHtml(true);
      } else if (type.id === 3) {
        setHideHtml(true);
        setHideUrl(false);
        setHideFile(true);
        setHideVideo(true);
        setUrl('');
      } else if (type.id === 4) {
        setHideHtml(false);
        setHideUrl(true);
        setHideFile(true);
        setHideVideo(true);
        setUrl('');
      } else {
        setHideVideo(true);
        setHideHtml(true);
        setHideUrl(false);
        setHideFile(true);
        setUrl('');
      }
    } else {
      setUrl('');
      setHideHtml(true);
      setHideUrl(true);
      setHideFile(true);
      setHideVideo(true);
    }
  }, [type]);

  const getArquivos = async (publicacaoId) => {
    const data = await Axios.get(`${config.api_path}/v1/transparencia/publicacao-arquivo/listar/${publicacaoId}`);
    setArquivosPublicacao(data.data.data);
    setIdArquivoDelete(null);
    setIdArquivoEditar(null);
    setIsDeleting(false);
    setIsEditing(false);
  };

  const handleDeleteArquivo = async (arquivoId, publicacaoId) => {
    setIsDeleting(true);
    await Axios.delete(`${config.api_path}/v1/transparencia/publicacao-arquivo/deletar/${arquivoId}`).then((res) =>
      console.log(res)
    );
    getArquivos(publicacaoId);
  };

  const handleUpdateArquivo = async (arquivoId, publicacaoId) => {
    setIsEditing(true);
    await Axios.put(`${config.api_path}/v1/transparencia/publicacao-arquivo/editar/${arquivoId}`, {
      nome: novoNomeArquivo,
    }).then((res) => console.log(res));
    getArquivos(publicacaoId);
  };

  const handleUpload = (handleChange, index) => {
    return (file) => {
      handleChange({
        target: {
          id: `publicacaoArquivos.${index}.caminho`,
          value: file.data.data,
        },
      });
    };
  };

  function changeType(handleChange) {
    return (e) => {
      if (e.target.value) {
        handleChange({
          target: {
            id: 'tipoPublicacao',
            value: e.target.value,
          },
        });
        setType(e.target.value);
      } else {
        handleChange({
          target: {
            id: 'tipoPublicacao',
            value: '',
          },
        });
        setType('');
      }
    };
  }

  function changeUrl(handleChange) {
    return (e) => {
      if (e.target.value) {
        handleChange({
          target: {
            id: 'urlPublicacao',
            value: e.target.value,
          },
        });
        setUrl(e.target.value);
      } else {
        handleChange({
          target: {
            id: 'urlPublicacao',
            value: '',
          },
        });
        setUrl('');
        setHideVideo(true);
      }
    };
  }

  function handleBlur() {
    if (url.includes('youtu.be')) {
      const partes = url.split('/');
      setCodeYt(partes.pop());
      setHideVideo(false);
    } else if (url.includes('www.youtube.com')) {
      const urlParams = new URLSearchParams(url.split('?')[1]);
      const videoCode = urlParams.get('v');
      setCodeYt(videoCode);
      setHideVideo(false);
    }
  }

  const handleCategoriaSelect = (handleChange) => {
    return async (e) => {
      if (e.target.value) {
        const value = e.target.value;
        const filtro = JSON.stringify({ 'categoriaPublicacao.id': { $eq: `long:${value.id}` } });
        try {
          const { data } = await Axios.get(
            `${config.api_path}/v1/transparencia/categoria_publicacao_tipo?filter=${filtro}`
          );
          if (data.data.count === 0) {
            setTemTipo(false);
          } else {
            setTemTipo(true);
          }
        } catch (error) {}
        handleChange({
          target: {
            id: 'categoriaPublicacao',
            value: value,
          },
        });
        setCategoriId(value.id);
      }
    };
  };

  const handleNomeChange = (handleChange) => {
    return (e) => {
      handleChange({
        target: {
          id: 'nome',
          value: e.target.value,
        },
      });
      if (e.target.value && justOne) {
        handleChange({
          target: {
            id: `publicacaoArquivos.0.nome`,
            value: e.target.value,
          },
        });
      }
    };
  };

  return (
    <Crud
      header='Publicação'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/publicacao`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <div>
            <Modal show={visivel} onHide={hide} size='xl'>
              <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
              <Modal.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        <FormLabel>Arquivos</FormLabel>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Container className='p-0 mb-4'>
                          {arquivosPublicacao
                            ? arquivosPublicacao.map((arquivo) => (
                                <Container>
                                  <Row className='mb-2'>
                                    <td className='col'>
                                      <Col>
                                        <FormLabel>
                                          <i className='mr-2'>
                                            <FontAwesomeIcon icon={faFilePdf} />{' '}
                                          </i>
                                          {arquivo.nome}
                                        </FormLabel>
                                      </Col>
                                      {isEditing ? (
                                        <Row
                                          className='mb-4 mt-2'
                                          hidden={idArquivoEditar === arquivo.id ? false : true}
                                        >
                                          <Col>
                                            <Spinner animation='border' />
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Col hidden={idArquivoEditar === arquivo.id ? false : true}>
                                          <Form.Group>
                                            <FormLabel>Nome</FormLabel>
                                            <InputGroup>
                                              <Input.Text
                                                id='nome'
                                                value={novoNomeArquivo}
                                                onChange={(e) => setNovoNomeArquivo(e.target.value)}
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                          <Button
                                            onClick={() => handleUpdateArquivo(arquivo.id, arquivo.publicacao.id)}
                                          >
                                            Editar nome
                                          </Button>
                                        </Col>
                                      )}
                                    </td>
                                    <td>
                                      <Row
                                        className='justify-content-center align-items-center'
                                        hidden={idArquivoDelete === arquivo.id ? true : false}
                                      >
                                        <Col>
                                          <Button size='lg' onClick={() => setIdArquivoDelete(arquivo.id)}>
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        </Col>
                                        <Col>
                                          <Button
                                            size='lg'
                                            onClick={() => {
                                              setNovoNomeArquivo('');
                                              setIdArquivoEditar(arquivo.id);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                          </Button>
                                        </Col>
                                      </Row>
                                      {isDeleting ? (
                                        <Row
                                          className='mb-4 mt-2'
                                          hidden={idArquivoDelete === arquivo.id ? false : true}
                                        >
                                          <Col>
                                            <Spinner animation='border' />
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Row
                                          className='justify-content-center align-items-center'
                                          hidden={idArquivoDelete === arquivo.id ? false : true}
                                        >
                                          <Col>
                                            <Button
                                              size='lg'
                                              onClick={() => handleDeleteArquivo(arquivo.id, arquivo.publicacao.id)}
                                            >
                                              <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                          </Col>
                                          <Col>
                                            <Button size='lg' onClick={() => setIdArquivoDelete(null)}>
                                              <FontAwesomeIcon icon={faBan} />
                                            </Button>
                                          </Col>
                                        </Row>
                                      )}
                                    </td>
                                  </Row>
                                </Container>
                              ))
                            : ''}
                        </Container>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col>
              <Button
                onClick={() => {
                  if (selected) {
                    show(selected);
                  }
                }}
              >
                Alterar arquivos
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors, touched, isValid }) => (
        <Fragment>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Categoria</FormLabel>
                <Input.AsyncSelect
                  id='categoriaPublicacao'
                  value={values.categoriaPublicacao}
                  onChange={handleCategoriaSelect(handleChange)}
                  isInvalid={errors.categoriaPublicacao}
                  path={`${config.api_path}/v1/transparencia/categoria_publicacao`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma categoria'
                />
                <Form.Control.Feedback type='invalid'>{errors.categoriaPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Tipo de publicação</FormLabel>
                <Input.AsyncSelect
                  id='tipoPublicacao'
                  value={values.tipoPublicacao}
                  onChange={changeType(handleChange)}
                  isInvalid={errors.tipoPublicacao}
                  path={`${config.api_path}/v1/transparencia/tipo_publicacao`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um tipo de publicação'
                />
                <Form.Control.Feedback type='invalid'>{errors.tipoPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5} hidden={values.categoriaPublicacao ? !values.categoriaPublicacao.agruparEntidade : true}>
              <Form.Group>
                <FormLabel>Entidade</FormLabel>
                <Input.AsyncSelect
                  id='entidade'
                  value={values.entidade}
                  onChange={handleChange}
                  isInvalid={errors.entidade}
                  path={`${config.api_path}/v1/transparencia/entidade`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma entidade'
                />
                <Form.Control.Feedback type='invalid'>{errors.entidade}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5} hidden={hideFile}>
              <Form.Group hidden={!temTipo}>
                <FormLabel>Tipo pela Categoria</FormLabel>
                <Input.AsyncSelect
                  id='categoriaPublicacaoTipo'
                  value={values.categoriaPublicacaoTipo}
                  onChange={handleChange}
                  isInvalid={errors.categoriaPublicacaoTipo}
                  path={`${config.api_path}/v1/transparencia/categoria_publicacao_tipo`}
                  label={'${nome}'}
                  dataFilter={filter}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um tipo de categoria'
                />
                <Form.Control.Feedback type='invalid'>{errors.categoriaPublicacaoTipo}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Data</FormLabel>
                <Input.Date id='data' value={values.data} onChange={handleChange} />
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleNomeChange(handleChange)}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.nome}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={5}>
              <Form.Group hidden={hideUrl}>
                <FormLabel>URL Publicação</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='urlPublicacao'
                    value={url}
                    onChange={changeUrl(handleChange)}
                    isInvalid={errors.urlPublicacao}
                    onBlur={handleBlur}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.urlPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Form.Group hidden={values.tipoPublicacao ? values.tipoPublicacao.id !== 1 : true}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        <FormLabel>Arquivos</FormLabel>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <FieldArray
                          name={'publicacaoArquivos'}
                          render={(arrayHelper) => (
                            <Container className='p-0'>
                              <Row className='mb-4'>
                                <Col>
                                  <Button
                                    onClick={() => {
                                      setJustOne(false);
                                      arrayHelper.push({});
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </Button>
                                </Col>
                              </Row>
                              {values.publicacaoArquivos &&
                                values.publicacaoArquivos.map((arquivo, index) => (
                                  <div>
                                    <Container>
                                      <Row className='mb-4'>
                                        <td className='col'>
                                          <Col className='mb-4'>
                                            <Form.Group>
                                              <FormLabel>Nome do arquivo</FormLabel>
                                              <Input.Text
                                                id={`publicacaoArquivos.${index}.nome`}
                                                value={values.publicacaoArquivos[index].nome}
                                                onChange={handleChange}
                                              ></Input.Text>
                                            </Form.Group>
                                          </Col>
                                          <Col>
                                            <Switch
                                              id={`publicacaoArquivos.${index}.linkExterno`}
                                              label={'Link externo'}
                                              value={values.publicacaoArquivos[index].linkExterno}
                                              onChange={handleChange}
                                            />
                                          </Col>
                                        </td>
                                        <td className='col'>
                                          <Col>
                                            {values.publicacaoArquivos[index].linkExterno ? (
                                              <Col>
                                                <FormLabel>Link URL</FormLabel>
                                                <Input.Text
                                                  id={`publicacaoArquivos.${index}.caminho`}
                                                  values={values.publicacaoArquivos[index].caminho}
                                                  onChange={handleChange}
                                                ></Input.Text>
                                              </Col>
                                            ) : arquivo.caminho ? (
                                              arquivo.caminho
                                            ) : (
                                              <Dropzone
                                                route={`${config.api_path}/v1/transparencia/publicacao/upload`}
                                                accept='.pdf'
                                                onUpload={handleUpload(handleChange, index)}
                                              />
                                            )}
                                          </Col>
                                        </td>
                                        <td style={{ display: 'flex' }}>
                                          <Row className='justify-content-center align-items-center'>
                                            <Col>
                                              <Button size='lg' onClick={() => arrayHelper.remove(index)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                              </Button>
                                            </Col>
                                          </Row>
                                        </td>
                                      </Row>
                                    </Container>
                                  </div>
                                ))}
                            </Container>
                          )}
                        ></FieldArray>
                        {uploadFiles.map((file) => (
                          <Input.Text id='urlPublicacao' value={file} disabled />
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group hidden={hideCategoria}>
                <FormLabel>Categoria</FormLabel>
                <Input.AsyncSelect
                  id='subcategoriaPublicacao'
                  value={values.subcategoriaPublicacao}
                  onChange={handleChange}
                  isInvalid={errors.subcategoriaPublicacao}
                  path={`${config.api_path}/v1/transparencia/categoria_publicacao`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma categoria'
                />
                <Form.Control.Feedback type='invalid'>{errors.subcategoriaPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group hidden={hideVideo}>
                <iframe
                  width='560'
                  height='315'
                  src={`https://www.youtube.com/embed/${codeYt}`}
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowfullscreen
                ></iframe>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                    <pre>{JSON.stringify(touched, null, 2)}</pre>
                    <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

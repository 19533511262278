import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import config from '../../config';
import Page from '../../navigation/Page';
import { ClienteProvider } from '../../navigation/context/ClienteContext';
import { flat } from '../../navigation/utils';
import LicitacaoList from '../public/AvisoLicitacaoPage/LicitacaoList';
import PortalTransparenciaPage from '../public/PortalTransparenciaPage';
import DeclaracaoInexistenciaPublicPage from '../public/PortalTransparenciaPage/DeclaracaoInexistencia/declaracaoInexistenciaPage';
import EstruturaOrganizacional from '../public/PortalTransparenciaPage/EstruturaOrganizacional/estruturaOrganizacional';
import GrupoCategoria from '../public/PortalTransparenciaPage/GrupoCategoria/grupoCategoria';
import PortalTransparenciaPublicacoesPage from '../public/PortalTransparenciaPage/PaginaPublicações/publicacoes';
import Pesquisa from '../public/PortalTransparenciaPage/Pesquisa/pesquisa';
import '../public/PortalTransparenciaPage/styles.scss';
import {
  CategoriaPublicacao,
  CategoriaPublicacaoTipo,
  DeclaracaoInexistenciaService,
  GrupoCategoriaPublicacao,
  PortalClienteService,
  Publicacao,
  PublicacaoArquivo,
} from '../public/PortalTransparenciaPage/utils/useServices';
import ServicoPage from '../public/ServicoPage';
import EstruturaList from '../public/ServicoPage/EstruturaList';
import ServicoDetail from '../public/ServicoPage/ServicoDetail';
import ServicoList from '../public/ServicoPage/ServicoList';

const navigation = [
  {
    path: '/:cliente/licitacao',
    label: 'Página de avisos de licitação',
    content: () => <LicitacaoList />,
    exact: true,
  },
  {
    path: '/:cliente',
    label: 'Inicio',
    content: () => <PortalTransparenciaPage />,
    exact: true,
    routes: [
      {
        path: '/declaracao-inexistencia-informacao',
        label: 'Página de declaração de inexistência de informações',
        content: () => <DeclaracaoInexistenciaPublicPage />,
        exact: true,
      },
      {
        path: '/busca/:palavraChave',
        label: 'Página de Pesquisa',
        content: () => <Pesquisa />,
        exact: true,
      },
      {
        path: '/estrutura-organizacional',
        label: 'Estrutura organizacional',
        content: () => <EstruturaOrganizacional />,
        exact: true,
      },
      {
        path: 'detail/:grupoNomeUrl',
        label: 'Página de grupo categoria',
        content: () => <GrupoCategoria />,
        exact: true,
        routes: [
          {
            path: '/:categoriaNomeUrl',
            label: 'Página de categoria',
            content: () => <PortalTransparenciaPublicacoesPage />,
            exact: true,
          },
        ],
      },
      {
        path: '/servicos',
        label: 'Carta de Serviços',
        content: () => <ServicoPage />,
        exact: true,
        routes: [
          {
            path: '/estrutura',
            label: 'Estrutura Organizacional',
            content: () => <EstruturaList />,
            exact: true,
            routes: [
              {
                path: '/:unidade',
                label: 'Unidade',
                content: () => <ServicoList />,
                exact: true,
                routes: [
                  {
                    path: '/detail/:id',
                    label: 'Detalhes do Serviço',
                    content: () => <ServicoDetail />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default function PublicRouter() {
  const [clienteTipo, setClienteTipo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [portal, setPortal] = useState(null);
  const [nomeUrl, setNomeUrl] = useState(null);
  const [identificador, setIdentificador] = useState(null);
  const [listaClientes, setListaClientes] = useState([]);
  const [grupoComCategoria, setGrupoComCategoria] = useState(null);
  const [grupoCategoria, setGrupoCategoria] = useState(null);
  const [todasCategorias, setTodasCategorias] = useState(null);
  const [categoriaTipo, setCategoriaTipo] = useState(null);
  const [publicacoes, setPublicacoes] = useState(null);
  const [declaracoes, setDeclaracoes] = useState([]);
  const [publicacaoArquivo, setPublicacaoArquivo] = useState(null);
  const [portalCliente, setPortalCliente] = useState({});
  const fontSize = 16;
  const [fontSizeBase, setFontSizeBase] = useState(fontSize);
  const [contraste, setContraste] = useState(false);
  const [grupoCategoriaPublicacao, setGrupoCategoriaPublicacao] = useState([]);

  const match = useParams();

  const aumentarFonte = () => {
    setFontSizeBase((prevSize) => Math.min(prevSize + 2, 30));
  };

  const diminuirFonte = () => {
    setFontSizeBase((prevSize) => Math.max(prevSize - 2, 8));
  };

  const fetchAllData = useCallback(async (cliente) => {
    if (!cliente) return;

    setLoading(true);
    try {
      //Primeiro determinar o cliente do portal
      let res = await Axios.get(`${config.api_path}/public/v1/portal/${cliente}`);
      if (res.data.error || !res.data.data) {
        //TODO: se falhar a obter o portal ou não retornar nenhum, redirecionar para uma página de erro
      }

      const portal = res.data.data;
      const { nomeUrl } = portal;
      const { identificador, clienteTipo } = portal.clienteGlobal;

      setPortal(portal);
      setNomeUrl(nomeUrl);
      setIdentificador(identificador);
      setClienteTipo(clienteTipo);

      // O Identificador é uma dependencia das outras consultas, então não podemos prosseguir sem ele
      if (identificador) {
        let categoria;
        let grupoCategoria;

        CategoriaPublicacaoTipo(identificador).then((res) => {
          if (!res.data.data.error) setCategoriaTipo(res.data.data);
        });
        Publicacao(identificador).then((res) => {
          if (!res.data.data.error) setPublicacoes(res.data.data);
        });

        PublicacaoArquivo(identificador).then((res) => {
          if (!res.data.data.error) setPublicacaoArquivo(res.data.data);
        });

        PortalClienteService(identificador).then((res) => {
          if (!res.data.data.error) {
            const layout = res.data.data[0].layout;
            document.body.style.setProperty('--cor-bg-categoria', `${layout.categoriaButtonBgColor}`);
            document.body.style.setProperty('--cor-font-categoria', `${layout.categoriaButtonFontColor}`);
            document.body.style.setProperty('--cor-font-grupo', `${layout.grupoCategoriaLabelFontColor}`);
            document.body.style.setProperty('--cor-bg-top', `${layout.topBgColor}`);
            document.body.style.setProperty('--page-bg-color', `${layout.pageBgColor}`);
            document.body.style.setProperty('--page-font-color', `${layout.pageFontColor}`);
            document.body.style.setProperty('--top-label-font-color', `${layout.topNomePortalColor}`);
            setPortalCliente(layout);
          }
        });

        DeclaracaoInexistenciaService(identificador).then((res) => {
          if (!res.data.data.error) setDeclaracoes(res.data.data);
        });

        //Quando o cliente for prefeitura, será necessário listar os outros clientes
        if (clienteTipo.id === 8) {
          Axios.get(`${config.api_path}/public/v1/clientesiorconp/${identificador}`).then((res) =>
            setListaClientes(res.data.data)
          );
        } else {
          setListaClientes([portal.clienteGlobal]);
        }

        // Essas são as unicas consultas que dependem uma da outra, então não precisamos esperar que as outras sejam concluidas
        let promises = [];
        promises.push(CategoriaPublicacao(identificador).then((res) => (categoria = res.data)));
        promises.push(GrupoCategoriaPublicacao(identificador).then((res) => (grupoCategoria = res.data)));
        //Esperar que todas sejam concluidas antes de prosseguir
        await Promise.all(promises);

        if (!categoria.error && !grupoCategoria.error) {
          setTodasCategorias(categoria.data);
          setGrupoCategoriaPublicacao(grupoCategoria.data);

          const grupo_e_categoria = {};
          const grupoNomesUrl = {};

          grupoCategoria.data
            .sort((a, b) => (a.ordemExibicao > b.ordemExibicao ? 1 : -1))
            .forEach((grupo) => {
              grupoNomesUrl[grupo.nome] = grupo.nomeUrl;
              for (let i = 0; i < categoria.data.length; i++) {
                if (categoria.data[i].grupoCategoriaPublicacao.id === grupo.id) {
                  const { nome } = grupo;
                  const categoriaAtual = categoria.data[i];
                  if (!grupo_e_categoria[nome]) {
                    grupo_e_categoria[nome] = [];
                  }
                  grupo_e_categoria[nome].push(categoriaAtual);
                }
              }
            });

          setGrupoCategoria(grupoNomesUrl);
          setGrupoComCategoria(grupo_e_categoria);
        }
      }
    } catch (err) {
      console.trace(err);
      //TODO: redirecionar para pagina de erro
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllData(match.cliente);
  }, [fetchAllData, match.cliente]);

  useEffect(() => document.body.style.setProperty('--font-size-base', `${fontSizeBase}px`), [fontSizeBase]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (contraste === true) body.classList.add('contraste');
    else body.classList.remove('contraste');
  }, [contraste]);

  /** TODO: talvez organizar valores em grupos ex.:
   * {
   *  cliente: { clienteTipo, loading, portal, nomeUrl, identificador, listaClientes },
   *  portal: { grupoComCategoria, grupoCategoria, todasCategorias, declaracoes, categoriaTipo, publicacoes, publicacaoArquivo },
   *  acessibilidade: { fontSizeBase, setFontSizeBase, aumentarFonte, diminuirFonte, contraste, setContraste }
   * }
   */
  const clientInfo = {
    clienteTipo,
    loading,
    portal,
    nomeUrl,
    identificador,
    listaClientes,
    grupoComCategoria,
    grupoCategoria,
    todasCategorias,
    declaracoes,
    categoriaTipo,
    publicacoes,
    publicacaoArquivo,
    portalCliente,
    grupoCategoriaPublicacao,
    fontSizeBase,
    setFontSizeBase,
    aumentarFonte,
    diminuirFonte,
    contraste,
    setContraste,
  };

  return (
    <>
      {flat(navigation).map((route) => {
        return (
          <Route key={route.path} path={route.path} exact={route.exact}>
            <ClienteProvider value={{ ...clientInfo, route }}>
              <Page route={route} />
            </ClienteProvider>
          </Route>
        );
      })}
    </>
  );
}

import React from 'react';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Pagination } from 'react-bootstrap';
import DataTableFilter from './DataTableFilter'
import AsyncOutput from '../AsyncOutput'
import { extractValue } from '../utils/formatador';

import debounce from 'es6-promise-debounce';

import Axios from 'axios';
import SimpleContextMenu from '../SimpleContextMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withViewport } from '../hocs';
import './style.css'
const CancelToken = Axios.CancelToken;

class DataTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            page: this.props.page || 0,
            list: [],
            count: 0,
            loading: 0,
            errors: null,
            
            selected: null,
            selectedRow: null,
            ctxVisible: false,
            
            filter: {},
            sort: this.props.defaultSort
        }

        this.filters = [];

        this.debouncedRefresh = debounce(this.refresh.bind(this), 1000);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.cleanFilter = this.cleanFilter.bind(this);
    }

    componentDidMount() {
        this.debouncedRefresh();
        if(this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if(this.props.onRef) this.props.onRef(undefined);
        if(this.cancelRequest) this.cancelRequest("Operation canceled by user");
    }

    componentDidUpdate(oldProps) {
        if(oldProps.path !== this.props.path
            || oldProps.filter !== this.props.filter
            || oldProps.rows !== this.props.rows
            || oldProps.filterColumns !== this.props.filterColumns
            || oldProps.urlParams !== this.props.urlParams)
            this.debouncedRefresh();
    }

    cleanFilter() {
        this.filters.forEach(filter => filter.clean());
        this.setState({
            ...this.state,
            filter: {},
        }, () => this.debouncedRefresh());
    }

    refresh() {
        const { path, rows, filter : extendFilter, filterColumns, urlParams } = this.props;
        const { page, sort, filter } = this.state;

        let _filter = filter;
        if(extendFilter) _filter = { ...filter, ...extendFilter };

        this.setState(oldState => ({
            ...this.state,
            loading: (oldState.loading+1),
            selected: null,
            selectedRow: null
        }));

        let url = `${path}?filter=${encodeURI(JSON.stringify(_filter))}&limit=${rows}&offset=${page*rows}`;
             
        if(typeof sort !== 'undefined' && sort !== null){
            url = url + "&sort=" + encodeURI(JSON.stringify(sort));
        }
        if(typeof filterColumns !== 'undefined' && filterColumns !== null){
            url = url + "&columns=" + encodeURI(JSON.stringify(filterColumns));
        }

        if(typeof urlParams !== 'undefined' && urlParams !== null){
            url = url + urlParams
        }

        const cancelToken = { 
            cancelToken: new CancelToken( (c) => { this.cancelRequest = c;})
        };

        return new Promise((resolve, reject) => {        
            Axios.get(url,cancelToken )
                .then(res => {
                        this.cancelRequest = undefined;
                        var json = res.data;
                        if(json.error) {
                            reject(json);
                            this.setState( oldState => ({
                                loading: (oldState.loading-1),
                                errors: json.messages
                            }));
                        } 
                        else {
                            resolve(json.data);
                            this.setState( oldState => ({
                                ...this.state,
                                list: json.data.list,
                                count: json.data.count,
                                loading: oldState.loading-1,
                                errors: null
                            }));
                        }
                    }
                    ,err => {
                        console.log(err);
                })
            }
        )
    }

    setPage(nextPage) {
        const { rows, onPageChange } = this.props;
        const { count, page } = this.state;
        const lastPage = Math.ceil(count / rows) - 1;
        
        if(nextPage < 0) nextPage = 0;
        if(nextPage > lastPage) nextPage = lastPage;
        if(nextPage !== page) {
            if(onPageChange) onPageChange(nextPage);
            this.setState({
                ...this.state,
                page: nextPage
            }, this.refresh);
        }
    }

    handleFilterChange(property, compare, value) {

        let filter = { ...this.state.filter };
        
        if(!value || value === "boolean:any") delete filter[property];
        else filter[property] = { compare, value }

        this.setState({ ...this.state, filter }, () => this.debouncedRefresh(this.state.page, this.props.rows));
        this.setPage(0)
 
        if(this.props.updateFilter) this.props.updateFilter(filter)
    }

    hideMenu() {
        this.setState({
            ...this.state,
            ctxVisible: false
        });
    }

    handleSelect(e, row) {
        const { loading } = this.state;
        const { viewport } = this.props;

        if(!loading) {
            this.setState({
                ...this.state,
                selected: row || null,
                selectedRow: (row && viewport.width < 992) ? e.target : this.state.selectedRow,
                ctxVisible: (row && viewport.width < 992) ? (e.target !== this.state.selectedRow || !this.state.ctxVisible) : false
            });
            if(this.props.onSelect) this.props.onSelect(row);
        }
    }

    handleContextMenu(e, row) {
        e.preventDefault();
        const { loading } = this.state;

        if(!loading) {
            this.setState({
                ...this.state,
                selected: row || null,
                selectedRow: row ? e.target : null,
                ctxVisible: row ? (e.target !== this.state.selectedRow || !this.state.ctxVisible) : false
            });
            if(this.props.onSelect) this.props.onSelect(row);
        }
    }

    toggleSort(column) {
        const { sort } = this.state;
        let _sort = {};

        if(!sort || !sort[column.property]) _sort[column.property] = 'ASC'
        else if(sort[column.property] === 'ASC') _sort[column.property] = 'DESC';   
        else if(sort[column.property] === 'DESC') _sort = null;

        this.setState({
            ...this.state,
            sort: _sort
        }, this.debouncedRefresh);
    }

    render () {
        const { button, columns, rows, className, contextMenu, hideCtx, header, footer, breakpointTable, paginatorJustBelow, markFalseRow, rowStyles } = this.props;
        const { ctxVisible, selected, selectedRow, list, count, page, loading, errors, sort } = this.state;
        const lastPage = Math.ceil(count / rows);
        const tableClassName = [typeof breakpointTable !== 'undefined' && breakpointTable !== null 
                                    ? 'table-responsive-'+breakpointTable 
                                    : 'table-responsive',
                                className].join(' ')                      
        return (
            <Fragment>
                { contextMenu && <SimpleContextMenu placement="auto" show={!hideCtx && ctxVisible && !!selected} target={selectedRow} groups={contextMenu} /> }
                <div className={tableClassName} style={paginatorJustBelow ? null : {minHeight: '50vh'}}>
                    <table className="datatable table table-sm table-striped table-bordered table-hover table-responsive-sm">
                        <thead>
                            <tr className="bg-light">
                                {header && <th colSpan={columns.length}>
                                    {header(selected, list, count, page, errors)}
                                </th>}
                                {columns.map((column, i) => (
                                    <th className="text-center datatable-header sortable" key={i} onClick={() => this.toggleSort(column)}>
                                        <div className="d-flex w-100">
                                            <span className="flex-fill">{column.header}</span>
                                            <span className="px-2">
                                                { (sort && !!sort[column.property]) ?
                                                    <FontAwesomeIcon icon={`sort-${sort[column.property] === 'ASC' ? "up" : "down"}`}/>
                                                    :
                                                    <FontAwesomeIcon icon="sort"/>
                                                }
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                {columns.map((column, i) => (
                                    <td key={i}>
                                        {column.filter && <DataTableFilter property={column.property} filter={column.filter} onChange={this.handleFilterChange} onRef={(filter) => this.filters.push(filter)} /> }
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                errors ?
                                errors.map((err, i) => (
                                    <tr key={i}>
                                        <td colSpan={columns.length} className={err.code > 2999 ? "table-danger" : err.code > 1999 ? "table-warning" : "table-info"}>{err.description}</td>
                                    </tr>
                                ))
                                : (!list || list.length === 0) ?
                                <tr>
                                    <td colSpan={columns.length}><AsyncOutput loading={loading > 0}>Sem Registros</AsyncOutput></td>
                                </tr>
                                :  
                                list.map((row, rowId) => (
                                <tr key={rowId} onClick={(e) => {this.handleSelect(e, row); }} onContextMenu={(e) => this.handleContextMenu(e, row)} style={{cursor: 'pointer'}} className={row === selected ? "table-primary" : (markFalseRow && row[markFalseRow] === false ? "table-danger" : "")}>
                                    {columns.map((col, i) => {
                                        return(
                                        <td key={i} 
                                            className={
                                                rowStyles && rowStyles.map((rowStyle) => (
                                                    rowStyle && (( rowStyle.operation( 
                                                        ...rowStyle.fields.map((field) => row[field])
                                                    )) ? rowStyle.style : rowStyle.else )
                                                )).join(" ") 
                                            }
                                        >
                                            { (col.property && col.property === "count") ?
                                                <AsyncOutput loading={loading > 0}>{rowId+1}</AsyncOutput>:
                                                (col.property) &&
                                                <AsyncOutput loading={loading > 0}>{ (col.format) ? col.format(extractValue(row, col.property)) : extractValue(row, col.property) }</AsyncOutput>
                                            }
                                            {col.button &&
                                                <Button onClick={()=>button.action(row)} size="sm" className="p-1 m-2">
                                                    <FontAwesomeIcon icon={button.icon}/> {button.name}
                                                </Button>
                                            }
                                        </td>)
                                    })}
                                </tr>
                                ))
                            }
                        </tbody>
                        {footer && <tfoot>
                            {footer(selected, list, count, page, columns)}                            
                        </tfoot>}
                                                
                    </table>
                    
                </div>

                {(lastPage > 1) &&                
                    <div className="d-flex w-100 justify-content-center">
                        <Pagination >
                            <Pagination.First onClick={() => this.setPage(0)} disabled={loading || page === 0}/>
                            <Pagination.Prev onClick={() => this.setPage(page - 1)} disabled={loading || page === 0}/>
                            <Pagination.Item disabled>
                                {loading 
                                    ? <span><FontAwesomeIcon icon="cog" spin/> Carregando...</span> 
                                    : `${page + 1} de ${lastPage}`
                                }
                            </Pagination.Item>
                            <Pagination.Next onClick={() => this.setPage(page + 1)} disabled={loading || (page === lastPage - 1)}/>
                            <Pagination.Last onClick={() => this.setPage(lastPage - 1)} disabled={loading || (page === lastPage - 1)}/>
                        </Pagination>
                    </div>
                }
            </Fragment>
        );
    }

    static propTypes = {
        filter: PropTypes.object,

        header: PropTypes.func,
        footer: PropTypes.func,

        path: PropTypes.string.isRequired,
        rows: PropTypes.number.isRequired,
        page: PropTypes.number,
        columns: PropTypes.array.isRequired,
        filterColumns: PropTypes.array,

        onSelect: PropTypes.func,
        onPageChange: PropTypes.func,
        
        hideCtx: PropTypes.bool,
        contextMenu: PropTypes.array,
        onRef: PropTypes.func,
    }
}

export default withViewport(DataTable);
export const combinePath = (parent, child) => `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

export const parentOf = (path, level) =>
    '/' + path.split('/').reduce((prev, curr, i, arr) => ((i+(level||1)) >= arr.length ? prev : !prev ? `${curr}` : `${prev}/${curr}`), '')


export const flat = (routes, parent) => 
    routes.map((route) => {
        route = {
            ...route,
            parent,
            path: parent ? combinePath(parent.path, route.path) : route.path
        }

        return [
            route, 
            route.routes ? flat(route.routes, route) : []
        ];

    }).flat(Infinity);

export const pathTo = (route) => !route.parent ? [route] : [...pathTo(route.parent), route];

export const inject = (params, path) => 
    path.split('/')
        .map(token => token[0] === ':' && params[token.substring(1)] ? params[token.substring(1)] : token)
        .reduce((path, current) => `${path}/${current}`);
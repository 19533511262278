import Axios from "axios";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Col, Form, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import Crud from "../../../components/Crud"
import Input from "../../../components/Input"
import config from '../../../config'
import schema from "./schema"

const columns = [
    { header: "Nome", property: "nome", filter: true },
]


export default function MatrizAvaliacaoItemPage() {

    return (
        <Crud
            header="Matriz Avaliação Item"
            columns={columns}
            path={`${config.api_path}/v1/transparencia/matriz_item`}
            rows={20}
            schema={schema}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>
                    <Row>
                        <Col md={5}>
                            <Form.Group>
                                <FormLabel>Grupo Matriz Avaliação</FormLabel>
                                <Input.AsyncSelect id="matrizAvaliacaoGrupo" value={values.matrizAvaliacaoGrupo} onChange={handleChange} isInvalid={errors.matrizAvaliacaoGrupo}
                                    path={`${config.api_path}/v1/transparencia/matriz_grupo`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione um Grupo"
                                />
                                <Form.Control.Feedback type="invalid">{errors.matrizAvaliacaoGrupo}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={1}>
                            <Form.Group>
                                <FormLabel>Número</FormLabel>
                                <InputGroup>
                                    <Input.Text id="numero" value={values.numero} onChange={handleChange} isInvalid={errors.numero} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.numero}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nome" value={values.nome} onChange={handleChange} isInvalid={errors.nome} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <Form.Group>
                                <FormLabel>Matriz Classificação</FormLabel>
                                <Input.AsyncSelect id="matrizAvaliacaoClassificacao" value={values.matrizAvaliacaoClassificacao} onChange={handleChange} isInvalid={errors.matrizAvaliacaoClassificacao}
                                    path={`${config.api_path}/v1/transparencia/matriz_classificacao`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione uma Classificacao"
                                />
                                <Form.Control.Feedback type="invalid">{errors.matrizAvaliacaoClassificacao}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>

            )}

        ></Crud>
    )
}
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { useCliente } from './context/ClienteContext';
import { inject, pathTo } from './utils';

export default function NavigationBreadcrumb() {
  const { grupoCategoria, todasCategorias, route } = useCliente();
  const match = useRouteMatch();
  const location = useLocation();
  const [categoria, setCategoria] = useState();

  useEffect(() => {
    todasCategorias &&
      todasCategorias.forEach((categoria) => {
        if (match.params.categoriaNomeUrl === categoria.nomeUrl) {
          setCategoria(categoria);
        }
      });
  }, [match.params.categoriaNomeUrl, todasCategorias]);

  return match.path === '/:cliente' ? (
    ''
  ) : (
    <Breadcrumb>
      <Container className='p-0 m-0' fluid>
        <Container className={match.path === '/:cliente/licitacao' ? 'pl-0 pr-0 ml-0' : ''}>
          {route &&
            match &&
            location &&
            pathTo(route).map((route, i, routes) => {
              const thisPath = inject(match.params, route.path);
              return i === routes.length - 1 ? (
                <span key={i} className='breadcrumb-item active'>
                  {match.path === '/:cliente/detail/:grupoNomeUrl'
                    ? grupoCategoria &&
                      Object.keys(grupoCategoria).find((key) => grupoCategoria[key] === match.params.grupoNomeUrl)
                    : categoria
                    ? categoria.nome
                    : location.overrideLabel && location.overrideLabel[thisPath]
                    ? location.overrideLabel[thisPath]
                    : route.label}
                </span>
              ) : (
                <NavLink key={i} to={thisPath} className='breadcrumb-item'>
                  {route.path === '/:cliente/detail/:grupoNomeUrl'
                    ? grupoCategoria &&
                      Object.keys(grupoCategoria).find((key) => grupoCategoria[key] === match.params.grupoNomeUrl)
                    : location.overrideLabel && location.overrideLabel[thisPath]
                    ? location.overrideLabel[thisPath]
                    : route.label}
                </NavLink>
              );
            })}
        </Container>
      </Container>
    </Breadcrumb>
  );
}

import { library } from '@fortawesome/fontawesome-svg-core';

import { 
    fas
} from '@fortawesome/free-solid-svg-icons';

import { 
    far
} from '@fortawesome/free-regular-svg-icons';

export const buildLibrary = () => {
    library.add(
        fas, far
    )
}
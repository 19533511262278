import React from 'react';
import { Component, Fragment } from 'react';
import { ListGroup } from 'react-bootstrap';
import SidebarItemGroup from './SidebarItemGroup';
import SidebarItem from './SidebarItem';

class SidebarList extends Component {
    state = {
        activeMenu: false,
    }

    openMenu(menu) {
        this.setState({
            ...this.state,
            activeMenu: (menu.id === this.state.activeMenu) ? false : menu.id
        })
    }

    render () {
        const { menus, depth, trigger } = this.props;
        const { activeMenu } = this.state;

        return (
            <Fragment>
                <ListGroup variant="flush">
                    {
                        menus.filter((menu) => !menu.hidden).map( menu => (
                            menu.items
                            ? <SidebarItemGroup key={menu.id} depth={(depth || 0)} menu={menu} current={activeMenu} event={() => this.openMenu(menu)} trigger={trigger}/>
                            : <SidebarItem key={menu.id} depth={(depth || 0)} menu={menu} trigger={trigger} />
                        ))
                    }
                </ListGroup>
            </Fragment>
        );
    }
}

export default SidebarList;
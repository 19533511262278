import React from 'react';
import { Component } from 'react';

class Switch extends Component {

    render () {
        const { id, label, variant, alignRight, className, value, ...rest } = this.props;
        return (
            <div className={`switch switch-${variant || 'primary'} align-${alignRight ? 'right' : 'left'}`}>
                <input id={id} type="checkbox" className={`switch-input ${className}`} checked={value} value={value} {...rest} />
                <label htmlFor={id} className="switch-label">{label}</label>
            </div>
        );
    }
}

export default Switch;
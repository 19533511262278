import Axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormLabel,
  Image,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  {
    header: 'Data da inscrição',
    property: 'dataHoraInscricao',
    filter: true,
    format: (data) => format(new Date(data), 'dd/MM/yyyy HH:mm:ss'),
  },
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'CPF', property: 'cpf', filter: true },
  { header: 'Email', property: 'email', filter: true },
  { header: 'Celular', property: 'celular', filter: true },
  {
    header: 'Instituição representada',
    property: 'instituicaoRepresentada',
    filter: true,
  },
  {
    header: 'Status do comprovante',
    property: 'statusPagamento',
    filter: true,
  },
];

export default function InscricaoPage() {
  const [visivel, setVisivel] = useState(false);
  const [statusPagamento, setStatusPagamento] = useState('');
  const [comprovanteType, setComprovanteType] = useState('');
  const [id, setId] = useState('');
  const hide = () => setVisivel(false);

  const show = (inscricao) => {
    if (
      inscricao.comprovantePagamento &&
      inscricao.comprovantePagamento.includes('.pdf')
    ) {
      setComprovanteType('pdf');
    } else {
      setComprovanteType('img');
    }
    setId(inscricao.id);
    setVisivel(true);
  };

  const handleComprovanteUpdate = async (inscricao) => {
    inscricao.statusPagamento = statusPagamento;
    await Axios.put(
      `${config.api_path}/v1/transparencia/inscricao/status`,
      inscricao
    ).then(() => window.location.reload());
  };

  const handleChangeInscricao = (event) => {
    setStatusPagamento(event.target.value);
  };

  return (
    <Crud
      header='Inscrição'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/inscricao`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <div>
            <Modal show={visivel} onHide={hide} size='xl'>
              <Modal.Header
                style={{ backgroundColor: '#074E80', height: '50px' }}
                closeButton
              />
              <Modal.Body>
                <Col>
                  <Form.Group>
                    <FormLabel>Status do pagamento</FormLabel>
                    <InputGroup>
                      <Input.Text
                        id='statusPagamento'
                        value={statusPagamento}
                        onChange={handleChangeInscricao}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col>
                  <Button onClick={() => handleComprovanteUpdate(selected)}>
                    Alterar status do pagamento
                  </Button>
                </Col>

                {comprovanteType === 'pdf' ? (
                  <Col className='mt-4'>
                    <a
                      href={`${config.api_path}/v1/transparencia/inscricao/comprovante/${id}`}
                      target='__blank'
                    >
                      Link para acessar o comprovante de pagamento
                    </a>
                  </Col>
                ) : (
                  <Col className='mt-4'>
                    <Col>
                      <FormLabel>Imagem do comprovante de pagamento</FormLabel>
                    </Col>
                    <Row>
                      <Image
                        src={`${config.api_path}/v1/transparencia/inscricao/comprovante/${id}`}
                        width={400}
                      />
                    </Row>
                  </Col>
                )}
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col>
              <Button
                onClick={() => {
                  if (selected) {
                    show(selected);
                    setStatusPagamento(selected.statusPagamento);
                  }
                }}
              >
                Comprovante de Pagamento/Status
              </Button>
            </Col>
            <Row>
              <Col>
                <Button
                  href={`${config.api_path}/v1/transparencia/inscricao/arquivo.csv`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Exportar como CSV
                </Button>
              </Col>
            </Row>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Evento</FormLabel>
                <Input.AsyncSelect
                  id='evento'
                  value={values.evento}
                  onChange={handleChange}
                  isInvalid={errors.evento}
                  path={`${config.api_path}/public/v1/evento`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um Evento'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.evento}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleChange}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>CPF</FormLabel>
                <InputGroup>
                  <Input.Mask
                    mask={'999.999.999-99'}
                    id='cpf'
                    value={values.cpf}
                    onChange={handleChange}
                    isInvalid={errors.cpf}
                    disabled
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.cpf}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='email'
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={errors.email}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>Celular</FormLabel>
                <InputGroup>
                  <Input.Mask
                    mask={'(99) 9 9999-9999'}
                    id='celular'
                    value={values.celular}
                    onChange={handleChange}
                    isInvalid={errors.celular}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.celular}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>CEP</FormLabel>
                <InputGroup>
                  <Input.Mask
                    mask={'99999-999'}
                    id='cep'
                    value={values.cep}
                    onChange={handleChange}
                    isInvalid={errors.cep}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.cep}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>Endereço</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='endereco'
                    value={values.endereco}
                    onChange={handleChange}
                    isInvalid={errors.endereco}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.endereco}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Bairro</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='bairro'
                    value={values.bairro}
                    onChange={handleChange}
                    isInvalid={errors.bairro}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.bairro}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>Município</FormLabel>
                <InputGroup>
                  <Input.AsyncSelect
                    id='municipio'
                    value={values.municipio}
                    onChange={handleChange}
                    isInvalid={errors.municipio}
                    path={`${config.api_path}/public/v1/municipio`}
                    label={'${uf} - ${nome}'}
                    sort={{ uf: 'ASC', nome: 'ASC' }}
                    rowKey='id'
                    nullable
                    placeholder='Selecione um Munícipio'
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.municipio}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Complemento</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='complementoEndereco'
                    value={values.complementoEndereco}
                    onChange={handleChange}
                    isInvalid={errors.complementoEndereco}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.complementoEndereco}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group>
                <FormLabel>Instituição Representada</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='instituicaoRepresentada'
                    value={values.instituicaoRepresentada}
                    onChange={handleChange}
                    isInvalid={errors.instituicaoRepresentada}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.instituicaoRepresentada}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

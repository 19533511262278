import { faBan, faCheck, faFilePdf, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { Button, Col, Container, Form, FormLabel, InputGroup, Modal, Row, Spinner, Table } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Unidade gestora', property: 'unidadeGestora.nome', filter: true },
  { header: 'Modalidade', property: 'modalidadeTipo.nome', filter: true },
  { header: 'Número da Modalidade', property: 'numero', filter: true },
  { header: 'Número do Processo	', property: 'numeroProcesso', filter: true },
  { header: 'Exercício', property: 'exercicio', filter: {type: 'integer'} },
  {
    header: 'Data de Publicação',
    property: 'dataPublicacao',
    filter: true,
    format: (data) => format(new Date(data), 'dd/MM/yyyy'),
  },
  { header: 'Valor Estimado', property: 'valorEstimado', filter: true },
  { header: 'Valor Adjudicado', property: 'valorAdjudicado', filter: true },
  { header: 'Objeto', property: 'objeto', filter: true },
  { header: 'Situação', property: 'situacao', filter: true },
];

export default function LicitacaoPrivatePage() {
  const [visivel, setVisivel] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, ssetIsSaving] = useState(false);
  const [hideNovoArquivo, setHideNovoArquivo] = useState(true);
  const [idArquivo, setIdArquivo] = useState();
  const [arquivosLicitacao, setArquivosLicitacao] = useState([]);
  const [dataPublicacao, setDataPublicacao] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [licitacaoArquivoTipo, setLicitacaoArquivoTipo] = useState('');

  const hide = () => {
    setArquivosLicitacao([]);
    setVisivel(false);
  };

  const getArquivos = async (licitacaoId) => {
    const data = await Axios.get(`${config.api_path}/v1/transparencia/licitacao/arquivos/${licitacaoId}`);
    setArquivosLicitacao(data.data.data);
    setIsDeleting(false);
    ssetIsSaving(false);
  };

  const show = async (licitacao) => {
    getArquivos(licitacao.id);
    setVisivel(true);
    setHideNovoArquivo(true);
  };

  const handleUpload = async (e, licitacaoId) => {
    if (e) {
      ssetIsSaving(true);
      setHideNovoArquivo(true);
      try {
        const lista = e.data.data.split('/');
        const nomeArquivo = lista[lista.length - 1];
        await Axios.post(`${config.api_path}/v1/transparencia/licitacao/arquivo/salvar/${licitacaoId}`, {
          nomeArquivo: nomeArquivo,
          tipo: licitacaoArquivoTipo,
        });
        getArquivos(licitacaoId);
      } catch (error) {}
    }
  };

  const handleDeleteArquivo = async (arquivoId, licitacaoId) => {
    setIsDeleting(true);
    try {
      await Axios.delete(`${config.api_path}/v1/transparencia/licitacao/arquivo/deletar/${arquivoId}`);
      getArquivos(licitacaoId);
    } catch (error) {}
  };

  const hasBackSlash = (value) => {
    if (/\//.test(value)) {
      return value.split('/')[0];
    } else return value;
  };

  const handleKeyPress = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  return (
    <Crud
      header='Licitação'
      columns={columns}
      filter
      path={`${config.api_path}/v1/transparencia/licitacao`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <div>
            <Modal show={visivel} onHide={hide} size='xl'>
              <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
              <Modal.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        <FormLabel>Arquivos</FormLabel>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Container className='p-0 mb-4'>
                          {arquivosLicitacao
                            ? arquivosLicitacao
                                .sort((a, b) => {
                                  if (a.tipo && b.tipo) {
                                    return a.tipo.ordemExibicao - b.tipo.ordemExibicao;
                                  }
                                })
                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                .map((arquivo) => {
                                  var nome = arquivo.nomeArquivo;
                                  if (/-/.test(arquivo.nomeArquivo)) {
                                    const indice = arquivo.nomeArquivo.indexOf('-');
                                    nome = arquivo.nomeArquivo.substring(indice + 2);
                                  }
                                  return (
                                    <Container>
                                      <Row className='mb-2'>
                                        <td className='col'>
                                          <Col>
                                            <FormLabel>
                                              <a
                                                style={{
                                                  textDecoration: 'none',
                                                  color: '#495057',
                                                }}
                                                href={`https://transparencia.systemainformatica.com.br/entidade/licon/${identificador}/licitacoes/${dataPublicacao}/${arquivo.nomeArquivo}`}
                                                target='__blank'
                                              >
                                                <i className='mr-2'>
                                                  <FontAwesomeIcon icon={faFilePdf} />{' '}
                                                </i>
                                                {arquivo.tipo ? arquivo.tipo.nome + ' - ' : ''}
                                                {nome}
                                              </a>
                                            </FormLabel>
                                          </Col>
                                        </td>
                                        <td hidden={arquivo.licon}>
                                          <Row
                                            className='justify-content-center align-items-center'
                                            hidden={idArquivo === arquivo.id ? true : false}
                                          >
                                            <Col>
                                              <Button size='lg' onClick={() => setIdArquivo(arquivo.id)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                              </Button>
                                            </Col>
                                          </Row>
                                          {isDeleting ? (
                                            <Row className='mb-4 mt-2' hidden={idArquivo === arquivo.id ? false : true}>
                                              <Col>
                                                <Spinner animation='border' />
                                              </Col>
                                            </Row>
                                          ) : (
                                            <Row
                                              className='justify-content-center align-items-center'
                                              hidden={idArquivo === arquivo.id ? false : true}
                                            >
                                              <Col>
                                                <Button
                                                  size='lg'
                                                  onClick={() => handleDeleteArquivo(arquivo.id, arquivo.licitacao.id)}
                                                >
                                                  <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                              </Col>
                                              <Col>
                                                <Button size='lg' onClick={() => setIdArquivo(null)}>
                                                  <FontAwesomeIcon icon={faBan} />
                                                </Button>
                                              </Col>
                                            </Row>
                                          )}
                                        </td>
                                      </Row>
                                    </Container>
                                  );
                                })
                            : ''}
                          <Container hidden={!isSaving}>
                            <Row>
                              <td>
                                <Col>
                                  <Spinner animation='border' />
                                </Col>
                              </td>
                            </Row>
                          </Container>
                        </Container>

                        <Container className='p-0'>
                          <Row className='mb-4 mt-2'>
                            <Col>
                              <Button
                                onClick={() => {
                                  setHideNovoArquivo(false);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Col>
                          </Row>

                          <div hidden={hideNovoArquivo}>
                            <Container>
                              <Row className='mb-4'>
                                <td className='col'>
                                  <Col md={5}>
                                    <Form.Group>
                                      <FormLabel>Selecione o tipo do arquivo</FormLabel>
                                      <InputGroup>
                                        <Input.AsyncSelect
                                          id='licitacaoarquivotipo'
                                          value={licitacaoArquivoTipo}
                                          onChange={(e) => setLicitacaoArquivoTipo(e.target.value)}
                                          path={`${config.api_path}/v1/transparencia/licitacaoarquivotipo`}
                                          label={'${nome}'}
                                          rowKey='id'
                                          nullable
                                          placeholder='Selecione um tipo de arquivo'
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col hidden={!licitacaoArquivoTipo}>
                                    {
                                      <Dropzone
                                        route={`${config.api_path}/v1/transparencia/licitacao/arquivo/upload/${identificador}/${dataPublicacao}`}
                                        onUpload={(e) => handleUpload(e, selected ? selected.id : '')}
                                      />
                                    }
                                  </Col>
                                </td>
                              </Row>
                            </Container>
                          </div>
                        </Container>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col>
              <Button
                onClick={() => {
                  if (selected) {
                    setIdentificador(selected.clienteGlobal.identificador);
                    setDataPublicacao(selected.dataPublicacao.substring(0, 4));
                    show(selected);
                  }
                }}
              >
                Arquivos Anexados
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Exercício</FormLabel>
                <InputGroup>
                  <Input.Text
                    type='number'
                    id='exercicio'
                    value={values.exercicio}
                    onChange={handleChange}
                    isInvalid={errors.exercicio}
                    onKeyPress={handleKeyPress}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.exercicio}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Número do processo</FormLabel>
                <InputGroup>
                  <Input.Text
                    type='number'
                    id='numeroProcesso'
                    value={values.numeroProcesso && hasBackSlash(values.numeroProcesso)}
                    onChange={handleChange}
                    isInvalid={errors.numeroProcesso}
                    onKeyPress={handleKeyPress}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.numeroProcesso}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Nome da Modalidade</FormLabel>
                <Input.AsyncSelect
                  id='modalidadeTipo'
                  value={values.modalidadeTipo}
                  onChange={handleChange}
                  isInvalid={errors.modalidadeTipo}
                  path={`${config.api_path}/v1/transparencia/licitacaomodalidadetipo`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma modalidade'
                />
                <Form.Control.Feedback type='invalid'>{errors.modalidade}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Número da modalidade</FormLabel>
                <InputGroup>
                  <Input.Text
                    type='number'
                    id='numero'
                    value={values.numero && hasBackSlash(values.numero)}
                    onChange={handleChange}
                    isInvalid={errors.numero}
                    onKeyPress={handleKeyPress}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.numero}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Unidade gestora</FormLabel>
                <Input.AsyncSelect
                  id='unidadeGestora'
                  value={values.unidadeGestora}
                  onChange={handleChange}
                  isInvalid={errors.unidadeGestora}
                  path={`${config.api_path}/v1/transparencia/clienteglobalfiltered`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma unidade gestora'
                />
                <Form.Control.Feedback type='invalid'>{errors.unidadeGestora}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Data de Publicação</FormLabel>
                <InputGroup>
                  <Input.Date
                    id='dataPublicacao'
                    value={values.dataPublicacao}
                    onChange={handleChange}
                    isInvalid={errors.dataPublicacao}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.dataPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Data do Recebimento da Proposta</FormLabel>
                <InputGroup>
                  <Input.Date
                    id='dataRecebimentoProposta'
                    value={values.dataRecebimentoProposta}
                    onChange={handleChange}
                    isInvalid={errors.dataRecebimentoProposta}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.dataRecebimentoProposta}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Situação</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='situacao'
                    value={values.situacao}
                    onChange={handleChange}
                    isInvalid={errors.situacao}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.situacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Valor estimado</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='valorEstimado'
                    value={values.valorEstimado}
                    onChange={handleChange}
                    isInvalid={errors.valorEstimado}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.valorEstimado}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Valor adjudicado</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='valorAdjudicado'
                    value={values.valorAdjudicado}
                    onChange={handleChange}
                    isInvalid={errors.valorAdjudicado}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.valorAdjudicado}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Objeto</FormLabel>
                <InputGroup>
                  <Input.Text
                    as='textarea'
                    id='objeto'
                    value={values.objeto}
                    onChange={handleChange}
                    isInvalid={errors.objeto}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.objeto}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Participantes</FormLabel>
                <InputGroup>
                  <Input.Text
                    as='textarea'
                    id='participantes'
                    value={values.participantes}
                    onChange={handleChange}
                    isInvalid={errors.participantes}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.participantes}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Group>
                <FormLabel>Vencedores</FormLabel>
                <InputGroup>
                  <Input.Text
                    as='textarea'
                    id='vencedores'
                    value={values.vencedores}
                    onChange={handleChange}
                    isInvalid={errors.vencedores}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.vencedores}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

import * as yup from 'yup';

const schema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  email: yup
    .string()
    .email('Email precisa ser válido')
    .required('Campo obrigatório.'),
  cpf: yup.string().test('cpf-validate', 'CPF inválido', (value) => {
    if (!cpfValidator(value)) {
      throw new yup.ValidationError('Insira um CPF válido', value, 'cpf');
    }
    return true;
  }),

  celular: yup.string().required('Campo obrigatório.'),
  cep: yup.string().required('Campo obrigatório.'),
  endereco: yup.string().required('Campo obrigatório.'),
  bairro: yup.string().required('Campo obrigatório.'),
  municipio: yup.object().required('Campo obrigatório.'),
  confirmEmail: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('email')], 'Os emails não são iguais.'),
});

export function cpfValidator(cpf) {
  cpf = cpf ? cpf.replace(/\D/g, '') : '';

  if (cpf.length !== 11) {
    return false;
  }
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  return remainder === parseInt(cpf.substring(10, 11));
}

export default schema;

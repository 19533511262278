import React from 'react';
import { Component, Fragment } from 'react';
import { Form, FormControl, Overlay, Popover, ListGroup, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import debounce from 'es6-promise-debounce';
import Axios from 'axios';

import ScrollListener from '../ScrollListener';
import { extractValue, supplant, replaceAccents, removeAccents } from '../utils/formatador';
import { withViewport } from '../hocs';

class AsyncSelect extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleScrollBottom = this.handleScrollBottom.bind(this);
        
        this.search = this.search.bind(this);
        this.debouncedSearch = debounce(this.search, 1000);

        this.hideOptions = this.hideOptions.bind(this);
        this.isSelected = this.isSelected.bind(this);
    }

    state = {
        selected: null,
        search: '',
        
        focused: false,
        target: null,
        
        //Options
        options: [],
        offset: 0,
        count: 0,
    }

    handleScrollBottom() {
        if(!this.state.loading && (this.state.offset < this.state.count)){
            this.search();
        } 
    }

    search(clear) {
        this.setState({
            ...this.state,
            loading: true,
            options: clear ? [] : this.state.options
        }, () => {

            const { search, offset } = this.state;
            const { path, label, limit, dataFilter, sort, columns, types, params } = this.props;

            let filter = { ...dataFilter };

            if(search) {
                let or = {};
                let properties = label.match(/\${([^${}]*)}/g).map((attr) => attr.substring(2, attr.length-1));
                for(let property of properties) {
                    let type = (types && types[property]) || 'string';
                    if(["integer", "long", "short"].indexOf(type) >= 0) {
                        let value = search.replace(/\D/g, '');
                        if(value) {
                            or = {
                                ...or,
                                [property]: { compare: "=", value: `${type}:${value}`}
                            }
                        }
                    } else if(["double", "float" ].indexOf(type) >= 0) {
                        let value = search.replace(/[^0-9,.]/g, '');
                        if(value) {
                            or = {
                                ...or,
                                [property]: { compare: "=", value: `${type}:${value}`}
                            }
                        }
                    }else { //todos os outros serão considerados string
                        or = {
                            ...or,
                            [property]: { compare: "%%", value: `string:${replaceAccents(search)}`}
                        }
                    }
                }
                filter = { ...filter, or };
            }
            

            let url = `${path}?offset=${offset}&limit=${limit}&filter=${encodeURI(JSON.stringify(filter))}`;

            if(sort) url += `&sort=${encodeURI(JSON.stringify(sort))}`;
            if(columns) url += `&columns=${encodeURI(JSON.stringify(columns))}`;
            if(params) for(var i in params) if(params[i]) url = `${url}&${i}=${params[i]}`;

            Axios.get(url, {
                cancelToken: new Axios.CancelToken((c) => {
                    this.cancelContextRequest = c;
                })
            }).then(res => {
                let json = res.data;
                this.setState(prevState => {
                    if(json.error) return { ...prevState, loading: false };
                    if(clear) return {
                        ...prevState,
                        options: [...json.data.list],
                        offset: json.data.list.length,
                        count: json.data.count,
                        loading: false,
                    }
                    return {
                        ...prevState,
                        options: [...prevState.options, ...json.data.list],
                        offset: Math.min((prevState.offset + json.data.list.length), json.data.count),
                        count: json.data.count,
                        loading: false,
                    }
                });
            }, err => {
                if(!Axios.isCancel(err)){
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            });
        });
    }


    //New Methods
    componentDidMount() {
        const { lazy } = this.props;
        this.setState({
            ...this.state,
            selected: this.props.value
        }, () => {
            if(!lazy) this.search(true);
        });
    }

    componentWillUnmount() {
        if(this.cancelContextRequest) this.cancelContextRequest();
    }

    componentDidUpdate(oldProps) {
        if(oldProps.dataFilter !== this.props.dataFilter
        || oldProps.path !== this.props.path) {
            this.setState({
                ...this.state,
                selected: this.props.value,
                offset: 0,
                options: [],
            }, () => {
                if(!this.props.lazy) this.search(true);
            });
        } else if(oldProps.value !== this.props.value) {
            this.setState({
                ...this.state,
                selected: this.props.value,
                focused: false
            })
        }
    }

    handleFocus(e) {
        this.setState({
            ...this.state,
            focused: true,
            target: e.target,
        }, () => {
            if(!this.state.loading && this.state.options.length === 0)
                this.search()
        });
    }

    handleChange(e) {
        this.setState({
            ...this.state,
            search: e.target.value,
            offset: 0,
            options: [],
            loading: true,
        }, () => this.debouncedSearch(true));
    }

    hideOptions() {
        this.setState({
            ...this.state,
            focused: false
        });
    }

    handleBlur() {
        const { viewport } = this.props;
        if(viewport.width >= 768) this.hideOptions();
    }

    select(row) {
        const { id, name, onChange } = this.props;
        this.setState({
            ...this.state,
            selected: row,
            focused: false,
        }, () => {
            (onChange) && onChange({target: {id, name, value: row}}); 
        });
    }

    labelOf(value, highlight) {
        const { label } = this.props;
        if(!value) return "";

        let val = supplant(label, value);
        if(!highlight) return val;

        let index = removeAccents(val.toLowerCase()).indexOf(removeAccents(highlight.toLowerCase()));
        let length = highlight.length;

        if(index < 0) return val;

        return <span>{val.substring(0, index)}<b>{val.substring(index, index + length)}</b>{val.substring(index + length)}</span>
    }

    isSelected(option) {
        const { rowKey } = this.props;
        const { selected } = this.state;

        if(!option || !selected) return false;

        if(rowKey) return extractValue(selected, rowKey) === extractValue(option, rowKey);
        return selected === option;
    }

    render () {
        const { search, focused, target, options, selected, loading, } = this.state; 
        const { placeholder, viewport, size, nullable, disabled, isInvalid, className } = this.props;

        const renderedOptions = (
            <ListGroup variant="flush">  
                { (nullable && selected) &&
                    <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`} action
                        onClick={() => this.select(null)} >
                        <FontAwesomeIcon icon="times"/> Remover Seleção
                    </ListGroup.Item>
                }
                { (options && options.length > 0) ?
                    options.map((option, i) => (
                        <ListGroup.Item key={i} action active={this.isSelected(option)} className={(size && `list-group-item-${size}`)}
                            onMouseDown={() => {this.select(option); this.hideOptions()}} >
                                {this.labelOf(option, search)}
                        </ListGroup.Item>
                    ))
                    : !loading && 
                        <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`}>
                            Sem registros
                        </ListGroup.Item>
                }
                { (loading) &&  
                    <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`}>
                        <FontAwesomeIcon icon="cog" spin /> Carregando...
                    </ListGroup.Item> 
                }
            </ListGroup>
        )

        return (
            <Fragment>
                { viewport.width >= 768 ?
                    
                    <Overlay show={focused} target={target} placement="bottom-start">
                        <ScrollListener as={Popover} style={{maxHeight: 200, overflowY: 'auto', minWidth: (this.el ? this.el.clientWidth : 0) }}
                            onScrollBottom={this.handleScrollBottom}>
                            <Popover.Content className="px-0">
                                {renderedOptions}
                            </Popover.Content>
                        </ScrollListener>
                    </Overlay>
                    :
                    <Modal size="xl" show={focused} onHide={this.hideOptions} restoreFocus={false}
                        onScrollBottom={this.handleScrollBottom} scrollable={true}>

                        <Modal.Header closeButton>
                            <Form.Control value={focused ? search : this.labelOf(selected)} 
                                placeholder={selected ? this.labelOf(selected) : placeholder}
                                onChange={this.handleChange}/>
                        </Modal.Header>

                        <ScrollListener onScrollBottom={this.handleScrollBottom} as={Modal.Body} className="px-0"
                            style={{ overflowY: 'auto' }} >
                            {renderedOptions}
                        </ScrollListener>

                    </Modal>

                }

                <FormControl value={focused ? search : this.labelOf(selected)} 
                    placeholder={selected ? this.labelOf(selected) : placeholder}
                    
                    onFocus={this.handleFocus} onChange={this.handleChange}
                    ref={(el) => this.el = el} onBlur={this.handleBlur}
                    
                    autoComplete="off" disabled={disabled} isInvalid={isInvalid}
                    className={[className, "custom-select", (size && `custom-select-${size}`)].join(' ')}
                />
            </Fragment>
        );
    }

    static propsTypes = {
        id: PropTypes.string,
        name: PropTypes.string,

        placeholder: PropTypes.string,
        label: PropTypes.string.isRequired,
        rowKey: PropTypes.string,
        value: PropTypes.any,
        
        nullable: PropTypes.bool,
        
        columns: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        types: PropTypes.object,
        path: PropTypes.string.isRequired,

        sort: PropTypes.object,
        limit: PropTypes.number,
        dataFilter: PropTypes.object,

        disabled: PropTypes.bool,
    }

    static defaultProps = {

        placeholder: "Selecione um item",
        rowKey: "id",
        
        columns: null,
        sort: null,
        
        limit: 20,

        disabled: false
    }
}

export default withViewport(AsyncSelect);
import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import './Page.css';

export default function Page({ route }) {
  return (
    <Fragment>
      <Container
        className='main-navigation align-items-start p-0 m-0'
        style={{ minHeight: '700px' }}
        fluid
      >
        {route.content()}
      </Container>
    </Fragment>
  );
}

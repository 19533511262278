import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'es6-promise-debounce';
import React, { Component, Fragment } from 'react';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Input from '../../../components/Input';
import Switch from '../../../components/Switch';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';
import Service from '../../../components/Systema/Service';
import config from '../../../config';
import DocumentsModal from './DocumentsModal';
import LicitacaoItem from './LicitacaoItem';

class LicitacaoList extends Component {
  state = {
    loading: true,
    licitacoes: [],
    errors: false,
    expanded: false,

    filter: {},
    query: {},

    page: 1,

    showModal: false,
  };

  constructor(props) {
    super(props);

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.debouncedUpdateQuery = debounce(this.updateQuery, 1000);
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.updateQuery();
    this.service = new Service(
      `${config.api_path}/public/v1/${match.params.cliente}/licitacao`
    );
  }

  handleFilterChange(e) {
    const { filter } = this.state;
    this.setState({
      ...this.state,
      filter: {
        ...filter,
        [e.target.id]: e.target.value,
      },
    });
    this.debouncedUpdateQuery();
  }

  updateQuery() {
    const { filter } = this.state;

    let query = {};

    if (filter) {
      if (!filter.mostrarPassadas) {
        query = {
          ...query,
          dataHoraCertame: {
            compare: '>=',
            value: `date:${new Date().getTime()}`,
          },
        };
      }

      if (filter.exercicio) {
        query = {
          ...query,
          '1:dataHoraCertame': {
            compare: '>=',
            value: `date:${new Date(filter.exercicio, 1, 1).getTime()}`,
          },
          '2:dataHoraCertame': {
            compare: '<=',
            value: `date:${new Date(filter.exercicio, 12, 31).getTime()}`,
          },
        };
      }

      if (filter.modalidadeLicitacao) {
        query = {
          ...query,
          'modalidadeLicitacao.id': {
            compare: '=',
            value: `long:${filter.modalidadeLicitacao.id}`,
          },
        };
      }

      if (filter.numeroProcesso) {
        query = {
          ...query,
          numeroProcesso: {
            compare: '=',
            value: `integer:${filter.numeroProcesso}`,
          },
        };
      }

      if (filter.numeroPorModalidade) {
        query = {
          ...query,
          numeroPorModalidade: {
            compare: '=',
            value: `integer:${filter.numeroPorModalidade}`,
          },
        };
      }

      if (filter.objeto) {
        query = {
          ...query,
          objeto: { compare: '%%', value: filter.objeto },
        };
      }
    }

    this.setState({
      ...this.state,
      query,
    });
  }

  setPage(page) {
    this.setState({
      ...this.state,
      page: page,
    });
  }

  render() {
    const { match } = this.props;
    const { page, expanded, showModal, filter, query } = this.state;

    return (
      <Fragment>
        <DocumentsModal
          show={showModal}
          licitacao={expanded}
          onHide={() => this.setState({ ...this.state, showModal: false })}
        />

        <Card className='m-4'>
          <Card.Body>
            <Card.Title>
              <FontAwesomeIcon icon={faSearch} /> Buscar Licitações
            </Card.Title>

            <Form.Row>
              <Col xs={12} md={12} lg={3}>
                <Form.Label>Modalidade da Licitação</Form.Label>
                <Input.Select
                  nullable={true}
                  path={`${config.api_path}/public/v1/${match.params.cliente}/modalidadelicitacao`}
                  placeholder='Modalidade'
                  id='modalidadeLicitacao'
                  value={filter.modalidadeLicitacao}
                  //eslint-disable-next-line
                  label='${nome}'
                  onChange={this.handleFilterChange}
                />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Form.Label>Número da Licitação</Form.Label>
                <Input.Integer
                  placeholder={`001`}
                  id='numeroPorModalidade'
                  onChange={this.handleFilterChange}
                  value={filter.numeroPorModalidade}
                />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Form.Label>Número do Processo</Form.Label>
                <Input.Integer
                  placeholder={`001`}
                  id='numeroProcesso'
                  onChange={this.handleFilterChange}
                  value={filter.numeroProcesso}
                />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Form.Label>Exercicio</Form.Label>
                <Input.Integer
                  placeholder={`${new Date().getFullYear()}`}
                  id='exercicio'
                  onChange={this.handleFilterChange}
                  value={filter.exercicio}
                />
              </Col>
              <Col xs={12}>
                <Form.Label>Objeto</Form.Label>
                <Input.Text
                  placeholder='Objeto contém...'
                  id='objeto'
                  onChange={this.handleFilterChange}
                  value={filter.objeto}
                />
              </Col>

              <Col xs={12} className='pt-4'>
                <Switch
                  label='Mostrar Licitações passadas'
                  id='mostrarPassadas'
                  onChange={(e) =>
                    this.handleFilterChange({
                      target: {
                        id: e.target.id,
                        value: !filter.mostrarPassadas,
                      },
                    })
                  }
                  value={filter.mostrarPassadas}
                />
              </Col>
            </Form.Row>
          </Card.Body>
        </Card>

        <div className='my-2 m-4'>
          <DataPagination
            service={this.service}
            filter={query}
            page={page}
            limit={10}
          >
            {({ data, count, error, loading, page, lastPage, limit }) => (
              <Fragment>
                {loading ? (
                  <ListGroup.Item>
                    <FontAwesomeIcon icon='cog' spin /> Carregando licitações...
                  </ListGroup.Item>
                ) : data.length <= 0 ? (
                  <ListGroup.Item className='text-muted'>
                    Nenhuma licitação encontrada.
                  </ListGroup.Item>
                ) : (
                  <Fragment>
                    <ListGroup>
                      {data.map((licitacao, i) => (
                        <LicitacaoItem
                          key={i}
                          licitacao={licitacao}
                          expand={expanded === licitacao}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              expanded: licitacao,
                            })
                          }
                          onButtonClick={(e) =>
                            this.setState({ ...this.state, showModal: true })
                          }
                        />
                      ))}
                    </ListGroup>
                  </Fragment>
                )}
                <Row className='my-2 align-items-center'>
                  <Col xs={4}></Col>
                  <Col xs={4} className='d-flex justify-content-center'>
                    <Paginator
                      page={page}
                      lastPage={lastPage}
                      onPage={this.setPage}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}
          </DataPagination>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(LicitacaoList);

import React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Loading extends Component {
    render () {
        return (
            <div className="fixed-top d-flex align-items-center justify-content-center vw-100 vh-100" style={{background: 'rgba(0, 0, 0, .5)'}}>
                <div className="card">
                    <div className="card-body">
                        <FontAwesomeIcon icon="cog" spin /> {this.props.message || "Carregando..."}
                    </div>
                </div>
            </div>
        )
    }
}

export default Loading;
import { upperFirst } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';

const Decimal = ({ id, name, value, onChange, locale = 'pt-br', fractions = 2, ...rest }) => {
    

    //Formatar valor que vai ser exibido.
    let displayValue;
    if(!value && value !== 0) displayValue = '';
    else displayValue = value.toLocaleString(locale, { minimumFractionDigits: fractions});

    //Tratar mudanças de valor
    const handleChange = (e) => {
        let inputValue = e.target.value;
        let rawValue = 0.0;
        if(inputValue == "") {
            rawValue = undefined;
        } else {
            rawValue = parseFloat(inputValue.replace(/\D/g, ''));
            if(!rawValue || isNaN(rawValue)) rawValue = 0;
            if(fractions > 0) rawValue /= Math.pow(10, fractions);
            if(inputValue.indexOf('-') >= 0) rawValue *= -1
            if(inputValue.indexOf('+') >= 0 && rawValue < 0) rawValue *= -1;
        }

        if(onChange) onChange({ target: { id, name, value: rawValue }});
    }

    return (
        <Form.Control value={displayValue} onChange={handleChange} {...rest}/>
    )
}


export default Decimal;
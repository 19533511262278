import { LOAD_PERSON, SET_PERSON, PERSON_ERROR, REMOVE_PERSON } from "../actions/actionTypes";

const initialState = {
    person: null,
    loading: false,
    loaded: false,

    errors: []
};

export const PersonReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_PERSON:
            return {
                ...state,
                person: null,
                loading: true,
                loaded: false
            }
        case SET_PERSON:
            if(action.value.error) {
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    errors: action.value.messages
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    person: action.value.data,
                    errors: action.value.messages
                };
            }
        case PERSON_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: action.value.messages
            }
        case REMOVE_PERSON:
            return {
                ...state,
                person: null,
                loaded: false,
                loading: false,
            };
        default:
            return state;
    }
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { Fragment, useState } from "react";
import { Button, Col, Form, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import Crud from "../../../components/Crud"
import Input from "../../../components/Input"
import config from '../../../config';
import schema from "./schema"
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const columns = [
    { header: "Nome", property: "nome", filter: true },
    { header: "Nome da URL", property: "nomeUrl", filter: true },
    { header: "Ordem exibição", property: "ordemExibicao", filter: true },
]


export default function EntidadePage() {
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)

    function handleKeyPress(event) {
        const pattern = /^[a-z]+$/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    async function descer() {
        setLoading(true);
        await Axios.post("/v1/transparencia/entidade/trocar-ordem?direcao=descer", selected)
        setLoading(false);
    }
    async function subir() {
        setLoading(true);
        await Axios.post("/v1/transparencia/entidade/trocar-ordem?direcao=subir", selected)
        setLoading(false);
    }

    return (
        <Crud
            contextMenu={[
                {
                    buttons: [
                        { icon: "arrow-up", name: "Subir", onClick: subir },
                        { icon: "arrow-down", name: "Descer", onClick: descer }
                    ]
                }
            ]}
            onSelect={setSelected}
            header="Entidade"
            columns={columns}
            path={`${config.api_path}/v1/transparencia/entidade`}
            rows={20}
            schema={schema}
            buttonBar={({ selected }) => (
                <>
                    <Row>
                        <Button onClick={() => subir(selected)}><FontAwesomeIcon icon={faArrowUp} />Subir</Button>
                        <Col>
                            <Button onClick={() => descer(selected)}><FontAwesomeIcon icon={faArrowDown} />Descer</Button>
                        </Col>
                    </Row>
                </>

            )}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Entidade</FormLabel>
                                <Input.AsyncSelect id="cliente" value={values.cliente} onChange={handleChange} isInvalid={errors.cliente}
                                    path={`${config.api_path}/v1/transparencia/clientesiorconp`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione uma Enteidade"
                                />
                                <Form.Control.Feedback type="invalid">{errors.cliente}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nome" value={values.nome} onChange={handleChange} isInvalid={errors.nome} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Nome da URL</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nomeUrl" value={values.nomeUrl} onChange={handleChange} isInvalid={errors.nomeUrl} onKeyPress={handleKeyPress} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nomeUrl}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Row>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>

            )}

        ></Crud>
    )
}
import React from 'react';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const CustomDropdown = React.forwardRef( ({ children, onClick }, ref) => (
    <button ref={ref} className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" 
        onClick={(e) => {
            e.preventDefault(); 
            onClick(e);
        }} >
        {children}
    </button>
));

class NavbarSubitem extends Component {

    render() {
        const { menu } = this.props;
        
        if(menu.items) return (
            <Dropdown id={menu.id} drop="right">
                <Dropdown.Toggle as={CustomDropdown}>{menu.name}</Dropdown.Toggle>
                <Dropdown.Menu flip={true}>
                    { menu.items.map(subitem => ( <NavbarSubitem key={subitem.id} menu={subitem}/>))}
                </Dropdown.Menu>
            </Dropdown>
        )

        if(menu.to.startsWith('/http')) return (
            <a className="dropdown-item" href={menu.to.substring(1)} target="_blank"> {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name} <FontAwesomeIcon icon={faExternalLinkAlt}/></a>
        )

        return (
            <Dropdown.Item as={NavLink} to={menu.to}>
                {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name}
            </Dropdown.Item>
        )
    }
}

export default NavbarSubitem;
import React, { useState } from 'react';
import {
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap';
import Qrcode from '../../../../qrcode.jpg';
import SubTitulo from '../Subtitulos';
import Validacao from '../Validacao';
import { Comprovante } from '../utils';

export default function QrcodePix({ userId, cpf }) {
  const [userInfos, setUserInfos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const comprovante = async (file) => {
    setLoading(true);
    try {
      await Comprovante(file, userId, cpf);
      setUserInfos(true);
      setShow(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return userInfos ? (
    <>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        className='bg-success mt-4'
        delay={3000}
        autohide
      >
        <Toast.Body>Comprovante enviado com sucesso</Toast.Body>
      </Toast>
      <Validacao userCPF={cpf} />
    </>
  ) : (
    <>
      <SubTitulo
        texto={
          'Para finalizar sua inscrição, efetue o pagamento utilizando os dados do pix abaixo. Em seguida envie o comprovante do pix ou depósito clicando em "Escolher arquivo"'
        }
        isHidden={false}
      />

      <Card>
        <Card.Body className='p-0'>
          {/* <ListGroup className='flush'>
              <ListGroup.Item> */}
          <Container>
            <Row className='row-centered'>
              <Image src={Qrcode} />
            </Row>
            <Row className='row-centered'>
              <b>Pix copia e cola:</b>
            </Row>
            <Col className='row-centered mb-4' style={{ textAlign: 'center' }}>
              00020126360014BR.GOV.BCB.PIX0114086184740001035204000053039865406650.005802BR5919GRUPO
              RONALDO ALVES6009Olinda/PE62210517INSCRICAOEVTCUSTO6304FD39
            </Col>
            <Row className='row-centered'>
              <b>Chave Pix:</b> 08.618.474/0001-03
            </Row>
            <Row className='row-centered'>
              <b>Beneficiário:</b> GRUPO RONALDO ALVES
            </Row>
            <Row className='row-centered'>
              <b>Banco do Brasil</b>
            </Row>
            <Row className='row-centered'>
              <b>Agência:</b> 2365-5
            </Row>
            <Row className='row-centered'>
              <b>C/C:</b> 51535-3
            </Row>
            <Row className='row-centered mb-2'>
              <b>Valor:</b> R$ 650,00
            </Row>
          </Container>
          {/* </ListGroup.Item>
            </ListGroup> */}
        </Card.Body>
      </Card>
      <Form.Group controlId='formFile' className='mt-4'>
        <Form.Control
          type='file'
          accept='.pdf, .jpg, .jpeg, .png'
          onChange={(e) => comprovante(e.target.files[0])}
        />
      </Form.Group>
      {loading && (
        <>
          <Spinner animation='border' />{' '}
          <span className='ml-2'>
            Aguarde enquanto salvamos o seu comprovante...
          </span>
        </>
      )}
    </>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  InputGroup,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import {
  NavLink,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import config from '../../../config';
import { useCliente } from '../../../navigation/context/ClienteContext';
import BarraAcessibilidade from '../PortalTransparenciaPage/BarraAcessibilidade/barraAcessibilidade';
import HeaderPortal from '../PortalTransparenciaPage/Header/header';
import NavigationBreadcrumb from '../../../navigation/NavigationBreadcrumb';

export default function List() {
  const match = useRouteMatch();
  const location = useLocation();
  const { identificador } = useCliente();
  const [loading, setLoading] = useState(false);
  const [servicos, setServicos] = useState();
  const [estrutura, setEstrutura] = useState();
  const [filter, setFilter] = useState();

  const fetchEstrutura = useCallback(async (identificador, unidade) => {
    let url = `${config.api_path}/public/v1/${identificador}/estrutura-organizacional/${unidade}`;
    try {
      let res = await Axios.get(url);

      if (!res.data.error) {
        setEstrutura(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchServicos = useCallback(async (identificador, unidade) => {
    setLoading(true);

    let url = `${config.api_path}/public/v1/${identificador}/servico/estrutura/${unidade}`;

    try {
      let res = await Axios.get(url);

      if (!res.data.error) {
        setServicos(res.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);
  
  useEffect(() => {
    fetchEstrutura(identificador, match.params.unidade);
    fetchServicos(identificador, match.params.unidade);
  }, [fetchEstrutura, fetchServicos, identificador, match.params.unidade]);

  return (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='pt-4 mb-4'>
        {!location.estruturaData && estrutura && (
          <Redirect
            to={{
              pathname: match.url,
              estruturaData: estrutura,
              overrideLabel: {
                ...(location.overrideLabel ? location.overrideLabel : {}),
                [match.url]: estrutura.nome,
              },
            }}
          />
        )}

        {estrutura && <h2 className='display-4'>{estrutura.nome}</h2>}

        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon='search' />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder='Filtrar'
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </InputGroup>

        <ListGroup className='mt-2'>
          {!loading &&
            servicos &&
            servicos
              .filter((servico) => servico.nome.match(new RegExp(filter, 'i')))
              .map((servico, i) => (
                <ListGroup.Item
                  action
                  key={i}
                  className='text-dark'
                  as={NavLink}
                  to={{
                    pathname: `${match.url}/detail/${servico.id}`,
                    overrideLabel: {
                      ...location.overrideLabel,
                      [`${match.url}/detail/${servico.id}`]: servico.nome,
                    },
                  }}
                >
                  <div className='d-flex w-100 justify-content-between align-items-center'>
                    <div>
                      <h4>{servico.nome}</h4>
                      <p>{servico.descricao}</p>
                    </div>
                    <FontAwesomeIcon icon={'arrow-right'} />
                  </div>
                </ListGroup.Item>
              ))}
          {loading && (
            <ListGroup.Item className='d-flex align-items-center'>
              <Spinner animation='border' />{' '}
              <span className='ml-2'>Carregando...</span>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Container>
    </>
  );
}

// class List extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       estrutura: props.location.estruturaData,
//       servicos: [],
//       loading: false,
//       filter: '',
//     };
//   }

//   componentDidMount() {
//     const { estrutura } = this.state;
//     if (!estrutura) {
//       this.fetchEstrutura();
//     }
//     this.fetchServicos();
//   }

//   async fetchEstrutura() {
//     const { match } = this.props;
//     let url = `${config.api_path}/public/v1/${match.params.cliente}/estrutura-organizacional/${match.params.unidade}`;
//     try {
//       let res = await Axios.get(url);

//       if (!res.data.error) {
//         this.setState({
//           ...this.state,
//           estrutura: res.data.data,
//         });
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   async fetchServicos() {
//     const { match } = this.props;

//     await this.setState({ ...this.state, loading: true });

//     let url = `${config.api_path}/public/v1/${match.params.cliente}/servico/estrutura/${match.params.unidade}`;

//     try {
//       let res = await Axios.get(url);

//       if (!res.data.error) {
//         this.setState({
//           ...this.state,
//           servicos: res.data.data,
//         });
//       }
//     } catch (e) {
//       console.log(e);
//     } finally {
//       await this.setState({ ...this.state, loading: false });
//     }
//   }

//   render() {
//     const { match, location } = this.props;
//     const { servicos, loading, estrutura, filter } = this.state;

//     return (

//     );
//   }
// }

// export default withRouter(List);

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import './styles.scss';

export default function Whatsapp() {
  return (
    <div className='whatsapp'>
      <a href='https://wa.me/558132214951' target='__blank'>
        <Button>
          <FontAwesomeIcon icon={faWhatsapp} size='3x' />
        </Button>
      </a>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import NavigationBreadcrumb from '../../../../navigation/NavigationBreadcrumb';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import BarraAcessibilidade from '../BarraAcessibilidade/barraAcessibilidade';
import Footer from '../Footer/footer';
import HeaderPortal from '../Header/header';
import './declaracaoInexistencia.scss';

export default function DeclaracaoInexistenciaPublicPage() {
  const currentlyLocation = useLocation();
  const [location, setLocation] = useState();
  const { declaracoes, portal, grupoComCategoria, portalCliente } = useCliente();

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentlyLocation]);
  return declaracoes && portal && grupoComCategoria && portalCliente ? (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='declaracao-page'>
        <header className='titulo'>
          <h2>Declaração de Inexistência de Informações</h2>
        </header>
        <Container fluid>
          <Table>
            <thead>
              <tr>
                <th>Conteúdo</th>
                <th>Grupo</th>
                <th>Periodo de inexistência</th>
              </tr>
            </thead>
            <tbody>
              {declaracoes.map((declaracao) => {
                return (
                  declaracao.categoriaPublicacao &&
                  declaracao.categoriaPublicacao.grupoCategoriaPublicacao && (
                    <tr key={declaracao.id}>
                      <td>
                        <NavLink
                          exact
                          to={`${location}/detail/${declaracao.categoriaPublicacao.grupoCategoriaPublicacao.nomeUrl}/${declaracao.categoriaPublicacao.nomeUrl}`}
                        >
                          {declaracao.categoriaPublicacao.nome}
                        </NavLink>
                      </td>
                      <td>{declaracao.categoriaPublicacao.grupoCategoriaPublicacao.nome}</td>
                      <td>{declaracao.periodo}</td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Container>
      <Footer />
    </>
  ) : (
    <Loading />
  );
}

import React from 'react';
import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FloatButton extends Component {
    render () {
        const { position, className, icon, onClick, disabled, variant} = this.props;
        
        let _style = {left: 'auto', right: '1.2rem', bottom: '1.2rem'};
        if(position === 'right')
            _style = {left: '1.2rem', right: 'auto', bottom: '1.2rem'};


        return (
            <div className={`${className} fixed-bottom`} style={_style}>
                <Button variant={variant} size="lg" className="rounded-circle" style={{width: 60, height: 58}} onClick={onClick} disabled={disabled}>
                    <FontAwesomeIcon icon={icon} />
                </Button>
            </div>
        );
    }
}

export default FloatButton;
import { faChevronDown, faChevronUp, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse, Container } from 'react-bootstrap';
import { useCliente } from '../../../navigation/context/ClienteContext';
import { FaqService } from '../PortalTransparenciaPage/utils/useServices';
import './faq.scss';

export function ComponenteHTML({ htmlString }) {
  const createMarkup = () => {
    return { __html: htmlString };
  };
  return <div className='resposta' dangerouslySetInnerHTML={createMarkup()} />;
}

export default function Faq() {
  const { identificador } = useCliente();
  const buttonRef = useRef(null);
  const [faqs, setFaqs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    if (identificador) {
      const fetchFaq = async () => {
        const { data } = await FaqService(identificador);
        setFaqs(data.data);
      };
      fetchFaq();
    }
  }, [identificador]);

  return (
    <Container fluid className='p-0'>
      {faqs &&
        faqs
          .sort((a, b) => a.ordemExibicao - b.ordemExibicao)
          .map((faq, index) => (
            <div className='box-pergunta' key={index}>
              <button
                ref={buttonRef}
                className='pergunta'
                onClick={() => {
                  if (currentIndex === index) {
                    setCurrentIndex(null);
                    buttonRef.current.blur();
                  } else {
                    setCurrentIndex(index);
                  }
                }}
                aria-controls={`collapse-text-${index}`}
                aria-expanded={currentIndex === index}
              >
                <div className='question-icon'>
                  <FontAwesomeIcon icon={faQuestion} />
                  {faq.pergunta}
                </div>
                <FontAwesomeIcon hidden={currentIndex === index} icon={faChevronDown} />
                <FontAwesomeIcon hidden={currentIndex !== index} icon={faChevronUp} />
              </button>
              <Collapse in={currentIndex === index}>
                <div id={`collapse-text-${index}`}>
                  <ComponenteHTML key={index} htmlString={faq.respostaHTML} />
                </div>
              </Collapse>
            </div>
          ))}
    </Container>
  );
}

import React from 'react';
import { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';

class AlertList extends Component {
    render () {
        const { alerts } = this.props;

        return (
            <Fragment>
                {alerts && 
                    alerts.map( (alert, i) => (
                        <Alert key={i} variant={alert.code >= 3000 ? "danger" : (alert.code >= 2000 ? "warning" : "info")}>{alert.description}</Alert>
                    ))
                }
            </Fragment>
        );
    }
}

export default AlertList;
import React from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import './groupCard.scss';

export default function GroupCard({ nomeGrupo, grupoNomeUrl, categorias }) {
  const location = useLocation();
  return (
    <Card className='groupcard'>
      <Card.Header className='groupcard-header'>
        <NavLink className='grupo-nome' to={`${location.pathname}/detail/${grupoNomeUrl}`}>
          <h2>{nomeGrupo}</h2>
        </NavLink>
      </Card.Header>
      <Card.Body>
        <CardDeck className='groupcard-deck'>
          {categorias
            .sort((a, b) => (a.ordemExibicao > b.ordemExibicao ? 1 : -1))
            .map((categoria, index) =>
              categoria.listado ? (
                categoria.linkExterno ? (
                  <a href={categoria.linkUrl} rel='noopener noreferrer' target='_blank' className='cards' key={index}>
                    {/* <FontAwesomeIcon icon={faDollarSign}/> */}
                    <h6>{categoria.nome}</h6>
                  </a>
                ) : (
                  <NavLink
                    to={`${location.pathname}/detail/${grupoNomeUrl}/${categoria.nomeUrl}`}
                    className='cards'
                    key={index}
                  >
                    <h6>{categoria.nome}</h6>
                  </NavLink>
                )
              ) : null
            )}
        </CardDeck>
      </Card.Body>
    </Card>
  );
}

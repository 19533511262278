import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { CSSTransition }  from 'react-transition-group'
import { extractValue } from '../utils/formatador';

import DataTable from '../DataTable';
import Loading from '../Loading/Loading';
import Axios from 'axios';

import states from './states';
import ConfirmModal from '../ConfirmModal';
import FloatButton from '../FloatButton';
import ButtonBar from './ButtonBar';
import { Redirect, withRouter } from 'react-router-dom';
import CrudForm from './CrudForm';
import Header from '../Header';
import { handleAxiosResponse, handleAxiosError } from '../utils/handlers';
import Tree from '../Tree';

const mapStateToProperties = store => ({
    config: store.configState
});

class Crud extends Component {
    constructor(props, context) {
        super(props, context);

        this.setSelected = this.setSelected.bind(this);

        this.showInsert = this.showInsert.bind(this);
        this.showUpdate = this.showUpdate.bind(this);
        this.showRectify = this.showRectify.bind(this);
        this.showDelete = this.showDelete.bind(this);
        this.showState = this.showState.bind(this);
        this.showVisualize = this.showVisualize.bind(this);
        
        this.confirm = this.confirm.bind(this);
        this.return = this.return.bind(this);
        this.checkError = this.checkError.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    state = {
        currentState: states.consulta,

        showConfirm: false,

        selected: null,

        loading: false,
        loadingMessage: '',

        page: 0,

        contextMenu: [],

        linkTo: null
    }

    componentDidMount() {
        this.buildContextMenu();

        switch (this.props.initialState) {
            case "inclusao":
                this.showInsert();
                break;
            case "alterar":
                if(this.props.selected) this.setState({
                    ...this.state,
                    selected: this.props.selected
                }, () => {
                    this.showUpdate();
                });
                break;
            case "retificar":
                this.showRectify();
                break;
            case "visualizar":
                this.showVisualize();
                break;
            default:
                return;
        }
    }

    componentDidUpdate(oldProps) {
        if(oldProps.disableAlterar !== this.props.disableAlterar
            || oldProps.rectifiable !== this.props.rectifiable
            || oldProps.disableExcluir !== this.props.disableExcluir
            || oldProps.contextMenu !== this.props.contextMenu) {
            this.buildContextMenu();
        }
    }

    buildContextMenu() {
        const { disableAlterar, rectifiable, disableExcluir, disableVisualizar, contextMenu : appendContextMenu = [] } = this.props
        let updateButtons = [];
        let deleteButtons = [];

        if(!disableVisualizar) updateButtons.push({ variant: "outline-dark", icon:"book-open", name:"Visualizar", onClick :this.showVisualize});
        if(!disableAlterar) updateButtons.push({ variant: "outline-warning", icon: "edit", name: "Alterar", onClick: this.showUpdate});
        if(rectifiable) updateButtons.push({ variant: "outline-warning", icon: "eraser", name: "Retificar", onClick: this.showRectify});
        if(!disableExcluir) deleteButtons.push({ variant: "outline-danger", icon: "trash", name: "Excluir", onClick: this.showDelete});
        
        let contextMenu = [];
        if((updateButtons.length > 0)) contextMenu.push({ buttons: updateButtons });
        if(deleteButtons.length > 0) contextMenu.push({buttons: deleteButtons});
        contextMenu = [...contextMenu, ...appendContextMenu];
        
        this.setState({
            ...this.state,
            contextMenu
        })
    }

    setSelected(row) {
        const { onSelect } = this.props;
        
        this.setState({
            ...this.state,
            selected: row
        }, () => {
            if(onSelect) onSelect(row);
        });

    }

    hasSelected() {
        if(!this.state.selected) {
            this.props.alert.show("Selecione um registro", {type: "warning"});
            return false;
        }
        return true;
    }

    processValues(values, processor, nextState) {
        const { alert } = this.props;
        this.setState({
            ...this.state,
            loading: true
        });

        Promise.resolve(processor(values)).then(values => {
            this.setState({
                ...this.state,
                loading: false,
                selected: values,
                currentState: nextState
            })
        }, err => {
            err.messages.forEach((msg) => alert.error(msg.descrition));
            this.setState({
                ...this.state,
                loading: false
            });
        });
    }

    showState(state) {
        const { selected } = this.state;
        this.processValues(selected, (values => (values)), state);
    }

    showInsert() {
        const { schema, incluirShow } = this.props;
        this.processValues(schema.default(), incluirShow || (values => (values)), states.inclusao);
    }

    showUpdate() {
        if(this.hasSelected()) {
            const { alterarShow, validateAlterar } = this.props;
            const { selected } = this.state;
            if(!validateAlterar || validateAlterar(selected)){
                this.processValues(selected, alterarShow || (values => (values)), states.alteracao);
            }   
        }
    }

    showRectify() {
        if(this.hasSelected()) {
            const { alterarShow } = this.props;
            const { selected } = this.state;
            this.processValues(selected, alterarShow || (values => (values)), states.retificacao);
        }
    }

    showVisualize(){
        if(this.hasSelected()){
            const {visualizarShow } = this.props;
            const {selected} = this.state;
            this.processValues(selected, visualizarShow || (values => (values)), states.visualizar);
        }
    }

    showDelete() {
        if(this.hasSelected()) {
            this.setState({
                ...this.state,
                currentState: states.exclusao,
                showConfirm: true,
            });
        }
    }

    submit(saved) {
        this.setState({
            ...this.state,
            showConfirm: true,
            selected: saved
        });
    }

    confirm() {
        const { alert, path } = this.props;
        const { currentState, selected } = this.state;

        if(currentState === states.exclusao){
            Axios.delete(`${path}/${selected.id}`).then(
                handleAxiosResponse(alert),
                handleAxiosError(alert)
            ).then(this.checkError);
        }
        
        this.setState({
            ...this.state,
            loading: true,
            showConfirm: false,
        });
    }

    checkError(json){
        const { currentState } = this.state;
        if(json.error) {
            this.return((currentState === states.exclusao ? states.consulta : currentState))
        } else {
            this.return();
        }
    }

    handleSubmit(values) {
        this.setState({
            ...this.state,
            loading: true,
            showConfirm: false,
        });
        if(this.props.onFormSubmit) this.props.onFormSubmit(values);
    }

    handleSuccess(json) {
        this.checkError(json);
        if(this.props.onFormSuccess) this.props.onFormSuccess(json);
    }

    handleError(err) {
        const { alert } = this.props;
        alert.error(err.message);
        if(this.props.onFormError) this.props.onFormError(err);
    }

    return(toState) {
        if(this.dataTable) this.dataTable.refresh();
        this.setState({
            ...this.state,
            showConfirm: false,
            loading: false,
            currentState: toState ? toState : states.consulta,
            linkTo: this.props.linkTo ? this.props.linkTo : null,
         //   selected: null
        });

        const { onReturn } = this.props
        if(onReturn) onReturn()
    }

    render () {

        const { header, fluid, path, form, schema, config, onFormChange, isTree } = this.props; //General properties
        const { rows, columns, defaultSort, filter, filterColumns, markRowByFalseAttr, rowStyles } = this.props; //Data Table properties
        const { rectifiable, disableIncluir, disableAlterar, disableExcluir, disableVisualizar, buttonBar, buttonBarSize = "sm", showSave = true, showReturn = true } = this.props; //Buttons properties
        const { loading, currentState, showConfirm, selected, page, contextMenu } = this.state;

        const buttonBarProps = {
            disableIncluir, disableAlterar, disableExcluir, disableVisualizar,
            rectifiable, buttonBar, 
            size: buttonBarSize,
            selected: selected,
            showInsert: this.showInsert,
            showUpdate: this.showUpdate,
            showDelete: this.showDelete,
            showState: this.showState,
            showVisualize: this.showVisualize,
        };

        return (
            
            /* Utilizado p/ voltar para um determinado link em vez da tela de consulta do crud */
            this.state.linkTo && this.state.linkTo.pathname ? 
            
            <Redirect to={this.state.linkTo}/>

            :

            <Loading.Container as={Container} fluid={fluid || config.fluidContent} loading={loading}>
                {header &&
                <Header>
                    {currentState.acao} de {header}
                </Header>
                }

                <CSSTransition in={currentState.showForm} classNames="page" timeout={300} unmountOnExit>                 
                    <div>
                        <CrudForm onChange={onFormChange} path={path} currentState={currentState} params={currentState.params} validationSchema={schema} 
                            initialValues={selected} form={form} showSave={showSave && !currentState.hideSave} showReturn={showReturn && !currentState.hideReturn}
                            onSubmit={this.handleSubmit} onSuccess={this.handleSuccess} onError={this.handleError} onReturn={this.return} />
                    </div>
                </CSSTransition>

                <CSSTransition in={!!currentState.custom} classNames="page" timeout={300} unmountOnExit>
                    <div>
                        {currentState.custom && currentState.custom(selected, () => this.return())}
                    </div>
                </CSSTransition>

                <CSSTransition in={!currentState.showForm && !currentState.custom} classNames="page" timeout={300} unmountOnExit>
                    <div>
                        <ButtonBar {...buttonBarProps} />
                        {
                       
                       !isTree ?
                       
                        <DataTable path={path} rows={rows || 50} columns={columns} className="mt-2 mt-lg-0"
                            contextMenu={contextMenu} filter={filter} filterColumns={filterColumns}
                            hideCtx={currentState === states.exclusao} page={page} defaultSort={defaultSort}
                            onSelect={this.setSelected} onPageChange={(page) => this.setState({...this.state, page: page})}
                            onRef={ref => this.dataTable = ref} markFalseRow={markRowByFalseAttr}
                            rowStyles={rowStyles}
                        />
                       
                        :  <Tree path={path} columns={columns} onSelect={this.setSelected} />
                       
                       }
                        
                        { !disableIncluir && <FloatButton variant="primary" className="d-lg-none" icon="plus" onClick={this.showInsert} /> }
                    </div>
                </CSSTransition>

                <ConfirmModal show={showConfirm} header={currentState.acao}
                    onRefuse={() => this.return((currentState === states.exclusao ? states.consulta : currentState))}
                    onConfirm={this.confirm}>
                    {`Tem certeza que deseja ${currentState.do.toLowerCase()} o registro selecionado?`}
                    { currentState === states.exclusao &&
                        <table className="table mt-3">
                            <tbody>
                                {
                                    columns.map((col, i) => (
                                        <tr key={i}>
                                            <th>{col.header}</th>
                                            <td>{ col.format ? col.format(extractValue(selected, col.property)) : extractValue(selected, col.property) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </ConfirmModal>
            </Loading.Container>
        );
    }

    static propTypes = {
        fluid: PropTypes.bool,

        //disables
        disableIncluir: PropTypes.bool,
        disableAlterar: PropTypes.bool,
        disableRetificar: PropTypes.bool,
        disableExcluir: PropTypes.bool,

        //Data table properties
        filter: PropTypes.object,
        path: PropTypes.string.isRequired,
        rows: PropTypes.number,
        columns: PropTypes.array.isRequired,
        filterColumns: PropTypes.array,

        //Crud Form properties
        form: PropTypes.func.isRequired,
        buttonBar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

        incluirShow: PropTypes.func,
        alterarShow: PropTypes.func,
        visualizarShow: PropTypes.func,

    }
}

export default connect(mapStateToProperties)(withAlert()(withRouter(Crud)));
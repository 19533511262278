import React, { Component } from 'react';
import InputMask from 'react-input-mask';

class Input extends Component {
    render () {
        const { className, isInvalid, ...rest } = this.props;
        return (
            <InputMask className={`form-control ${className} ${isInvalid && 'is-invalid'}`} {...rest} />
        );
    }
}

export default Input;
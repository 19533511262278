import React, { Component } from 'react'
import './Dropzone.css'

class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
        <div className="ProgressDiv">
            <div className="ProgressBar">
                <div className="Progress" style={{ width: this.props.progress + '%' }}/>                
            </div>
            <div style={{color: 'Dodgerblue', opacity: this.props.progress/100, marginLeft:'8px'}}>
                <i className="far fa-check-circle fa-sm"></i>
            </div>
        </div>
    )
  }
}

export default Progress
import React from 'react';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Loading from './Loading';

class LoadingContainer extends Component {
    render () {
        const { loading, as : Comp, style, filter, ...rest } = this.props;
        let _style = style || {};
        if(loading) _style.filter = filter || 'blur(2.5px)';

        return (
            <Fragment>
                {this.props.loading && <Loading />}
                <Comp style={_style} {...rest}/>
            </Fragment>
        );
    }

    static propTypes = {
        loading: PropTypes.bool,
        as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
        filter: PropTypes.string,
    }
}

export default LoadingContainer;
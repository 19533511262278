import * as Yup from 'yup';


const schema = Yup.object().shape({
    "nome": Yup.string().required("Informe o nome"),
    "exibeDia": Yup.boolean().default(true),
    "exibeMes": Yup.boolean().default(true),
    "exibeAno": Yup.boolean().default(true),
    "listado": Yup.boolean().default(true),
});

export default schema;
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

export default function SubTitulo({ texto, isHidden }) {
  return (
    <Card hidden={isHidden} className='mt-4 mb-4'>
      <Card.Body className='mt-4'>
        <Row className='mb-4'>
          <Col>
            <h4 className='titles'>{texto}</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';
import { Button, Col, Form, ListGroup } from 'react-bootstrap';
import Crud from '../../../components/Crud';

import Input from '../../../components/Input';

import FormCol from '../../../components/FormCol';
import Switch from '../../../components/Input/Switch';
import config from '../../../config';
import ImportPage from './ImportPage';
import schema from './schema';

const columns = [
  {
    property: 'estruturaOrganizacional.nome',
    header: 'Unidade Orçamentária',
    filter: true,
  },
  { property: 'nome', header: 'Nome', filter: true },
  { property: 'descricao', header: 'Descrição', filter: true },
];

const importState = {
  id: 'import',
  showForm: true,
  acao: 'Importar',
  done: 'Importado',
  do: 'Importar',
  hideSave: true,
  hideReturn: true,
};

const defaultTipoSolicitacao = {
  tipoSolicitacao: {
    id: 1,
    nome: 'Presencial',
    regex: '*.',
    campo: 'Endereço',
  },
};

export default function ServicoPage() {
  return (
    <Fragment>
      <Crud
        schema={schema}
        columns={columns}
        path={`${config.api_path}/v1/servico`}
        header='Serviço'
        buttonBar={({ showState }) => (
          <Button size='sm' onClick={() => showState(importState)}>
            Importar
          </Button>
        )}
        form={({ state, doReturn, values, errors, handleChange }) =>
          state === importState ? (
            <ImportPage doReturn={doReturn} />
          ) : (
            <Fragment>
              <Form.Row>
                <FormCol
                  xs={12}
                  id='estruturaOrganizacional'
                  formLabel='Unidade Orçamentária'
                  component={Input.AsyncSelect}
                  path={`${config.api_path}/v1/estrutura-organizacional`}
                  //eslint-disable-next-line
                  label='${codigo} - ${nome}'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  id='nome'
                  formLabel='Nome'
                  component={Input.Text}
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  id='descricao'
                  formLabel='Descrição'
                  component={'textarea'}
                  className='form-control'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='exigencias'
                  formLabel='Exigências'
                  component={'textarea'}
                  className='form-control'
                  placeholder='Não se aplica'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='pagamentoTaxas'
                  formLabel='Pagamento de taxas'
                  component={'textarea'}
                  className='form-control'
                  placeholder='Não se aplica'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='prazoAtendimento'
                  formLabel='Prazo para atendimento'
                  component={'textarea'}
                  className='form-control'
                  placeholder='Não se aplica'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='formaAcompanhamento'
                  formLabel='Formas de acompanhamento'
                  component={'textarea'}
                  className='form-control'
                  placeholder='Não se aplica'
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='agendamentoPrevio'
                  label='Agendamento Prévio'
                  component={Switch}
                  // alignRight={viewport.xs || viewport.sm}
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />

                <FormCol
                  xs={12}
                  md={6}
                  id='atendimentoDomiciliar'
                  label='Atendimento domiciliar'
                  component={Switch}
                  // alignRight={viewport.xs || viewport.sm}
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
              </Form.Row>

              <div className='mt-2'>
                <FieldArray
                  name='documentos'
                  render={(arrayHelpers) => (
                    <Fragment>
                      {values.documentos && values.documentos.length > 0 ? (
                        <ListGroup>
                          {values.documentos.map((documento, index) => (
                            <ListGroup.Item>
                              <Form.Row>
                                <FormCol
                                  xs={12}
                                  id={`documentos.${index}.nome`}
                                  formLabel='Documento'
                                  component={Input.Text}
                                  values={values}
                                  errors={errors}
                                  onChange={handleChange}
                                />

                                <FormCol
                                  xs={12}
                                  id={`documentos.${index}.observacao`}
                                  formLabel='Descrição'
                                  component={'textarea'}
                                  className='form-control'
                                  values={values}
                                  errors={errors}
                                  onChange={handleChange}
                                />

                                <FormCol
                                  xs={12}
                                  md={6}
                                  id={`documentos.${index}.obrigatorio`}
                                  component={Switch}
                                  label='Obrigatório'
                                  // alignRight={viewport.xs || viewport.sm}
                                  values={values}
                                  errors={errors}
                                  onChange={handleChange}
                                />

                                <Col
                                  xs={12}
                                  md={6}
                                  className='d-flex align-items-center justify-content-end'
                                >
                                  <Button
                                    variant='danger'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <FontAwesomeIcon icon='minus' />
                                  </Button>
                                  <Button
                                    className='ml-2'
                                    onClick={() =>
                                      arrayHelpers.insert(index + 1, {
                                        obrigatorio: true,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon='plus' />
                                  </Button>
                                </Col>
                              </Form.Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <Form.Row>
                          <Col>
                            <Button
                              block
                              onClick={() =>
                                arrayHelpers.push({ obrigatorio: true })
                              }
                            >
                              <FontAwesomeIcon icon='plus' /> Adicionar
                              Documento{' '}
                            </Button>
                          </Col>
                        </Form.Row>
                      )}
                    </Fragment>
                  )}
                />
              </div>

              <div className='mt-2'>
                <FieldArray
                  name='formasSolicitacao'
                  render={(arrayHelpers) => (
                    <Fragment>
                      {values.formasSolicitacao &&
                      values.formasSolicitacao.length > 0 ? (
                        <ListGroup>
                          {values.formasSolicitacao.map(
                            (formaSolicitacao, index) => (
                              <ListGroup.Item>
                                <Form.Row>
                                  <FormCol
                                    xs={12}
                                    md='auto'
                                    id={`formasSolicitacao.${index}.tipoSolicitacao`}
                                    formLabel='Forma de Solicitação'
                                    component={Input.AsyncSelect}
                                    path={`${config.api_path}/v1/tipo-solicitacao`}
                                    //eslint-disable-next-line
                                    label={'${nome}'}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                  />

                                  <FormCol
                                    xs={12}
                                    md='auto'
                                    fill
                                    id={`formasSolicitacao.${index}.valor`}
                                    formLabel={
                                      formaSolicitacao.tipoSolicitacao
                                        ? formaSolicitacao.tipoSolicitacao.campo
                                        : 'Valor'
                                    }
                                    component={Input.Text}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                  />

                                  <Col
                                    xs={12}
                                    md='auto'
                                    className='d-flex align-items-center justify-content-end'
                                  >
                                    <Button
                                      variant='danger'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <FontAwesomeIcon icon='minus' />
                                    </Button>
                                    <Button
                                      className='ml-2'
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, {
                                          ...defaultTipoSolicitacao,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon='plus' />
                                    </Button>
                                  </Col>
                                </Form.Row>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      ) : (
                        <Form.Row>
                          <Col>
                            <Button
                              block
                              onClick={() =>
                                arrayHelpers.push({ ...defaultTipoSolicitacao })
                              }
                            >
                              <FontAwesomeIcon icon='plus' /> Adicionar Forma de
                              Solicitação{' '}
                            </Button>
                          </Col>
                        </Form.Row>
                      )}
                    </Fragment>
                  )}
                />
              </div>
            </Fragment>
          )
        }
      />
    </Fragment>
  );
}

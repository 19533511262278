import React from "react";

export default function MaintenancePage() {

    return (
        <div class="container mt-4">
            <h1 class="display-1 text-center">Em Manutenção...</h1>
            
            <p class="lead text-center">
                O Portal da Transparência está ganhando um novo formato, mais completo e intuitivo. Em breve estará disponível.
            </p>
        </div>
    )
}
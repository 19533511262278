import React from 'react';
import { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';

import ReactCrop from 'react-image-crop';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-image-crop/dist/ReactCrop.css';
import { Image } from 'image-js';
import { EXIF } from 'exif-js';

class Cropper extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            crop: {
                unit: "%",
                aspect: props.free ? undefined : props.aspectRatio
            }
        }

        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.changeImage = this.changeImage.bind(this);
    }

    onCropChange(crop) {
        this.setState({
            ...this.state,
            crop: crop
        });
    };

    onImageLoaded(img) {
        console.log("changed", img);
        this.image = img;
    };

    changeImage(){
        const { uri, onChange, resize } =  this.props;
        const { crop } = this.state;

        const scaleX = (this.image.naturalWidth / this.image.width);
        const scaleY = (this.image.naturalHeight / this.image.height);

        EXIF.getData(this.image, function() {
            let orientation = EXIF.getTag(this, "Orientation");
            // alert(`Orientation: ${orientation}`);

            Image.load(uri).then((img) => {
                switch(orientation) {
                    case 2: img = img.flipX(); break;
                    case 3: img = img.rotate(180); break;
                    case 4: img = img.flipX().rotate(180); break;
                    case 5: img = img.flipX().rotate(270); break;
                    case 6: img = img.rotate(90); break;
                    case 7: img = img.flipX().rotate(90); break;
                    case 8: img = img.rotate(270); break;
                    default: break;
                }

                if(crop.x && crop.y && crop.width && crop.height) {
                    img = img.crop({
                        x: crop.x * scaleX,
                        y: crop.y * scaleY,
                        width: crop.width * scaleX,
                        height: crop.height * scaleY
                    });
                }

                if(resize) {
                    img = img.resize(resize);
                }
                
                img.toBlob(`image/jpeg`, 1).then(blob => {
                    blob.name = "cropped.jpeg";
                    if(onChange) onChange(window.URL.createObjectURL(blob), blob);
                });
            });
        });

    }
    
    handleClose(){        
        if(this.props.onChange){
            this.props.onChange(null, null);           
        }
    }

    render () {
        const { header = "Recorte", circularCrop = false, show = false, uri } = this.props;
        const { crop } = this.state;
        return (
            <Modal show={show} centered onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex justify-content-center div-center">

                        {uri && (
                            <ReactCrop 
                                src={uri} crop={crop} circularCrop={circularCrop}
                                onImageLoaded={this.onImageLoaded}
                                onChange={this.onCropChange}
                            />                        
                        )}
                    </div>

                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        <FontAwesomeIcon icon="arrow-left"/> Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => this.changeImage()}>
                        <FontAwesomeIcon icon="check"/> Confirmar
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default Cropper;
import { SET_SESSION, HANDLE_SESSION_ERROR, SET_SESSION_LOADING, SET_CONTEXT_LOADING, REMOVE_SESSION, UPDATE_CONFIG } from './actionTypes';
import { LOAD_PERSON, SET_PERSON, PERSON_ERROR, REMOVE_PERSON } from './actionTypes';
import { UPDATE_SERVICEWORKER } from './actionTypes';

import config from '../config';
import Axios from 'axios';

//Login Reducer Actions
export const fetchSession = () => {
    return dispatch => {
        dispatch({ type: SET_SESSION_LOADING });

        fetch(`${config.api_path}/session`)
        .then(res => res.json())
        .then(
            json => dispatch({type: SET_SESSION, value: json }), 
            err => dispatch({type: HANDLE_SESSION_ERROR, value: err})
        );
    }
}

export const setContext = (context, callback) => {
    return dispatch => {
        dispatch({type: SET_CONTEXT_LOADING});
        dispatch({type: REMOVE_PERSON});

        fetch(`${config.api_path}/session/context`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                identificador: context.client.identificador
            })
        }).then(res => res.json())
        .then(
            json => { dispatch({type: SET_SESSION, value: json}); if(callback) callback(); },
            err => dispatch({type: HANDLE_SESSION_ERROR, value: err})
        );
    }
}

export const logout = (callback) => {
    return dispatch => {
        dispatch({type: REMOVE_PERSON});
        dispatch({type: REMOVE_SESSION});
        if(callback) callback();
    }
};

//Person Reducer Actions
export const fetchPerson = () => {
    return dispatch => {
        dispatch({type: LOAD_PERSON});

        Axios.get(`${config.api_path}/v1/pessoa-fisica/me`)
        .then(
            res => dispatch({type: SET_PERSON, value: res.data}),
            err => dispatch({type: PERSON_ERROR, value: err})
        );
    }
}

export const removePerson = () => ({
    type: REMOVE_PERSON
});

export const updateConfig = (property, values) => ({
    type: UPDATE_CONFIG,
    property: property,
    value: values
})

export const updateServiceWorker = (value) => ({
    type: UPDATE_SERVICEWORKER,
    value: value
})
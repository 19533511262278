import axios from 'axios';
import config from '../../../../config';

export const Inscricao = (cpf) => {
  const url = `${config.api_path}/public/v1/inscricao/usuario?cpf=${cpf}`;
  return axios.get(url, { cpf });
};

export const RegistrarInscricao = (form) => {
  const url = `${config.api_path}/public/v1/inscricao/registrar`;
  return axios.post(url, form);
};

export const Evento = (form) => {
  const url = `${config.api_path}/public/v1/evento`;
  return axios.get(url, form);
};

export const EnviarCodigo = (cpf, email) => {
  const url = `${config.api_path}/public/v1/codigo-validacao/email?cpf=${cpf}&email=${email}`;
  return axios.post(url);
};

export const ValidarCodigo = (cpf, code) => {
  const url = `${config.api_path}/public/v1/codigo-validacao/validar?cpf=${cpf}&code=${code}`;
  return axios.post(url);
};

export const Comprovante = (file, id, cpf) => {
  const formData = new FormData();
  console.log(file);
  const partes = file.name.split('.');
  const extensao = partes[partes.length - 1];
  formData.append('file', file, `${id}.${extensao}`);
  formData.append('cpf', cpf);
  const url = `${config.api_path}/public/v1/inscricao/comprovante`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

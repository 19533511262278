import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormLabel,
  Row,
} from 'react-bootstrap';
import Input from '../../../components/Input';

import FormInscricao from './Form/form';
import { cpfValidator } from './Form/schema';
import SubTitulo from './Subtitulos';
import Validacao from './Validacao';
import Whatsapp from './Whatsapp/whatsapp';
import './styles.scss';
import { EnviarCodigo, Evento, Inscricao, ValidarCodigo } from './utils';

export default function InscricaoPage() {
  const [userCPF, setUserCPF] = useState('');
  const [password, setPassword] = useState('');
  const [evento, setEvento] = useState('');
  const [userForm, setUserForm] = useState({});
  const [isInscrito, setIsInscrito] = useState(false);
  const [isValidado, setIsValidado] = useState(false);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [render, setRender] = useState(false);

  const handleUsuarioInscrito = async () => {
    let email = '';
    if (!cpfValidator(userCPF)) {
      setInvalidCpf(true);
      return;
    }
    setInvalidCpf(false);
    try {
      await Inscricao(userCPF).then((res) => {
        if (res.data.data.length === 0) {
          setIsInscrito(false);
          setRender(true);
        } else {
          setIsInscrito(true);
          email = res.data.data[0].email;
          setUserForm(res.data.data[0]);
          //
          setIsValidado(true);
          //
        }
      });
      // await EnviarCodigo(userCPF, email);
    } catch (error) {}
  };

  useEffect(() => {
    const getEvento = async () => {
      try {
        const { data } = await Evento();
        setEvento(data.data.list[0]);
      } catch (error) {}
    };
    getEvento();
  }, []);

  const reenviarCodigo = async () => {
    try {
      await EnviarCodigo(userCPF, userForm.email);
    } catch (error) {}
  };

  const validarCodigo = async () => {
    try {
      await ValidarCodigo(userCPF, password);
      setIsValidado(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className='principal h-100'>
      <Whatsapp />
      <Card className='shadow' id='titulo'>
        <Card.Body>
          <Row className='mb-4'>
            <Col>
              <h1 className='titles'>
                {evento.nome} -{' '}
                {evento.dataInicialRealizacao
                  ? format(new Date(evento.dataInicialRealizacao), 'dd/MM/yyyy')
                  : ''}
              </h1>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {render ? (
        // <FormInscricao userCpf={userCPF} />
        <div>
          <h1 style={{ marginTop: '3rem' }}>Inscrições Encerradas</h1>
        </div>
      ) : (
        <>
          <SubTitulo
            texto={
              'Digite o seu CPF e Clique em "Minha Inscrição" para realizaruma nova inscrição ou verificar as informações de uma inscrição já realizada.'
            }
            isHidden={isInscrito || isValidado}
          />

          <SubTitulo
            texto={`Um código de verificação foi enviado para o seu e-mail (${userForm.email}). Digite abaixo e clique em "Confirmar Código" para prosseguir com asua inscrição`}
            isHidden={!isInscrito || isValidado}
          />
          <Form>
            <Row hidden={isValidado}>
              <Col className='col-md-4 col-8'>
                <Form.Group>
                  <FormLabel>CPF</FormLabel>
                  <Form.Control
                    as={Input.Mask}
                    mask={'999.999.999-99'}
                    value={userCPF}
                    onChange={(e) => {
                      setUserCPF(e.target.value);
                      setInvalidCpf(false);
                    }}
                    isInvalid={invalidCpf}
                  />
                </Form.Group>

                {invalidCpf ? (
                  <Alert variant='danger'>O CPF informado não é válido</Alert>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Form>

          <div hidden={!isInscrito || isValidado}>
            <Row className='mb-3'>
              <Col className='col-md-4 col-8'>
                <FormLabel>Código de verificação</FormLabel>
                <Input
                  name='password'
                  type='text'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <FormLabel className='text-muted'></FormLabel>
              </Col>
              <Col md={3} className='d-flex align-items-center'>
                <Button onClick={reenviarCodigo}>Reenviar código</Button>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Button onClick={validarCodigo}>Confirmar código</Button>
              </Col>
            </Row>
          </div>

          <Row className='mb-3' hidden={isInscrito}>
            <Col>
              <Form.Group>
                <Button onClick={handleUsuarioInscrito}>Minha inscrição</Button>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {isInscrito && userForm && isValidado ? (
        <Validacao userCPF={userCPF} />
      ) : (
        ''
      )}
    </Container>
  );
}

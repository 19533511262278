export const buildUrl = (path, params) => {
    let url = path;
    let first = true;
    for(let i in params) {
        if(params[i] === undefined) continue;
        let value = typeof params[i] === 'object' ? JSON.stringify(params[i]) : params[i];
        url += `${(first ? '?' : '&')}${i}=${encodeURI(value)}`; 
        if(first) first = false;
    }
    return url;
}
import React, { Component, Fragment } from 'react';
import { Navbar, Nav, Spinner, Row, Col, Container } from 'react-bootstrap';
import { NavLink, withRouter, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../config';
import Input from '../components/Input';

class NoClientPage extends Component {
    state = {
        selected: null
    }

    render () {
        const { match } = this.props;
        const { cliente, loading, selected } = this.state;

        if(selected){
            console.log(`/${selected.identificador}`)
            return (
                <Redirect to={`/${selected.identificador}`} />
            )
        } 

        return (
            <Fragment>
                <div className="header bg-header">
                    <div className="noise"></div>
                    <Navbar variant="dark" className="header-content w-100">
                        <Navbar.Brand className="d-flex align-items-center">
                            { cliente ?
                                cliente.nome
                                : "Transparência Governamental"
                            }
                            { loading && <Spinner animation="border" size="sm" className="ml-2"/> }
                        </Navbar.Brand>
                        <Nav className="flex-fill justify-content-end">
                            <NavLink to="/gestao" className="nav-link">
                                <FontAwesomeIcon icon="lock"/> Acesso Restrito
                            </NavLink>
                        </Nav>
                    </Navbar>
                </div>

                <Container>
                    { (!match.params || !match.params.cliente) &&
                        <Fragment>
                            <h1 className="font-weight-light text-center mt-4">Opa! Parece que você veio parar aqui por engano...</h1>
                            <h2 className="font-weight-lighter text-center">Selecione o orgão abaixo para ser redirecionado:</h2>

                            <Row>
                                <Col>
                                    <Input.Select path={`${config.api_path}/public/v1/cliente`}
                                        //eslint-disable-next-line
                                        label="${nome}"
                                        onChange={(e) => this.setState({...this.state, selected: e.target.value})}
                                    />
                                </Col>
                            </Row>

                            <small className="text-muted">Se você é um administrador de site, corrija o link do redirecionamento para identificar o orgão correto</small>
                        </Fragment>
                    }
                </Container>
            </Fragment>
        );
    }
}

export default withRouter(NoClientPage);
import React, { Fragment, useState } from 'react';
import { Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  {
    header: 'Estrutura',
    property: 'estruturaOrganizacional.nome',
    filter: true,
  },
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'Cargo', property: 'cargo', filter: true },
  { header: 'Foto', property: 'nomeFoto', filter: true },
];

export default function EstruturaOrganizacionalGestaoPage() {
  const [fileName, setFileName] = useState();
  const handleUpload = (handleChange) => {
    return (file) => {
      handleChange({
        target: {
          id: `nomeFoto`,
          value: file.data.data,
        },
      });
      setFileName(file.data.data);
    };
  };
  return (
    <Crud
      header='Estrutura Organizacional Gestão'
      columns={columns}
      path={`${config.api_path}/v1/estrutura-organizacional-gestao`}
      rows={20}
      schema={schema}
      form={({ state, handleChange, values, touched, isValid, errors }) => (
        <Fragment>
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Estrutura organizacional</FormLabel>
                <Input.AsyncSelect
                  id='estruturaOrganizacional'
                  value={values.estruturaOrganizacional}
                  onChange={handleChange}
                  isInvalid={errors.estruturaOrganizacional}
                  path={`${config.api_path}/v1/estrutura-organizacional`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma estrutura'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.estruturaPai}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleChange}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel>Cargo</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='cargo'
                    value={values.cargo}
                    onChange={handleChange}
                    isInvalid={errors.cargo}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.cargo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md={6}>
              <FormLabel>Foto</FormLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Dropzone
                route={`${config.api_path}/v1/estrutura-organizacional-gestao/upload`}
                onUpload={handleUpload(handleChange)}
              />
            </Col>
          </Row>
          <Row hidden={!fileName}>
            <Col md={6}>
              <FormLabel>Camino: {fileName}</FormLabel>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

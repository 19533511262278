import React from 'react';
import { ListGroup } from "react-bootstrap";

const defaultRenderer = (option) => option.label;

const Radio = ({ horizontal, selected, onSelect, options, render = defaultRenderer }) => {
    return (
        <ListGroup horizontal={horizontal} className={horizontal ? "list-group-horizontal" : ""}>
            {options.map(option => (
                <ListGroup.Item action 
                    onClick={() => onSelect(option)} 
                    active={option === selected}
                    disabled={option.disabled}>
                    {render(option)}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default Radio;
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import { GrupoCategoriaPublicacao } from '../utils/useServices';
import './navbar.scss';

export default function NavbarPortal({ grupoComCategoria }) {
  const [location, setLocation] = useState();
  const { portalCliente, grupoCategoria, grupoCategoriaPublicacao } = useCliente();
  const currentlyLocation = useLocation();
  
  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
  }, [currentlyLocation]);

  const renderCategoria = (nomeGrupo) => {
    return grupoComCategoria[nomeGrupo].map((categoria, index) =>
      categoria.linkExterno ? (
        <NavDropdown.Item href={categoria.linkUrl} target='__blank' key={index}>
          {categoria.nome}
        </NavDropdown.Item>
      ) : (
        <NavDropdown.Item
          as={Link}
          to={`${location}/detail/${grupoCategoria[nomeGrupo]}/${categoria.nomeUrl}`}
          key={index}
        >
          {categoria.nome}
        </NavDropdown.Item>
      )
    );
  };

  return (
    <Navbar
      expand='lg'
      className='navbar-container p-0 m-0'
      style={{
        backgroundColor: portalCliente.navbarBgColor || '',
      }}
    >
      {grupoCategoriaPublicacao && (
        <Container>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav
              className='me-auto navbar-content'
              style={{
                backgroundColor: portalCliente.navbarBgColor || '',
              }}
            >
              <Nav.Link as={Link} to={location} className='self-centered'>
                <FontAwesomeIcon
                  icon={faHome}
                  size='1x'
                  style={{ marginRight: '8px' }}
                />
                Página Inicial
              </Nav.Link>
              {grupoCategoriaPublicacao.map((grupo, index) => {
                if (!grupo.navbarPublicacao) {
                  return (
                    <NavDropdown key={index} title={grupo.nome}>
                      {renderCategoria(grupo.nome)}
                    </NavDropdown>
                  );
                }
                return null;
              })}
              <NavDropdown title='Publicações'>
                {Object.keys(grupoComCategoria).map((nomeGrupo) =>
                  grupoCategoriaPublicacao.map((grupo, index) => {
                    if (grupo.nome === nomeGrupo && grupo.navbarPublicacao) {
                      return (
                        <NavDropdown key={index} title={grupo.nome}>
                          {renderCategoria(nomeGrupo)}
                        </NavDropdown>
                      );
                    }
                    return null;
                  })
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
    </Navbar>
  );
}

import React, { Fragment } from 'react';
import { Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  {
    header: 'Categoria Publicacao',
    property: 'categoriaPublicacao.nome',
    filter: true,
  },
  { header: 'Declaração', property: 'declaracao', filter: true },
  { header: 'Período', property: 'periodo', filter: true },
];

export default function DeclaracaoInexistenciaPage() {
  return (
    <Crud
      header='Declaração inexistência de informação'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/declaracao-inexistencia`}
      rows={20}
      schema={schema}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Categoria Publicação</FormLabel>
                <Input.AsyncSelect
                  id='categoriaPublicacao'
                  value={values.categoriaPublicacao}
                  onChange={handleChange}
                  isInvalid={errors.categoriaPublicacao}
                  path={`${config.api_path}/v1/transparencia/categoria_publicacao`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um portal'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.categoriaPublicacao}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Declaração</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='declaracao'
                    value={values.declaracao}
                    onChange={handleChange}
                    isInvalid={errors.declaracao}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.declaracao}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Período</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='periodo'
                    value={values.periodo}
                    onChange={handleChange}
                    isInvalid={errors.periodo}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.periodo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

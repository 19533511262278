import React, { Component, Fragment } from 'react';
import { Formik, Form } from 'formik';
import { withAlert } from 'react-alert';
import { buildUrl } from '../utils/ajaxUtils';
import Axios from 'axios';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { states } from '.';
import ConfirmModal from '../ConfirmModal';
import { handleAxiosResponse, handleAxiosError } from '../utils/handlers';

class CrudForm extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            selected: null,
            showConfirm: false
        }

        this.confirm = this.confirm.bind(this);
        this.refuse = this.refuse.bind(this);
        
        this.submit = this.submit.bind(this);
        this.return = this.return.bind(this);
    }

    submit(object) {
        this.setState({
            ...this.state,
            selected: object,
            showConfirm: true
        });
    }

    confirm() {
        const { selected } = this.state;
        const { alert, currentState, path, onSubmit, onSuccess, onError } = this.props;
        
        this.setState({
            ...this.state,
            showConfirm: false
        });
        
        if(onSubmit) onSubmit(selected);
        let promise = null;

        switch(currentState.id) {
            case states.inclusao.id:
                promise = Axios.post(buildUrl(path, {}), selected)
                break;
            case states.retificacao.id:
                promise = Axios.put(buildUrl(path, { rectify: true }), selected)
                break;
            case states.alteracao.id:
                promise = Axios.put(buildUrl(path, {}), selected)
                break;
            default:
                let message = `Unsuported state: ${currentState.id}`;
                onError(message)
                throw message;
        }
        
        promise.then(
            handleAxiosResponse(alert),
            handleAxiosError(alert)
        ).then((json, res) => {
            if(onSuccess) onSuccess(json, res);
        }, err => {
            if(onError) onError(err);
        });
    }

    refuse() {
        this.setState({
            ...this.state,
            showConfirm: false
        })
    }

    return() {
        const { onReturn } = this.props;
        if(onReturn) onReturn();
    }

    render () {
        const { showConfirm } = this.state;
        const { validationSchema, confirmMessage, initialValues, form, currentState, showReturn = true, showSave = true, onChange } = this.props;

        return (
            <Fragment>
                <Formik validationSchema={validationSchema} onSubmit={this.submit} initialValues={initialValues} >
                    { ({ handleSubmit, handleChange, ...rest}) => (
                        <Form noValidate={true} onSubmit={handleSubmit}>
                            <fieldset disabled={currentState === states.visualizar ? true : false}>
                                { form({ state: currentState, doReturn: this.return, handleChange: (e) => { handleChange(e); if(onChange) onChange(e) }, ...rest}) }
                            </fieldset>
                            <div className="d-flex w-100 justify-content-center my-2">
                                { showReturn && <Button variant="secondary" className="mr-1" onClick={this.return} ><FontAwesomeIcon icon="reply"/> Voltar</Button> }
                                { (currentState !== states.visualizar) && showSave && <Button variant="primary" className="ml-1" type="submit"><FontAwesomeIcon icon="save"/> Salvar</Button> }
                            </div>
                        </Form>
                    )}
                </Formik>
                <ConfirmModal show={showConfirm} header={currentState.acao}
                    onRefuse={this.refuse} onConfirm={this.confirm} >
                    {confirmMessage ? confirmMessage
                        :`Tem certeza que deseja ${currentState.do.toLowerCase()} o registro selecionado?`
                    }
                </ConfirmModal>
            </Fragment>
        );
    }
}

export default withAlert()(CrudForm);
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ButtonGroup, Card, Col, Collapse, Container, Form, FormGroup, FormLabel, Row, Table } from 'react-bootstrap';
import Input from '../../../components/Input';
import Service from '../../../components/Systema/Service';
import config from '../../../config';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';

const service = new Service(`${config.api_path}/public/v1/transparencia/convenio`);
const sort = { dataInicioExecucao: 'DESC' };

export default function ConvenioPage() {
  const [page, setPage] = useState(1);
  const [filtroRequest, setFiltroRequest] = useState({});
  const [filtroNumeroConvenio, setFiltroNumeroConvenio] = useState('');
  const [filtroNomeOrgao, setFiltroNomeOrgao] = useState('');
  const [filtroSituacao, setFiltroSituacao] = useState('');
  const [filtroPrograma, setFiltroPrograma] = useState('');
  const anoAtual = new Date().getFullYear();
  const [filtroDataInicial, setFiltroDataInicial] = useState(new Date(anoAtual - 6, 0, 1));
  const [filtroDataFinal, setFiltroDataFinal] = useState(new Date());
  const [filterTable, setFilterTable] = useState({});
  const [ultimoConvenio, setUltimoConvenio] = useState();
  const [convenioDataSincronizacao, setConvenioDataSincronizacao] = useState();
  const [showFiltro, setShowFiltro] = useState();

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  useEffect(() => {
    if (
      filtroNumeroConvenio ||
      filtroNomeOrgao ||
      filtroPrograma ||
      filtroSituacao ||
      id ||
      filtroDataInicial ||
      filtroDataFinal
    ) {
      if (Object.keys(filtroRequest).length === 0) {
        setFiltroRequest({
          'clienteGlobal.identificador': id ? { $eq: id } : undefined,
        });
      }
      setFilterTable({
        numeroConvenio: filtroNumeroConvenio ? { $ilike: `${filtroNumeroConvenio}` } : undefined,
        nomeOrgao: filtroNomeOrgao ? { $ilike: `${filtroNomeOrgao}` } : undefined,
        situacao: filtroSituacao ? { $ilike: `${filtroSituacao}` } : undefined,
        programa: filtroPrograma ? { $ilike: `${filtroPrograma}` } : undefined,
        dataInicioExecucao: filtroDataInicial ? { $ge: `date:${filtroDataInicial.getTime()}` } : undefined,
        datafimExecucao: filtroDataFinal ? { $le: `date:${filtroDataFinal.getTime()}` } : undefined,
        'clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
    } else {
      setFilterTable({});
    }
  }, [
    filtroNumeroConvenio,
    filtroNomeOrgao,
    filtroPrograma,
    filtroSituacao,
    filtroDataInicial,
    filtroDataFinal,
    id,
    filtroRequest,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: convenioSync } = await Axios.get(
        `${config.api_path}/public/v1/transparencia/municipio-convenio/data-sincronizacao/${id}`
      );
      const { data: dataUltimoContrato } = await Axios.get(
        `${config.api_path}/public/v1/transparencia/convenio/data-registro/${id}`
      );
      setConvenioDataSincronizacao(convenioSync.data);
      setUltimoConvenio(dataUltimoContrato.data);
    };
    fetchData();
  }, [id]);

  return ultimoConvenio && setConvenioDataSincronizacao ? (
    <Container fluid>
      <Card className='mt-4 mb-2'>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='font-weight-light my-0'>Convênio</h3>
            </div>
            <span
              className='align-items-center'
              style={{ display: 'grid', gridTemplate: 'auto / auto auto', gap: '.4rem' }}
            >
              <small className='font-weight-bold'>Data da última Sincronização:</small>
              <small className='text-right'>
                {new Date(convenioDataSincronizacao).toLocaleDateString('pt-BR', opcoes)}
              </small>

              <small className='font-weight-bold'>Data do último Convênio:</small>
              <small className='text-right'>{new Date(ultimoConvenio).toLocaleDateString('pt-BR', opcoes)}</small>
            </span>
          </div>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Header className='py-2' onClick={() => setShowFiltro(!showFiltro)} style={{ cursor: 'pointer' }}>
          <div className='d-flex justify-content-between'>
            <div>
              <Card.Title className='my-0'>Filtros</Card.Title>
            </div>
            <div>
              <FontAwesomeIcon icon={showFiltro ? faMinus : faPlus} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={showFiltro}>
          <div>
            <Card.Body>
              <Row className='mb-3'>
                <Col md={4}>
                  <FormLabel>Data Inicial</FormLabel>
                  <Input.Date
                    value={filtroDataInicial}
                    type='date'
                    onChange={(e) => setFiltroDataInicial(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Data Final</FormLabel>
                  <Input.Date
                    value={filtroDataFinal}
                    type='date'
                    onChange={(e) => setFiltroDataFinal(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  <FormLabel>Numero do convênio</FormLabel>
                  <Form.Control
                    value={filtroNumeroConvenio}
                    onChange={(e) => setFiltroNumeroConvenio(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Nome do orgão</FormLabel>
                  <Form.Control value={filtroNomeOrgao} onChange={(e) => setFiltroNomeOrgao(e.target.value)} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={4}>
                  <FormLabel>Situação</FormLabel>
                  <Form.Control value={filtroSituacao} onChange={(e) => setFiltroSituacao(e.target.value)} />
                </Col>
                <Col md={4}>
                  <FormLabel>Programa</FormLabel>
                  <Form.Control value={filtroPrograma} onChange={(e) => setFiltroPrograma(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant='outline-primary' onClick={() => setFiltroRequest(filterTable)}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <main>
        <DataPagination sort={sort} service={service} page={page} limit={14} filter={filtroRequest}>
          {({ data, page, lastPage, loading }) => (
            <Fragment>
              <Table className='table-bordered table-hover table-striped'>
                <thead>
                  <tr>
                    <th>Mais</th>
                    <th>Número do Convênio</th>
                    <th>Nome do órgão</th>
                    <th>Situação</th>
                    <th>Programa</th>
                    <th>Data do inicio da execução</th>
                    <th>Data final da execução</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) =>
                    row.clienteGlobal.identificador === id ? (
                      <tr>
                        <td className='text-nowrap' align='center'>
                          <Button variant='outline-primary'>
                            <a
                              href={row.detalhesLink + '&Usr=guest&Pwd=guest'}
                              target='_blank'
                              style={{
                                textDecoration: 'none',
                                color: 'black',
                                hover: { textDecoration: 'none' },
                              }}
                            >
                              Detalhes
                            </a>
                          </Button>
                        </td>
                        <td>{row.numeroConvenio}</td>
                        <td>{row.nomeOrgao}</td>
                        <td>{row.situacao}</td>
                        <td>{row.programa}</td>
                        <td>{new Date(row.dataInicioExecucao).toLocaleDateString('pt-BR', opcoes)}</td>
                        <td>{new Date(row.datafimExecucao).toLocaleDateString('pt-BR', opcoes)}</td>
                      </tr>
                    ) : (
                      ''
                    )
                  )}
                </tbody>
              </Table>

              <Paginator page={page} lastPage={lastPage} setPage={setPage} loading={loading} />
            </Fragment>
          )}
        </DataPagination>
      </main>
    </Container>
  ) : (
    <Loading />
  );
}

import React from 'react';
import { Component } from 'react';

import { InputGroup, FormControl, DropdownButton, Dropdown, Form } from 'react-bootstrap';

import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBR);


class DataTableFilter extends Component {
    constructor(props, context) {
        super(props, context);

        let compareTypes = [
            {name: "Contém", type: "%%"},
            {name: "Inicio", type: "_%"},
            {name: "Fim", type: "%_"},
            {name: "Não Contém", type: "!%%"},
        ];

        if(['integer', 'float', 'long', 'double'].indexOf(props.filter.type) >= 0) {
            compareTypes = [
                {name: "Igual", type: "="},
                {name: "Maior", type: ">"},
                {name: "Maior ou Igual", type: ">="},
                {name: "Menor", type: "<"},
                {name: "Menor ou Igual", type: "<="},
            ];
        } else if (props.filter.type === 'boolean') {
            compareTypes = [
                { name: "Igual", type: "=" }
            ];
        }

        this.state = {
            compareTypes,
            compareType: compareTypes[0],

            from: null,
            to: null,
            value: ''
        }

        this.clean = this.clean.bind(this);
    }

    componentDidMount() {
        if(this.props.onRef) this.props.onRef(this);
    }

    clean() {
        this.setState({
            ...this.state,
            compareType: this.state.compareTypes[0],
            from: null,
            to: null,
            value: '',
        }, () => {
            if(this.props.onChange)
                this.props.onChange(this.props.property, this.state.compareType, false);
        });
    }

    setCompareType(compareType) {
        this.setState({
            ...this.state,
            compareType: compareType
        }, () => this.handleChange('value', this.state.value));

    }

    handleChange(field, value) {
        var nState = {...this.state};
        nState[field] = value;
    
        this.setState(nState, () => {
            if(this.props.onChange){
                let { filter, property } = this.props;
                let compareType = this.state.compareType.type;

                //Handle date comparation
                if(field === 'from'){
                    compareType = '>=';
                    property = "1:" + property;
                    if(value){
                        value = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
                    }
                } else if(field === 'to'){
                    compareType = '<=';
                    property = "2:" + property;
                    if(value){
                        value = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59)    
                    }
                    
                }

                if(value) {
                    if(filter.type === 'date') value = value.getTime(); //Convert date value 
                    value = (filter.type || 'string') + ":" + value;
                }

                this.props.onChange(property, compareType, value)
            }
        });
    }

    render() {
        const { filter } = this.props;
        const { compareTypes, compareType, from, to, value } = this.state;
        const type = filter.type || 'string';

        if(type === 'date') {
            return (
                <div className="d-flex datatable-date-filter">
                    <DatePicker selectsStart selected={from} endDate={to} maxDate={to}
                        onChange={(date) => this.handleChange('from', date)}
                        dateFormat="dd/MM/yyyy" locale="pt-BR"
                        placeholderText="De" className="form-control form-control-sm"/>
                    <DatePicker selectsEnd selected={to} startDate={from} minDate={from}
                        dateFormat="dd/MM/yyyy" locale="pt-BR"
                        onChange={(date) => this.handleChange('to', date)}
                        placeholderText="Até" className="form-control form-control-sm"/>
                </div>
            )
        }

        if (type === "boolean") {
            return (
                <Form.Control size="sm" as="select" onChange={({target}) => this.handleChange('value', target.value)} >
                    <option value="any">TODOS</option>
                    <option value="true">{filter.true || "SIM"}</option>
                    <option value="false">{filter.false || "NÃO"}</option>
                </Form.Control>
            )
        }


        return (
            <InputGroup size="sm">
                <DropdownButton as={InputGroup.Prepend} variant="outline-secondary" title={compareType.name}>
                    {
                        compareTypes.map((compareType, i) => (
                            <Dropdown.Item key={i} onClick={() => this.setCompareType(compareType)}>{compareType.name}</Dropdown.Item>
                        ))
                    }
                </DropdownButton>
                <FormControl placeholder={filter.placeholder || "Filtro"} value={value} onChange={({target}) => this.handleChange('value', target.value)}/>
            </InputGroup>
        )
    }
}

export default DataTableFilter;
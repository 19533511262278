import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useRef, useState } from 'react';
import { Button, Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

import { Editor } from '@tinymce/tinymce-react';
import Switch from '../../../components/Input/Switch';

const columns = [
  {
    header: 'Portal',
    property: 'modeloGrupoCategoriaPublicacao.modeloPortal.nome',
    filter: true,
  },
  {
    header: 'Grupo',
    property: 'modeloGrupoCategoriaPublicacao.nome',
    filter: true,
  },
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'Nome da URL', property: 'nomeUrl', filter: true },
  { header: 'Ordem exibição', property: 'ordemExibicao', filter: true },
];

export default function Modelo_Categoria_PublicacaoPage() {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [idGrupo, setIdGrupo] = useState({});
  const [view, setView] = useState(true);
  const [grupoNome, setGrupoNome] = useState();
  const [modeloSelecionado, setModeloSelecionado] = useState();

  function handleKeyPress(event) {
    const pattern = /^[a-z]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  function changeUrl(handleChange) {
    return (e) => {
      let nomeUrl = '';
      let linkUrl = '';
      let linkExterno = e.target.checked;
      handleChange({
        target: {
          id: 'linkExterno',
          value: linkExterno,
        },
      });

      if (linkExterno) {
        handleChange({
          target: {
            id: 'nomeUrl',
            value: nomeUrl,
          },
        });
      }

      if (!linkExterno) {
        handleChange({
          target: {
            id: 'linkUrl',
            value: linkUrl,
          },
        });
      }
    };
  }

  async function descer() {
    setLoading(true);
    await Axios.post('/v1/transparencia/modelo/categoria_publicacao/trocar-ordem?direcao=descer', selected);
    setLoading(false);
  }
  async function subir() {
    setLoading(true);
    await Axios.post('/v1/transparencia/modelo/categoria_publicacao/trocar-ordem?direcao=subir', selected);
    setLoading(false);
  }

  const handleNomeChange = (handleChange, linkExterno) => {
    return (e) => {
      handleChange({
        target: {
          id: 'nome',
          value: e.target.value,
        },
      });
      if (!linkExterno) {
        const nome = e.target.value;
        const nomeUrl = nome
          .normalize('NFD') // Normaliza os caracteres Unicode com decomposição
          .replace(/[\u0300-\u036f]/g, '') // Remove os caracteres Unicode que não são acentos
          .replace(/[ç]/g, 'c') // Substitui "ç" por "c"
          .replace(/[^\w\s]/gi, ' ') // Remove caracteres especiais
          .trim() // Remove espaços no começo e fim
          .toLowerCase() // Converte para minúsculas
          .replace(/\b(\d+)\b/g, ' $1 ') // Adiciona espaços em números soltos
          .replace(/[-_ ]+/g, '-') // Substitui espaços, underscores e hífens por traços
          .replace(/-{2,}/g, '-'); // Remove traços consecutivos

        handleChange({
          target: {
            id: 'nomeUrl',
            value: nomeUrl,
          },
        });
      }
    };
  };

  function changeId() {
    return (e) => {
      if (e.target.value) {
        setModeloSelecionado(e.target.value);
        setGrupoNome('');
        setIdGrupo({
          id: e.target.value.id,
        });
        setView(false);
      } else {
        setGrupoNome('');
        setIdGrupo({});
        setView(true);
      }
    };
  }

  function changeName(handleChange) {
    return (e) => {
      if (e.target.value) {
        handleChange({
          target: {
            id: 'modeloGrupoCategoriaPublicacao',
            value: e.target.value,
          },
        });
        setGrupoNome(e.target.value);
      }
    };
  }

  const handleDataChange = (handleChange, id, value) => {
    return handleChange({
      target: {
        id: id,
        value: value,
      },
    });
  };

  const handleExibechange = (handleChange) => {
    return (e) => {
      const exibeDia = 'exibeDia';
      const exibeMes = 'exibeMes';
      const exibeAno = 'exibeAno';
      const campo = e.target.id;
      let valor = e.target.checked;
      if (campo === exibeDia) {
        if (valor === true) {
          handleDataChange(handleChange, exibeDia, valor);
          handleDataChange(handleChange, exibeMes, valor);
          handleDataChange(handleChange, exibeAno, valor);
        } else {
          handleDataChange(handleChange, exibeDia, valor);
        }
      } else if (campo === exibeMes) {
        if (valor === true) {
          handleDataChange(handleChange, exibeMes, valor);
          handleDataChange(handleChange, exibeAno, valor);
        } else {
          handleDataChange(handleChange, exibeMes, valor);
          handleDataChange(handleChange, exibeDia, valor);
        }
      } else if (campo === exibeAno) {
        if (valor === true) {
          handleDataChange(handleChange, exibeAno, valor);
        } else {
          handleDataChange(handleChange, exibeAno, valor);
          handleDataChange(handleChange, exibeMes, valor);
          handleDataChange(handleChange, exibeDia, valor);
        }
      }
    };
  };

  let filter = { 'modeloPortal.id': { $eq: `long:${idGrupo.id}` } };

  return (
    <Crud
      contextMenu={[
        {
          buttons: [
            { icon: 'arrow-up', name: 'Subir', onClick: subir },
            { icon: 'arrow-down', name: 'Descer', onClick: descer },
          ],
        },
      ]}
      onSelect={setSelected}
      header='Modelo da Categoria Publicação'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/modelo/categoria_publicacao`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <Row>
            <Button onClick={() => subir(selected)}>
              <FontAwesomeIcon icon={faArrowUp} />
              Subir
            </Button>
            <Col>
              <Button onClick={() => descer(selected)}>
                <FontAwesomeIcon icon={faArrowDown} />
                Descer
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col>
              <Form.Group>
                <FormLabel>Modelo do Portal</FormLabel>
                <Input.AsyncSelect
                  value={modeloSelecionado}
                  onChange={changeId()}
                  isInvalid={errors.teste}
                  path={`${config.api_path}/v1/transparencia/modelo/portal`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um portal'
                />
                <Form.Control.Feedback type='invalid'>{errors.teste}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Grupo</FormLabel>
                <Input.AsyncSelect
                  id='modeloGrupoCategoriaPublicacao'
                  value={grupoNome}
                  onChange={changeName(handleChange)}
                  isInvalid={errors.modeloGrupoCategoriaPublicacao}
                  path={`${config.api_path}/v1/transparencia/modelo/grupo_categoria_publicacao`}
                  label={'${nome}'}
                  dataFilter={filter}
                  disabled={view}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um grupo'
                />
                <Form.Control.Feedback type='invalid'>{errors.modeloGrupoCategoriaPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleNomeChange(handleChange, values.linkExterno)}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.nome}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col md={2}>
              <Switch
                id='exibeDia'
                label={'Exibe dia'}
                value={values.exibeDia}
                onChange={handleExibechange(handleChange)}
              />
            </Col>

            <Col md={2}>
              <Switch
                id='exibeMes'
                label={'Exibe mês'}
                value={values.exibeMes}
                onChange={handleExibechange(handleChange)}
              />
            </Col>

            <Col md={2}>
              <Switch
                id='exibeAno'
                label={'Exibe ano'}
                value={values.exibeAno}
                onChange={handleExibechange(handleChange)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Switch
                id='linkExterno'
                label={'Link externo'}
                value={values.linkExterno}
                onChange={changeUrl(handleChange)}
              />
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Nome da Url</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nomeUrl'
                    value={values.nomeUrl}
                    onChange={handleChange}
                    disabled={values.linkExterno}
                    isInvalid={errors.nomeUrl}
                    onKeyPress={handleKeyPress}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.nomeUrl}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <FormLabel>Link da URL</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='linkUrl'
                    value={values.linkUrl}
                    onChange={handleChange}
                    disabled={!values.linkExterno}
                    isInvalid={errors.linkUrl}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.linkUrl}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col md={2}>
              <Switch id='agruparAno' label={'Agrupado por ano'} value={values.agruparAno} onChange={handleChange} />
            </Col>
            <Col md={2}>
              <Switch
                id='agruparEntidade'
                label={'Agrupado por entidade'}
                value={values.agruparEntidade}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Editor
            apiKey='g23uxmawmmddie2rzdpv01jdu0mp43tmtd1g63nf1ok2ec1h'
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=''
            onEditorChange={(html) => handleChange({ target: { id: 'conteudoHtml', value: html } })}
            init={{
              height: 400,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

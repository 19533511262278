import React from 'react';
import { Component } from 'react';
import { Card, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ButtonBar extends Component {
    render () {
        const { rectifiable, disableIncluir, disableAlterar, disableExcluir, disableVisualizar } = this.props;
        const { showInsert, showUpdate, showRectify, showDelete, showVisualize } = this.props;
        const { buttonBar, size = "sm" } = this.props;
        const { selected, showState } = this.props;

        return (
            <div className="d-none d-lg-block py-2">
                <Card className="bg-light">
                    <Card.Body className="p-2 d-flex align-items-center">
                        {!disableIncluir && <Button size={size} variant="primary" onClick={showInsert} ><FontAwesomeIcon icon="plus"/>  Incluir</Button> }
                        { rectifiable ?
                            disableAlterar ? 
                            <Button size={size} variant="warning" onClick={showRectify} className="ml-1 text-white"><FontAwesomeIcon icon="eraser"/> Retificar</Button>
                            :
                            <Dropdown as={ButtonGroup}>
                                <Button size={size} variant="warning" onClick={showUpdate} className="ml-1 text-white"><FontAwesomeIcon icon="edit"/> Alterar</Button> 
                                <Dropdown.Toggle size={size} split variant="warning" className="text-white" />

                                <Dropdown.Menu alignRight>
                                    <Dropdown.Item onClick={showUpdate}><FontAwesomeIcon icon="edit"/> Alterar</Dropdown.Item>
                                    <Dropdown.Item onClick={showRectify}><FontAwesomeIcon icon="eraser"/> Retificar</Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown>
                            : !disableAlterar && 
                            <Button size={size} variant="warning" onClick={showUpdate} className="ml-1 text-white"><FontAwesomeIcon icon="edit"/> Alterar</Button>
                        }
                        {!disableExcluir && <Button size={size} variant="danger"  onClick={showDelete} className="ml-1"><FontAwesomeIcon icon="trash"/> Excluir</Button>}
                        {!disableVisualizar && <Button size={size} variant="outline-dark" onClick={showVisualize} className="ml-1"><FontAwesomeIcon icon="book-open"/> Visualizar</Button> }
                        { buttonBar && 
                            <div className="ml-2 pl-2 border-left"> 
                                {
                                    (typeof buttonBar) == "function" ?
                                        buttonBar({ selected: selected, showState: showState })
                                        :
                                        buttonBar
                                
                                } 
                            </div>
                        }
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default ButtonBar;
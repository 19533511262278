import React from 'react';
import { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Media, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import { fetchPerson } from '../actions'
import AlertList from './AlertList';
import AsyncOutput from './AsyncOutput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from './Avatar';

import config from '../config';
import Axios from 'axios';

const mapStateToProperties = store => ({
    person: store.personState.person,
    loading: store.personState.loading,
    loaded: store.personState.loaded,
    errors: store.personState.errors
});

const mapDispatchToProperties = dispatch => bindActionCreators({ fetchPerson }, dispatch);

class PersonCard extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            hidePerfil: false
        }
    }    

    getHidePerfilParam(){
        Axios.get(`${config.api_path}/v1/parametros/get-value`, {
            params:{
                param: 'hide_perfil_page',
            }
        })
        .then(res=> {
            console.log(res)
            if(!res.data.error){
                this.setState({
                    ...this.state,
                    hidePerfil: res.data.data
                });
            }
        })
    }

    componentDidMount() {
        const { loaded, fetchPerson } = this.props;
        this.getHidePerfilParam();
        if (!loaded) fetchPerson();     
    }

    render() {
        const { person, loading, loaded, errors, fetchPerson, ...rest } = this.props;
        const noPerson = errors ? errors.reduce((r, i) => r = (r && i.code === 3021), true) : false;

        return (
            <Card {...rest}>
                {(person || loading) ?
                    <Media as={Card.Body}>
                        <Avatar uri={`${config.api_path}/v1/profile/picture`} icon="user-alt" 
                            editable={false} width={80} height={80} />
                        <Media.Body className="p-2">
                            <AsyncOutput variant={Card.Title} loading={loading} className="mb-1">
                                {person && (person.pessoaFisicaDados.nomeSocial || person.pessoaFisicaDados.nome)}
                            </AsyncOutput>
                            <AsyncOutput variant="small" loading={loading} style={{ display: "block" }}>
                                {person && person.cpf}
                            </AsyncOutput>

                            {this.state.hidePerfil ? null :
                            <Link to="/user">
                                Acessar Perfil
                            </Link>
                            }
                            <div>
                                <Link to ="/relatorio/individual">
                                    Acessar Relatórios Individuais
                                </Link>
                            </div>
                        </Media.Body>
                    </Media>
                    :
                    <Card.Body>
                        {(noPerson) ?
                            null
                            :
                            <Fragment>
                                <AlertList alerts={errors}></AlertList>
                                <Button onClick={fetchPerson} block disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /> Tentar Novamente</Button>
                            </Fragment>
                        }
                    </Card.Body>
                }
            </Card>
        );
    }
}

export default connect(mapStateToProperties, mapDispatchToProperties)(PersonCard);
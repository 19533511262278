import React from 'react';
import { ListGroup } from "react-bootstrap";

const defaultRenderer = (option) => option.label;

const SelectMany = ({ horizontal, selected, beforeSelect, onSelect, options, render = defaultRenderer }) => {

    function doSelect(option) {
        let index = selected.indexOf(option);
        if(index >= 0) {
            let target = [...selected];
            target.splice(index, 1);

            if(beforeSelect) target = beforeSelect(target, option, true);
            onSelect(target);
        } else {
            let target = [ ...selected, option ];
            if(beforeSelect) target = beforeSelect(target, option, false);

            onSelect(target);
        }
    }

    return (
        <ListGroup horizontal={horizontal} className={horizontal ? "list-group-horizontal" : ""}>
            {options.map(option => (
                <ListGroup.Item action 
                    onClick={() => doSelect(option)} 
                    active={selected.indexOf(option) >= 0}
                    disabled={option.disabled}>
                    {render(option)}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default SelectMany;
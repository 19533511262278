import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Container, Form, FormLabel, Modal, Row } from 'react-bootstrap';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Input from '../../../../components/Input';
import Loading from '../../../../components/Loading';
import config from '../../../../config';
import NavigationBreadcrumb from '../../../../navigation/NavigationBreadcrumb';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import Faq from '../../FaqPage/faq';
import BarraAcessibilidade from '../BarraAcessibilidade/barraAcessibilidade';
import DeclaracaoInexistenciaAlerta from '../DeclaracaoInexistencia/declaracaoInexistencia';
import Footer from '../Footer/footer';
import HeaderPortal from '../Header/header';
import './publicacoes.scss';

export function ComponenteHTML({ htmlString }) {
  const createMarkup = () => {
    return { __html: htmlString };
  };

  return <div dangerouslySetInnerHTML={createMarkup()} />;
}

export default function PortalTransparenciaPublicacoesPage() {
  const {
    grupoComCategoria,
    identificador,
    nomeUrl,
    todasCategorias,
    publicacoes,
    publicacaoArquivo,
    categoriaTipo,
    declaracoes,
    portal,
    portalCliente,
  } = useCliente();
  const { categoriaNomeUrl, grupoNomeUrl } = useParams();
  const currentlyLocation = useLocation();
  const [categoria, setCategoria] = useState();
  const [tiposCategoria, setTiposCategoria] = useState([]);
  const [tipoSelected, setTipoSelected] = useState();
  const [hideArquivo, setHideArquivo] = useState(true);
  const [isFaq, setIsFaq] = useState(false);
  const [isPub, setIsPub] = useState(false);
  const [hideVideo, setHideVideo] = useState(true);
  const [hideCategoria, setHideCategoria] = useState(true);
  const [codeYt, setCodeYt] = useState();
  const [arquivoPublicacaoList, setArquivoPublicacaoList] = useState({});
  const [publicacaoFilter, setPublicacaoFilter] = useState({});
  const [listaArquivos, setListaArquivos] = useState([]);
  const [subcategoria, setSubcategoria] = useState();
  const [eachDataFilter, setEachDataFilter] = useState([]);
  const [eachData, setEachData] = useState([]);
  const [open, setOpen] = useState(false);
  const [eachPublicacao, setEachPublicacao] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [entidadeAtual, setEntidadeAtual] = useState();
  const [declaracaoInformacao, setDeclaracaoInformacao] = useState(null);
  const [location, setLocation] = useState();
  const [dataInicialFilter, setDataInicialFilter] = useState(new Date('2018-01-01T03:00:00'));
  const [dataFinalFilter, setDataFinalFilter] = useState(new Date());
  const [nomePublicacaoFilter, setNomePublicacaoFilter] = useState('');
  const [currentlyYear, setCurrentlyYear] = useState();
  const [atualizadoEm, setAtualizadoEm] = useState();
  const [currentTipo, setCurrentTipo] = useState(null);
  const [show, setShow] = useState(false);

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let indexEntidade = 0;

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (currentlyLocation.pathname.includes('perguntas-e-respostas-frequentes')) {
      setIsFaq(true);
      setIsPub(false);
    } else {
      setIsPub(true);
      setIsFaq(false);
    }
  }, [currentlyLocation]);

  useEffect(() => {
    const renderizar = () => {
      if (todasCategorias && publicacoes && publicacaoArquivo && declaracoes && categoriaTipo) {
        const tipos = [];
        const tipoCategoria = [];
        const subcateg = [];
        const newData = new Set(eachDataFilter);
        const arquivosPub = {};
        todasCategorias.forEach(async (categoria) => {
          if (categoriaNomeUrl === categoria.nomeUrl && categoria.grupoCategoriaPublicacao.nomeUrl === grupoNomeUrl) {
            categoriaTipo.forEach((tipo) => {
              if (tipo.categoriaPublicacao.id === categoria.id) {
                tipoCategoria.push(tipo);
              }
            });
            setTiposCategoria(tipoCategoria);
            setCategoria(categoria);
            declaracoes.forEach((declaracao) => {
              if (declaracao.categoriaPublicacao && declaracao.categoriaPublicacao.id === categoria.id) {
                setDeclaracaoInformacao(declaracao);
              }
            });
            publicacoes.forEach((publicacao) => {
              if (publicacao.categoriaPublicacao.nomeUrl === categoriaNomeUrl) {
                if (!tipos.includes(publicacao.tipoPublicacao.nome)) {
                  tipos.push(publicacao.tipoPublicacao.nome);
                }
                if (publicacao.tipoPublicacao.id === 3) {
                  if (publicacao.urlPublicacao.includes('youtu.be')) {
                    const partes = publicacao.urlPublicacao.split('/');
                    setCodeYt(partes.pop());
                  } else if (publicacao.urlPublicacao.includes('www.youtube.com')) {
                    const urlParams = new URLSearchParams(publicacao.urlPublicacao.split('?')[1]);
                    const videoCode = urlParams.get('v');
                    setCodeYt(videoCode);
                  }
                  setHideVideo(false);
                }
                if (publicacao.tipoPublicacao.id === 1) {
                  publicacaoArquivo &&
                    publicacaoArquivo.forEach((arquivos) => {
                      if (arquivos.publicacao.id === publicacao.id) {
                        if (!arquivosPub.hasOwnProperty(publicacao.id)) {
                          arquivosPub[publicacao.id] = [];
                        }
                        arquivosPub[publicacao.id].push(arquivos);
                      }
                    });
                  setHideArquivo(false);
                }
                if (publicacao.tipoPublicacao.id === 4) {
                  subcateg.push(publicacao.subcategoriaPublicacao.nome);
                  setHideCategoria(false);
                }
                const year = publicacao.data.slice(0, 4);
                if (!newData.has(year)) newData.add(year);
                setEachDataFilter((prev) => {
                  const year = publicacao.data.slice(0, 4);
                  const newSet = new Set(prev);
                  if (!newSet.has(year)) newSet.add(year);
                  return [...newSet];
                });
                setEachPublicacao((prev) => {
                  return [...prev, publicacao];
                });
              }
            });
          }
        });
        const arrayData = Array.from(newData);
        const sortedData = arrayData.sort((a, b) => b - a);
        setDataInicialFilter(new Date(sortedData[sortedData.length - 1], 0, 1));
        setCurrentlyYear(sortedData[0]);
        setEachDataFilter(arrayData);
        setEachData(arrayData);
        const dataMaisRecente = Object.keys(arquivosPub).length !== 0  ? Object.values(arquivosPub).sort(
          (a, b) => new Date(b[0].publicacao.dataHoraRegistro) - new Date(a[0].publicacao.dataHoraRegistro)
        )[0][0].publicacao.dataHoraRegistro : '';
        setAtualizadoEm(new Date(dataMaisRecente).toLocaleDateString('pt-BR', opcoes));
        setArquivoPublicacaoList(arquivosPub);
        setPublicacaoFilter(arquivosPub);
        setSubcategoria(subcateg);
      }
    };
    renderizar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todasCategorias, publicacoes, publicacaoArquivo, grupoNomeUrl, categoriaTipo, categoriaNomeUrl, declaracoes]);

  useEffect(() => {
    const entidades = [];

    for (let dados of eachPublicacao) {
      const data = dados.data.slice(0, 4);
      const nome = dados.entidade ? dados.entidade.nome : '';
      const ordem = dados.entidade ? dados.entidade.ordemExibicao : '';
      if (data === currentlyYear && dados.entidade) {
        entidades.push({ nome, ordem });
      } else if (!currentlyYear) {
        entidades.push({ nome, ordem });
      }
    }
    const nomesEntidades = [...new Map(entidades.map((entidade) => [entidade.ordem, entidade])).values()].sort(
      (a, b) => a.ordem - b.ordem
    );
    setEntidades(nomesEntidades.map((entidade) => entidade.nome));
  }, [currentlyYear, eachDataFilter, eachPublicacao]);

  const handleDateClicked = (data) => {
    setCurrentlyYear(data);
  };

  const handleEntidadeClicked = (entidade) => {
    if (entidadeAtual === entidade) {
      setEntidadeAtual(null);
      return;
    }
    setEntidadeAtual(entidade);
  };

  const dataFormater = (data) => {
    const dataFormatada = new Date(data);
    const dia = String(dataFormatada.getDate()).padStart(2, '0');
    const mes = String(dataFormatada.getMonth() + 1).padStart(2, '0');
    const ano = dataFormatada.getFullYear();
    return `${dia}/${mes}/${ano} - `;
  };

  const exibirDatas = (arquivo) => {
    if (arquivo) {
      return arquivo.publicacao.categoriaPublicacao.exibeDia
        ? dataFormater(arquivo.publicacao.data)
        : arquivo.publicacao.categoriaPublicacao.exibeMes
        ? dataFormater(arquivo.publicacao.data).slice(3)
        : arquivo.publicacao.categoriaPublicacao.exibeAno
        ? dataFormater(arquivo.publicacao.data).slice(6)
        : '';
    }
  };

  const semAgruparPorDataEntidade = () => {
    return entidades.map((entidade, key) => {
      return (
        <div key={key} className='entidade'>
          <Button
            onClick={() => handleEntidadeClicked(entidade)}
            aria-controls={`todos-arquivos-${entidade}`}
            aria-expanded={entidadeAtual === entidade}
            className='nome-entidade'
            variant='link'
          >
            <h4>{entidade}</h4>
          </Button>

          {Object.values(publicacaoFilter).map((arrayArquivos) => {
            return (
              eachPublicacao &&
              eachPublicacao
                .filter((pub) => pub.entidade.nome === entidadeAtual && pub.id === arrayArquivos[0].publicacao.id)
                .map((pub) => (
                  <Collapse in={entidadeAtual === entidade}>
                    <div id={`todos-arquivos-${entidade}`}>
                      <div id={`todos-arquivos-${pub.entidade.nome}`}>
                        <a
                          href={
                            arrayArquivos[0].linkExterno
                              ? arrayArquivos[0].caminho
                              : `${config.api_path}/public/v1/${identificador}/publicacao_arquivo/download/${arrayArquivos[0].id}`
                          }
                          className='pdfs'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <i className='mr-2'>
                            <FontAwesomeIcon icon={faFilePdf} />
                          </i>
                          {`${exibirDatas(arrayArquivos[0])}${arrayArquivos[0].nome}`}
                        </a>
                      </div>
                    </div>
                  </Collapse>
                ))
            );
          })}
        </div>
      );
    });
  };

  const agruparAnoEntidade = () => {
    if (currentlyYear === null) return semAgruparPorDataEntidade();
    return (
      entidades &&
      entidades.map((entidade, key) => {
        return (
          <div key={key} className='entidade'>
            <Button
              onClick={() => handleEntidadeClicked(entidade)}
              aria-controls={`todos-arquivos-${entidade}`}
              aria-expanded={entidadeAtual === entidade}
              className='nome-entidade'
              variant='link'
            >
              <h4>{entidade}</h4>
            </Button>

            {Object.values(publicacaoFilter)
              .filter((array) => array[0].publicacao.data.slice(0, 4) === currentlyYear)
              .map((arrayArquivos) => {
                return (
                  eachPublicacao &&
                  eachPublicacao
                    .filter(
                      (pub) =>
                        pub.entidade && pub.entidade.nome === entidadeAtual && pub.id === arrayArquivos[0].publicacao.id
                    )
                    .map((pub) => (
                      <Collapse in={entidadeAtual === entidade}>
                        <div id={`todos-arquivos-${entidade}`}>
                          <div id={`todos-arquivos-${pub.entidade.nome}`}>
                            <a
                              href={
                                arrayArquivos[0].linkExterno
                                  ? arrayArquivos[0].caminho
                                  : `${config.api_path}/public/v1/${identificador}/publicacao_arquivo/download/${arrayArquivos[0].id}`
                              }
                              className='pdfs'
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              <i className='mr-2'>
                                <FontAwesomeIcon icon={faFilePdf} />
                              </i>
                              {`${exibirDatas(arrayArquivos[0])}${arrayArquivos[0].nome}`}
                            </a>
                          </div>
                        </div>
                      </Collapse>
                    ))
                );
              })}
          </div>
        );
      })
    );
  };

  const semAgrupar = () => {
    return Object.values(publicacaoFilter).map((arrayArquivos, index) => {
      if (arrayArquivos.length > 1) {
        return (
          <div className='arquivo' key={index}>
            <Button
              variant='link'
              className='pdfs'
              onClick={() => {
                setListaArquivos(arrayArquivos);
                handleShow();
              }}
            >
              <i className='mr-2'>
                <FontAwesomeIcon icon={faFilePdf} />{' '}
              </i>
              {`${arrayArquivos[0] && exibirDatas(arrayArquivos[0])}${arrayArquivos[0].publicacao.nome}`}
            </Button>
          </div>
        );
      } else if (arrayArquivos.length > 0) {
        return renderizarArquivo(arrayArquivos[0], index);
      }
      return null;
    });
  };

  const agruparAno = () => {
    if (currentlyYear === null) return semAgrupar();
    return Object.values(publicacaoFilter)
      .filter((arrayArquivos) => arrayArquivos[0] && arrayArquivos[0].publicacao.data.slice(0, 4) === currentlyYear)
      .map((arrayArquivos, index) => {
        if (arrayArquivos.length > 1) {
          return (
            <div className='arquivo'>
              <Button
                variant='link'
                className='pdfs'
                onClick={() => {
                  setListaArquivos(arrayArquivos);
                  handleShow();
                }}
              >
                <i className='mr-2'>
                  <FontAwesomeIcon icon={faFilePdf} />{' '}
                </i>
                {`${arrayArquivos[0] && exibirDatas(arrayArquivos[0])}${arrayArquivos[0].publicacao.nome}`}
              </Button>
            </div>
          );
        } else if (arrayArquivos.length > 0) {
          return renderizarArquivo(arrayArquivos[0], index);
        }
        return null;
      });
  };

  const renderizarArquivo = (arquivo, index) => {
    return arquivo ? (
      <div className='arquivo' key={index}>
        <a
          className='pdfs'
          href={
            arquivo.linkExterno
              ? arquivo.caminho
              : `${config.api_path}/public/v1/${identificador}/publicacao_arquivo/download/${arquivo.id}`
          }
          rel='noopener noreferrer'
          target='_blank'
        >
          <i className='mr-2'>
            <FontAwesomeIcon icon={faFilePdf} />{' '}
          </i>
          {`
          ${exibirDatas(arquivo)}
          ${arquivo.nome}`}
        </a>
      </div>
    ) : (
      ''
    );
  };

  const handleConsultarClicked = () => {
    const objetoFiltrado = {};
    const datas = [];
    for (const [chave, valor] of Object.entries(arquivoPublicacaoList)) {
      objetoFiltrado[chave] = valor.filter((item) => {
        const isNameMatch = item.nome.toLowerCase().includes(nomePublicacaoFilter.toLowerCase());
        const isDateInRange =
          new Date(item.publicacao.data) >= dataInicialFilter &&
          new Date(item.publicacao.data.slice(0, 4), 0, 1) <= dataFinalFilter;
        if (isNameMatch && isDateInRange) {
          const data = item.publicacao.data.slice(0, 4);
          datas.push(data);
          return true;
        }
        return false;
      });
    }
    const dataFiltrada = new Set(datas);
    setCurrentlyYear(Array.from(dataFiltrada).sort((a, b) => b - a)[0]);
    setEachDataFilter(Array.from(dataFiltrada));
    setPublicacaoFilter(objetoFiltrado);
  };

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'dataInicial') {
      setDataInicialFilter(new Date(value));
    } else if (name === 'dataFinal') {
      setDataFinalFilter(new Date(value));
    } else {
      setNomePublicacaoFilter(value);
    }
  };

  const handleTipoCategoriaChange = (tipo) => {
    if (tipo) {
      const datas = [];
      setTipoSelected(tipo);
      const objetoFiltrado = {};
      for (const [chave, valor] of Object.entries(arquivoPublicacaoList)) {
        objetoFiltrado[chave] = valor.filter((item) => {
          if (item.publicacao.categoriaPublicacaoTipo) {
            const tipoMatch = item.publicacao.categoriaPublicacaoTipo.nome === tipo.nome;
            if (tipoMatch) {
              const data = item.publicacao.data.slice(0, 4);
              datas.push(data);
              return true;
            }
            return false;
          }
          return null;
        });
      }
      const dataFiltrada = new Set(datas);
      setCurrentlyYear(Array.from(dataFiltrada).sort((a, b) => b - a)[0]);
      setEachDataFilter(Array.from(dataFiltrada));
      setPublicacaoFilter(objetoFiltrado);
    } else {
      setCurrentlyYear(Array.from(eachData).sort((a, b) => b - a)[0]);
      setEachDataFilter(eachData);
      setPublicacaoFilter(arquivoPublicacaoList);
    }
  };

  return declaracoes && portal && grupoComCategoria && portalCliente && categoria ? (
    <>
      <BarraAcessibilidade />
      <HeaderPortal />
      <NavigationBreadcrumb />
      <Container className='publicacoes p-0 m-0 pb-3' fluid>
        <Container className='content p-0'>
          {listaArquivos && (
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>{listaArquivos[0] ? listaArquivos[0].publicacao.nome : ''}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{listaArquivos.map((arquivo, index) => renderizarArquivo(arquivo, index))}</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <section className='content-left'>
            <header>
              <div className='categoria-voltar'>
                <h2 className='categoria-nome'>{categoria.nome}</h2>
                <div className='content-right'>
                  <NavLink exact to={`/${nomeUrl}`} className='btn'>
                    Voltar
                  </NavLink>
                </div>
              </div>
              <NavLink className='grupo-nome' to={`${location}/detail/${grupoNomeUrl}`}>
                <h5>{categoria.grupoCategoriaPublicacao.nome}</h5>
              </NavLink>
              {categoria.exibirDataAtualizacao ? <i className='atualizado-em'>Atualizado em: {atualizadoEm}</i> : ''}
            </header>

            {declaracaoInformacao != null && (
              <DeclaracaoInexistenciaAlerta informacoes={declaracaoInformacao} location={location} />
            )}

            {isPub && (
              <>
                {categoria.conteudoHtml && (
                  <main className='html'>
                    <ComponenteHTML key={categoria.id} htmlString={categoria.conteudoHtml} />
                  </main>
                )}

                <div hidden={hideVideo}>
                  <div>
                    <iframe
                      width='560'
                      height='315'
                      src={`https://www.youtube.com/embed/${codeYt}`}
                      title='YouTube video player'
                      frameborder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>

                <section className='filtros' hidden={hideArquivo}>
                  {categoria && (categoria.exibeAno || categoria.agruparAno) ? (
                    <Row className='mb-2 align-items-center'>
                      <Col md={2}>
                        <FormLabel>
                          <b>Data inicial:</b>
                        </FormLabel>
                        <Input.Date
                          name='dataInicial'
                          type='date'
                          value={dataInicialFilter}
                          onChange={handleFilterChange}
                        />
                      </Col>
                      <Col md={2}>
                        <FormLabel>
                          <b>Data final:</b>
                        </FormLabel>
                        <Input.Date
                          name='dataFinal'
                          type='date'
                          value={dataFinalFilter}
                          onChange={handleFilterChange}
                        />
                      </Col>
                      <Col md={4}>
                        <FormLabel>
                          <b>Nome da publicação:</b>
                        </FormLabel>
                        <Input name='nomePublicacao' type='text' onChange={handleFilterChange} />
                      </Col>
                      <Col className='d-flex align-self-end justify-content-md-end justify-content-center'>
                        <Button className='consultar' onClick={handleConsultarClicked}>
                          Consultar
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row className='d-flex justify-content-center align-items-center'>
                      <Col>
                        <FormLabel mb={4}>
                          <b>Nome da publicação:</b>
                        </FormLabel>
                        <Input name='nomePublicacao' type='text' onChange={handleFilterChange} />
                      </Col>
                      <Col className='d-flex align-self-end'>
                        <Button className='consultar' onClick={handleConsultarClicked}>
                          Consultar
                        </Button>
                      </Col>
                    </Row>
                  )}
                </section>

                {tiposCategoria.length > 0 ? (
                  <Row className='ml-0 mb-4'>
                    <Button
                      className={`mr-2 ${currentTipo === null ? 'tipo-selecionado' : 'tipo'}`}
                      onClick={() => {
                        handleTipoCategoriaChange(null);
                        setCurrentTipo(null);
                      }}
                    >
                      Todos os tipos
                    </Button>
                    {tiposCategoria.map((tipo, index) => (
                      <Button
                        key={index}
                        className={`mr-2 ${currentTipo === index ? 'tipo-selecionado' : 'tipo'}`}
                        onClick={() => {
                          handleTipoCategoriaChange(tipo);
                          setCurrentTipo(index);
                        }}
                      >
                        {tipo.nome}
                      </Button>
                    ))}
                  </Row>
                ) : (
                  ''
                )}

                {categoria.agruparAno && (
                  <Row className='ano-publicacao ml-0'>
                    {eachDataFilter &&
                      eachDataFilter
                        .sort((a, b) => b - a)
                        .map((data, index) => {
                          return (
                            <Button
                              variant='link'
                              key={index}
                              className={`anos ${Number(currentlyYear) === Number(data) ? 'underline' : ''}`}
                              onClick={() => handleDateClicked(data)}
                            >
                              {data}
                            </Button>
                          );
                        })}
                    <Button
                      variant='link'
                      className={`anos ${!currentlyYear ? 'underline' : ''}`}
                      onClick={() => handleDateClicked(null)}
                    >
                      Todos
                    </Button>
                  </Row>
                )}

                <div className='arquivo-list' hidden={hideArquivo}>
                  {categoria.agruparAno && categoria.agruparEntidade
                    ? agruparAnoEntidade()
                    : categoria.agruparAno
                    ? agruparAno()
                    : semAgrupar()}
                </div>

                <div className='subcategoria' hidden={hideCategoria}>
                  <div>
                    <h6>Categorias: </h6>
                    <div className='categorias'>
                      {subcategoria && subcategoria.map((cat, index) => <button key={index}>Categoria {cat}</button>)}
                    </div>
                  </div>
                </div>
              </>
            )}

            {isFaq && <Faq categoria={categoria} nomeUrl={nomeUrl} location={location} grupoNomeUrl={grupoNomeUrl} />}
          </section>
        </Container>
      </Container>

      <Footer />
    </>
  ) : (
    <Loading />
  );
}

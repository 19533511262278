import React from 'react';
import { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';


export default function ConfirmModal({ show, header, children, onConfirm, onRefuse, onComplete, confirmLabel = "Sim", refuseLabel = "Não" }) {


    const refuse = () => {
        if(onRefuse) onRefuse();
        if(onComplete) onComplete();
    }
 
    const confirm = () => {
        if(onConfirm) onConfirm();
        if(onComplete) onComplete();
    }

    return (
        <Modal centered show={show}>
            <Modal.Header>
                <h4>{header}</h4>
            </Modal.Header>
            <Modal.Body>
                { children }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={refuse} disabled={!show}>{refuseLabel}</Button>
                <Button variant="primary" onClick={confirm} disabled={!show}>{confirmLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
}
import React from 'react';
import { Component } from 'react';
import share from './ios-share.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InstallPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isIos: false,
            deferredInstallPrompt: null
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        // Detecta se o dispositivo está no iOS
        const isIos = /iphone|ipad|ipod/.test( window.navigator.userAgent.toLowerCase() );
        // Detects if device is in standalone mode
        const isInStandaloneMode = (('standalone' in window.navigator) && (window.navigator.standalone));
        // Verifica se deve exibir notificação popup de instalação:
        
        if(!isInStandaloneMode) {
            if(isIos) {
                this.setState({ 
                    ...this.state,
                    visible: true,
                    isIos: isIos
                });
            } else {
                window.addEventListener('beforeinstallprompt', (e) => {
                    // Stash the event so it can be triggered later.
                    e.preventDefault();
                    this.setState({
                        ...this.state,
                        visible: true,
                        isIos: isIos,
                        deferredInstallPrompt: e
                    });
                });
            }
        }
    }

    handleClick() {
        const { onInstall } = this.props;
        const { deferredInstallPrompt } = this.state;
        
        if(deferredInstallPrompt) {
            deferredInstallPrompt.prompt();

            deferredInstallPrompt.userChoice.then((choice) => {
                if(onInstall) onInstall();
            })
        }
    }

    render () {
        const { isIos, visible } = this.state;
        if(!visible) return null;
        
        return (
            <div onClick={this.handleClick} className="popover-arrow-top-center bs-popover-top popover" style={{top: 'auto', bottom: 5, position: 'fixed', left: 'calc(50vw - 150px)', width: 300, maxWidth: 300, cursor: 'pointer'}}>
                <div className="arrow" style={{left: 'calc(50% - 10px)'}}></div>
                <div className="popover-body">
                    <div className="d-flex w-100">
                        <div className="col pl-0 pr-2 justify-content-center align-items-center d-flex">
                            <span className="bg-secondary rounded text-white" style={{padding: "5px 10px"}}>
                                <FontAwesomeIcon icon="plus"/>
                            </span>
                        </div>
                        <div>
                            <div>
                                <strong>Você pode instalar esse aplicativo: </strong>
                            </div>
                            {
                                isIos ? 
                                <div>
                                    Toque em <img width="20" src={share} alt=""></img> e depois "Tela de Início"
                                </div>
                                :
                                <div>
                                    Clique aqui para instalar
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstallPromotion;
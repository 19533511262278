export default {
    
    api_path: '',
    jsf_path: 'https://transparencia.systemainformatica.com.br/views/',
    
    login_url: 'https://transparencia.systemainformatica.com.br/oauth2/login',
    logout_url: 'https://transparencia.systemainformatica.com.br/j_spring_security_logout',
    
    home: 'https://transparencia.systemainformatica.com.br/ui/'
}

import React, { Component } from 'react';

class Header extends Component {
    render () {
        const { children } = this.props;
        return (
            <h3 className="mt-2 font-weight-light">
                { children }
            </h3>
        );
    }
}

export default Header;
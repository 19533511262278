import React from 'react';
import { Component } from 'react';
import Loading from '../components/Loading';

class LoadingPage extends Component {
    render () {
        return (
            <Loading loading={true}></Loading>
        );
    }
}

export default LoadingPage;
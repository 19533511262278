import * as Yup from 'yup';

const schema = Yup.object().shape({
  "exercicio": Yup.string().required("Informe o exercício"),
  "numeroProcesso": Yup.string().required("Informe o número do processo"),
  "modalidadeTipo": Yup.string().required("Informe a modalidade"),
  "numero": Yup.string().required("Informe o número da modalidade"),
  "dataPublicacao": Yup.string().required("Informe a data de publicação"),
  "dataRecebimentoProposta": Yup.string().required("Informe a data do recebimento da proposta"),
  "situacao": Yup.string().required("Informe a situação da licitação"),
  "unidadeGestora": Yup.string().required("Informe a unidade gestora"),
  "objeto": Yup.string().required("Informe o objeto")
});

export default schema;

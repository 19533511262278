import React, { Component, Fragment } from 'react';
import { withViewport } from '../../components/hocs';
import { Form, Overlay, Modal, Popover, ListGroup } from 'react-bootstrap';
import { supplant, extractValue } from '../../components/utils/formatador';

import PropTypes from 'prop-types';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Select extends Component {
    constructor(props) {
        super(props);

        this.select = this.select.bind(this);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.hideOptions = this.hideOptions.bind(this);
        this.isSelected = this.isSelected.bind(this);
    }

    state = {
        selected: null,
        search: '',
        focused: false,
        target: null,
        options: [],
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            selected: this.props.value,
            options: this.props.options ? this.props.options : []
        });
        this.fetchOptions();
    }

    componentDidUpdate(oldProps) {
        
        if(!this.props.options && (oldProps.path !== this.props.path))  {
            this.setState({
                ...this.state,
                selected: this.props.value,
                focused: false
            });
            this.fetchOptions();
        } else if(oldProps.options !== this.props.options) {
            this.setState({
                ...this.state,
                selected: this.props.value,
                options: this.props.options,
                focused: false
            });
        } else if(this.props.value !== oldProps.value) {
            this.setState({
                ...this.state,
                selected: this.props.value
            });
        }
    }

    async fetchOptions() {
        const { options, path, value } = this.props;
        if(!options && path) {
            await this.setState({ ...this.state, loading: true });

            try {
                let res = await Axios.get(path);
                if(!res.data.error) {
                    let options = res.data.data.list || res.data.data;
                    
                    await this.setState({
                        ...this.state,
                        selected: value,
                        options 
                    });
                }
            } catch(e) {
                console.log(e);
            } finally {
                await this.setState({ ...this.state, loading: false });
            }
        }
    }

    select(row) {
        const { id, name, onChange } = this.props;
        this.setState({
            ...this.state,
            selected: row,
            focused: false,
        }, () => {
            if(onChange) onChange({ target: { id, name, value: row }});
        });
    }

    handleFocus(e) {
        this.setState({
            ...this.state,
            focused: true,
            target: e.target,
            search: ''
        });
    }

    hideOptions() {
        this.setState({
            ...this.state,
            focused: false
        });
    }

    handleBlur() {
        const { viewport } = this.props;
        if(viewport.width >= 768) this.hideOptions();
    }

    handleChange(e) {
        if(this.state.focused) {
            this.setState({
                ...this.state,
                search: e.target.value,
            });
        }
    }

    labelOf(value, highlight) {
        const { label } = this.props;
        if(!value) return "";

        let val = label ? supplant(label, value) : value;
        if(!highlight) return val;

        let index = val.toLowerCase().indexOf(highlight.toLowerCase());
        let length = highlight.length;

        return <span>{val.substring(0, index)}<b>{val.substring(index, index + length)}</b>{val.substring(index + length)}</span>
    }

    isSelected(option) {
        const { rowKey } = this.props;
        const { selected } = this.state;
        if(!option || !selected) return false;

        if(rowKey) return extractValue(selected, rowKey) === extractValue(option, rowKey);
        return selected === option;
    }

    render () {
        const { selected, target, search, focused, options, loading } = this.state; 
        const { viewport, placeholder, size, disabled, nullable, isInvalid, render, className } = this.props;

        const renderedOptions = (
            <ListGroup variant="flush">
                { (nullable && selected) &&
                    <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`} action
                        onClick={() => this.select(null)} >
                        <FontAwesomeIcon icon="times"/> Remover Seleção
                    </ListGroup.Item>
                }
                { (options && options.length > 0) ?
                    options.filter((option) => (render ? render(option) : this.labelOf(option)).toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    .map((option, i) => (
                    <ListGroup.Item key={i} action active={this.isSelected(option)} className={(size && `list-group-item-${size}`)}
                        onMouseDown={() => {this.select(option); this.hideOptions()}}>
                        {
                            render ? render(option, search) : this.labelOf(option, search)
                        }
                    </ListGroup.Item>
                    )) : !loading &&
                    <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`}>
                        Sem registros
                    </ListGroup.Item>
                }
                { (loading) &&  
                    <ListGroup.Item className={`text-center text-muted ${(size && `list-group-item-${size}`)}`}>
                        <FontAwesomeIcon icon="cog" spin /> Carregando...
                    </ListGroup.Item> 
                }
            </ListGroup>
        );

        return (
            <Fragment>
                { viewport.width >= 768 ?
                    <Overlay show={focused} target={target} placement="bottom-start">
                        <Popover style={{maxHeight: 200, overflowY: 'auto', minWidth: (this.el ? this.el.clientWidth : 0) }}>
                            <Popover.Content className="px-0">
                                {renderedOptions}
                            </Popover.Content>
                        </Popover>
                    </Overlay>
                    :
                    <Modal size="xl" show={focused} onHide={this.hideOptions} restoreFocus={false}>
                        <Modal.Header closeButton>
                            <Form.Control value={focused ? search : ( render ? render(selected) : this.labelOf(selected))} 
                                placeholder={selected ? (render ? render(selected) : this.labelOf(selected)) : placeholder}
                                onChange={this.handleChange}/>
                        </Modal.Header>
                        <Modal.Body className="px-0">
                            {renderedOptions}
                        </Modal.Body>
                    </Modal>
                }
                <Form.Control value={focused ? search : (render ? render(selected) : this.labelOf(selected))} 
                    placeholder={selected ? ( render ? render(selected) : this.labelOf(selected)) : placeholder} 

                    onFocus={this.handleFocus} onChange={this.handleChange}
                    ref={(el) => this.el = el} onBlur={this.handleBlur}
                    
                    autoComplete="off" disabled={disabled} isInvalid={isInvalid}
                    className={["custom-select", (size && `custom-select-${size}`), className].join(' ')}
                />
                
            </Fragment>
        );
    }

    static propsTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        
        placeholder: PropTypes.string,
        label: PropTypes.string.isRequired,
        rowKey: PropTypes.string,
        value: PropTypes.any,
        disabled: PropTypes.bool,
         
        options: PropTypes.array.isRequired,
    }

    static defaultProps = {
        placeholder:  "Selecione um item",
        options: false,
        disabled: false
    }
}

export default withViewport(Select);

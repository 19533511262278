import * as Yup from 'yup';

const schema = Yup.object({
  estruturaOrganizacional: Yup.object().test(
    'is-selected',
    'Selecione uma unidade',
    (est) => est && est.id
  ),
  nome: Yup.string().required('O nome é obrigatório'),
  descricao: Yup.string().required('Uma descrição é obrigatória'),

  exigencias: Yup.string(),
  pagamentoTaxas: Yup.string(),
  prazoAtendimento: Yup.string(),
  formaAcompanhamento: Yup.string(),

  agendamentoPrevio: Yup.boolean().default(false),
  atendimentoDomiciliar: Yup.boolean().default(false),

  documentos: Yup.array(
    Yup.object({
      nome: Yup.string().required('Nome do documento é obrigatório'),
      observacao: Yup.string().default(''),
      obrigatorio: Yup.boolean().default(true),
    })
  ).default([]),

  formasSolicitacao: Yup.array(
    Yup.object({
      tipoSolicitacao: Yup.object(),
      valor: Yup.string().required(''),
    })
  ).default([]),
});

export default schema;

import React from 'react';
import { Form } from 'react-bootstrap';

const Integer = ({ id, nome, value, onChange, locale = 'pt-br', ...rest }) => {
    

    //Formatar valor que vai ser exibido.
    let displayValue;
    if(!value && value !== 0) displayValue = '';
    else displayValue = value.toLocaleString(locale);

    //Tratar mudanças de valor
    const handleChange = (e) => {
        let inputValue = e.target.value;
        
        let rawValue = parseInt(inputValue.replace(/\D/g, ''));
        if(!rawValue || isNaN(rawValue)) rawValue = 0;

        if(inputValue.indexOf('-') >= 0) rawValue *= -1
        if(inputValue.indexOf('+') >= 0 && rawValue < 0) rawValue *= -1;

        if(onChange) onChange({ target: { id, nome, value: rawValue }});
    }

    return (
        <Form.Control value={displayValue} onChange={handleChange} {...rest}/>
    )
}

export default Integer;
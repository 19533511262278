import { UPDATE_CONFIG } from "../actions/actionTypes";

const initialState = {
    darkMode: false,
    fluidContent: true,
    
};

export const ConfigReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_CONFIG:
            let _state = { ...state };
            _state[action.property] = action.value;
            return _state;
        default:
            return state;
    }
}
import { faFileCsv, faFileExcel, faFilePdf, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Collapse, Container, Form, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';
import Service from '../../../components/Systema/Service';
import config from '../../../config';

const service = new Service(`${config.api_path}/public/v1/transparencia/contrato/arquivo`);
const serviceArquivo = new Service(`${config.api_path}/public/v1/transparencia/contrato/arquivo`);
const serviceArquivoLicitacao = new Service(`${config.api_path}/public/v1/transparencia/licitacao/arquivo`);
const sort = { data: 'DESC' };

export default function ContratoArquivoPage() {
  const [visivel, setVisivel] = useState(false);
  const [licitacaoVisivel, setLicitacaoVisivel] = useState(false);
  const [page, setPage] = useState(1);
  const [dadosModal, setDadosModal] = useState({});
  const [dadosModalLicitacao, setDadosModalLicitacao] = useState({});
  const anoAtual = new Date().getFullYear();
  const [filtroDataInicial, setFiltroDataInicial] = useState(new Date(anoAtual - 2, 0, 1));
  const [filtroDataFinal, setFiltroDataFinal] = useState(new Date());
  const [filtroFornecedor, setFiltroFornecedor] = useState('');
  const [filtroDocumento, setFiltroDocumento] = useState('');
  const [filterTable, setFilterTable] = useState({});
  const [contratoDataSincronizacao, setContratoDataSincronizacao] = useState();
  const [ultimoContrato, setUltimoContrato] = useState();
  const [filterTableSemData, setFilterTableSemData] = useState({});
  const [showFiltro, setShowFiltro] = useState();
  const [filtroRequest, setFiltroRequest] = useState({});
  let filter = { 'contrato.id': { $eq: `long:${dadosModal.id}` } };
  let filterLicitacao = { 'licitacao.id': { $eq: `long:${dadosModalLicitacao.id}` } };

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const showContrato = (row) => {
    setDadosModal(row);
    setVisivel(true);
  };

  const showLicitacao = (row) => {
    setDadosModalLicitacao(row);
    setLicitacaoVisivel(true);
  };

  const hide = () => setVisivel(false);
  const hideLicitacao = () => setLicitacaoVisivel(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: contratoSync } = await Axios.get(`${config.api_path}/public/v1/transparencia/cliente-tcepe/contrato-data-sincronizacao/${id}`);
      const { data: dataUltimoContrato } = await Axios.get(`${config.api_path}/public/v1/transparencia/contrato/arquivo/data-registro/${id}`);
      setContratoDataSincronizacao(contratoSync.data);
      setUltimoContrato(dataUltimoContrato.data);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (filtroFornecedor || filtroDocumento || id || filtroDataInicial || filtroDataFinal) {
      if (Object.keys(filtroRequest).length === 0) {
        setFiltroRequest({
          // data:
          //   filtroDataInicial || filtroDataFinal
          //     ? {
          //         $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
          //         $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
          //         $order: -1,
          //       }
          //     : undefined,
          'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
        });
      }
      setFilterTable({
        fornecedor: filtroFornecedor ? { $ilike: `${filtroFornecedor}` } : undefined,
        objeto: filtroDocumento ? { $ilike: `${filtroDocumento}` } : undefined,
        data:
          filtroDataInicial || filtroDataFinal
            ? {
                $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
              }
            : undefined,
        'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
      setFilterTableSemData({
        fornecedor: filtroFornecedor ? { $ilike: `${filtroFornecedor}` } : undefined,
        objeto: filtroDocumento ? { $ilike: `${filtroDocumento}` } : undefined,
        'contrato.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
    } else {
      setFilterTable({});
      setFilterTableSemData({});
    }
  }, [filtroFornecedor, filtroDocumento, id, filtroDataInicial, filtroDataFinal, filtroRequest]);

  function removeBracesAndParentheses(str) {
    const regex = /[{}()]/g;
    const cleanedStr = str.replace(regex, '');
    return cleanedStr;
  }

  return ultimoContrato && contratoDataSincronizacao ? (
    <Container fluid>
      <Card className='mt-4 mb-2'>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='font-weight-light my-0'>Arquivos dos Contratos</h3>
            </div>
            <span className='align-items-center' style={{ display: 'grid', gridTemplate: 'auto / auto auto', gap: '.4rem' }}>
              <small className='font-weight-bold'>Data da última Sincronização:</small>
              <small className='text-right'>{new Date(contratoDataSincronizacao).toLocaleDateString('pt-BR', opcoes)}</small>

              <small className='font-weight-bold'>Data do último Documento:</small>
              <small className='text-right'>{new Date(ultimoContrato).toLocaleDateString('pt-BR', opcoes)}</small>
            </span>
          </div>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Header className='py-2' onClick={() => setShowFiltro(!showFiltro)} style={{ cursor: 'pointer' }}>
          <div className='d-flex justify-content-between'>
            <div>
              <Card.Title className='my-0'>Filtros</Card.Title>
            </div>
            <div>
              <FontAwesomeIcon icon={showFiltro ? faMinus : faPlus} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={showFiltro}>
          <div>
            <Card.Body>
              <Row className='mb-3'>
                <Col>
                  <FormLabel>Data Inicial</FormLabel>
                  <Input.Date value={filtroDataInicial} type='date' onChange={(e) => setFiltroDataInicial(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Data Final</FormLabel>
                  <Input.Date value={filtroDataFinal} type='date' onChange={(e) => setFiltroDataFinal(e.target.value)} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <FormLabel>Fornecedor</FormLabel>
                  <Form.Control value={filtroFornecedor} onChange={(e) => setFiltroFornecedor(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Nome do Arquivo</FormLabel>
                  <Form.Control value={filtroDocumento} onChange={(e) => setFiltroDocumento(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant='outline-primary' onClick={() => setFiltroRequest(filterTable)}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <div>
        <Modal show={visivel} onHide={hide} size='lg'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  <strong>Fornecedor: </strong>
                  {dadosModal.fornecedor ? dadosModal.fornecedor : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModal.clienteTcepe ? dadosModal.clienteTcepe.clienteGlobal.nome : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModal.exercicio ? dadosModal.exercicio : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Contrato: </strong>
                  {dadosModal.numeroContrato ? dadosModal.numeroContrato : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Documento: </strong>
                  {dadosModal.documento ? dadosModal.documento : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor: </strong>
                  {dadosModal.valor ? dadosModal.valor : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModal.objeto ? dadosModal.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <Button onClick={() => window.open(dadosModal.urlContrato)}>Mais informações</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataPagination service={serviceArquivo} filter={filter}>
                  {({ data }) => (
                    <Table className='table-bordered table-hover table-striped'>
                      <thead>
                        <tr align='center'>
                          <th>Arquivo</th>
                          <th>Baixar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => (
                          <tr>
                            <td>{row.nomeArquivo}</td>
                            <td align='center' style={{ minWidth: 200 }}>
                              <Button
                                target='_blank'
                                href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/contratos/${row.contrato.exercicio}/${row.nomeArquivo}`}
                              >
                                Download
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </DataPagination>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={licitacaoVisivel} onHide={hideLicitacao} size='xl'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col md={3}>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModalLicitacao.exercicio ? dadosModalLicitacao.exercicio : ''}
                </p>
              </Col>
              <Col md={3}>
                <p>
                  <strong>Processo: </strong>
                  {dadosModalLicitacao.numeroProcesso ? dadosModalLicitacao.numeroProcesso : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor Adjudicado: </strong>
                  {dadosModalLicitacao.valorAdjudicado ? dadosModalLicitacao.valorAdjudicado : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModalLicitacao.clienteGlobal ? dadosModalLicitacao.clienteGlobal.nome : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Modalidade da licitação: </strong>
                  {dadosModalLicitacao.modalidade ? dadosModalLicitacao.modalidade : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModalLicitacao.objeto ? dadosModalLicitacao.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Participantes: </strong>
                  {dadosModalLicitacao.participantes ? dadosModalLicitacao.participantes : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Vencedores: </strong>
                  {dadosModalLicitacao.vencedores ? dadosModalLicitacao.vencedores : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Número da Modalidade: </strong>
                  {dadosModalLicitacao.numero ? dadosModalLicitacao.numero : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Situação: </strong>
                  {dadosModalLicitacao.situacao ? dadosModalLicitacao.situacao : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataPagination service={serviceArquivoLicitacao} filter={filterLicitacao}>
                  {({ data }) => (
                    <Table className='table-bordered table-hover table-striped'>
                      <thead>
                        <tr>
                          <th>Arquivo</th>
                          <th>Baixar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => {
                          return (
                            <tr>
                              <td>{row.nomeArquivo}</td>
                              <td align='center' style={{ minWidth: 200 }}>
                                <Button
                                  target='_blank'
                                  href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/licitacoes/${row.licitacao.exercicio}/${row.nomeArquivo}`}
                                >
                                  Download
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </DataPagination>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <DataPagination sort={sort} service={service} page={page} limit={14} filter={filtroRequest}>
          {({ data, page, lastPage, loading }) => (
            <Fragment>
              <Table className='table-bordered table-hover table-striped'>
                <thead>
                  <tr>
                    <th className='align-middle'>Exercício</th>
                    <th className='align-middle'>Unidade Gestora</th>
                    <th className='align-middle'>Data</th>
                    <th className='align-middle'>Número do contrato</th>
                    <th className='align-middle'>Processo licitatório</th>
                    <th className='align-middle'>Fornecedor</th>
                    <th className='align-middle'>Documento</th>
                    <th className='align-middle'>Nome do Arquivo</th>
                    <th className='align-middle'>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => {
                    if (row.contrato.clienteGlobal.identificador === id) {
                      return (
                        <tr>
                          <td>{row.contrato.exercicio}</td>
                          <td className='text-nowrap'>{row.contrato.clienteGlobal.nome}</td>
                          <td>{new Date(row.data).toLocaleDateString('pt-BR', opcoes)}</td>
                          <td>
                            <Button variant='link' onClick={() => showContrato(row.contrato)}>
                              {row.contrato.numeroContrato}
                              <FontAwesomeIcon className='ml-3' icon={faSearch} />
                            </Button>
                          </td>
                          <td>
                            {row.contrato.licitacao ? (
                              <Button variant='link' onClick={() => showLicitacao(row.contrato.licitacao)}>
                                {row.contrato.licitacao.modalidade + ' nº ' + row.contrato.licitacao.numero}
                              </Button>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{row.contrato.fornecedor}</td>
                          <td className='text-nowrap'>{removeBracesAndParentheses(row.contrato.documento)}</td>
                          <td>{row.nomeArquivo}</td>
                          <td>
                            {' '}
                            <Button
                              variant='outline-primary'
                              target='_blank'
                              href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/contratos/${row.contrato.exercicio}/${row.nomeArquivo}`}
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> PDF
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                    return '';
                  })}
                </tbody>
              </Table>

              <Paginator page={page} lastPage={lastPage} setPage={setPage} loading={loading} />
            </Fragment>
          )}
        </DataPagination>
      </div>
    </Container>
  ) : (
    <Loading />
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Container, ListGroup, Spinner, Table } from 'react-bootstrap';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import config from '../../../config';
import { useCliente } from '../../../navigation/context/ClienteContext';
import { parentOf } from '../../../navigation/utils';
import BarraAcessibilidade from '../PortalTransparenciaPage/BarraAcessibilidade/barraAcessibilidade';
import HeaderPortal from '../PortalTransparenciaPage/Header/header';
import NavigationBreadcrumb from '../../../navigation/NavigationBreadcrumb';

export default function ComponentName() {
  const match = useRouteMatch();
  const location = useLocation();
  const { identificador, grupoComCategoria } = useCliente();
  const [loading, setLoading] = useState(false);
  const [servico, setServico] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let res = await Axios.get(
          `${config.api_path}/public/v1/${identificador}/servico/${match.params.id}`
        );
        if (!res.data.error) {
          setServico(res.data.data);
          setLoading(false);
        } else {
          setError({
            subject: 'ERRO: Transparência Governamental (Detalhe de Serviço)',
            body: '',
            messages: res.data.messages,
          });
          setLoading(false);
        }
      } catch (err) {
        if (err.response) {
          console.log(err.response);
          let messages = err.response.data.messages;
          let body = `Olá%2c encontrei um erro ao acessar a página do Portal da Transparência.%0D%0A ${window.location}`;
          setError({
            subject: 'ERRO: Transparência Governamental (Detalhe de Serviço)',
            body: body,
            messages: messages,
          });
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [identificador, match.params.id]);

  return (
    grupoComCategoria && (
      <>
        <BarraAcessibilidade />
        <HeaderPortal />
        <NavigationBreadcrumb />
        <Container className='pt-4 mb-4'>
          {(!location.overrideLabel || !location.overrideLabel[match.url]) &&
            servico && (
              <Redirect
                to={{
                  pathname: match.url,
                  overrideLabel: {
                    ...location.overrideLabel,
                    [parentOf(match.url, 2)]:
                      servico.estruturaOrganizacional.nome,
                    [match.url]: servico.nome,
                  },
                }}
              />
            )}
          {servico ? (
            <Fragment>
              <h2 className='font-weight-light'>{servico.nome}</h2>
              <p className='lead'>{servico.descricao}</p>
              <hr />

              {servico.exigencias && (
                <div>
                  <h5>
                    <FontAwesomeIcon icon='tasks' /> Exigências
                  </h5>
                  <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
                    {servico.exigencias}
                  </p>
                </div>
              )}

              {servico.documentos && servico.documentos.length > 0 && (
                <Fragment>
                  <h5>
                    <FontAwesomeIcon icon='id-card' /> Documentos
                  </h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Obrigatório</th>
                        <th>Observação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servico.documentos.map((documento, i) => (
                        <tr key={i}>
                          <td>{documento.nome}</td>
                          <td>{documento.obrigatorio ? 'Sim' : 'Não'}</td>
                          <td>{documento.observacao || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Fragment>
              )}

              <div>
                <h5>
                  <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Requer
                  Agendamento Prévio
                </h5>
                <p className='ml-4'>
                  {servico.agendamentoPrevio ? 'Sim' : 'Não'}
                </p>
              </div>

              <div>
                <h5>
                  <FontAwesomeIcon icon='map-marker-alt' /> Atendimento
                  Domiciliar
                </h5>
                <p className='ml-4'>
                  {servico.atendimentoDomiciliar ? 'Sim' : 'Não'}
                </p>
              </div>

              {servico.pagamentoTaxas && (
                <div>
                  <h5>
                    <FontAwesomeIcon icon='money-bill-wave' /> Taxas
                  </h5>
                  <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
                    {servico.pagamentoTaxas}
                  </p>
                </div>
              )}

              {servico.prazoAtendimento && (
                <div>
                  <h5>
                    <FontAwesomeIcon icon={['far', 'clock']} /> Prazo de
                    Atendimento
                  </h5>
                  <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
                    {servico.prazoAtendimento}
                  </p>
                </div>
              )}

              {servico.formaAcompanhamento && (
                <div>
                  <h5>
                    <FontAwesomeIcon icon={'eye'} /> Acompanhamento
                  </h5>
                  <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
                    {servico.formaAcompanhamento}
                  </p>
                </div>
              )}

              {servico.formasSolicitacao &&
                servico.formasSolicitacao.length > 0 && (
                  <Fragment>
                    <h5>
                      <FontAwesomeIcon icon={'phone'} /> Formas de
                      Solicitação/Atendimento
                    </h5>
                    <Table>
                      <tbody>
                        {servico.formasSolicitacao.map(
                          (formaSolicitacao, i) => (
                            <tr key={i}>
                              <th>{formaSolicitacao.tipoSolicitacao.nome}</th>
                              <td>
                                {formaSolicitacao.tipoSolicitacao.id === 1 ? (
                                  <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                      formaSolicitacao.valor
                                    )}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {formaSolicitacao.valor}
                                  </a>
                                ) : formaSolicitacao.tipoSolicitacao.id ===
                                  3 ? (
                                  <a href={`mailto:${formaSolicitacao.valor}`}>
                                    {formaSolicitacao.valor}
                                  </a>
                                ) : formaSolicitacao.tipoSolicitacao.id ===
                                  4 ? (
                                  <a
                                    href={formaSolicitacao.valor}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <FontAwesomeIcon
                                      icon={'external-link-alt'}
                                    />{' '}
                                    {formaSolicitacao.valor}
                                  </a>
                                ) : (
                                  formaSolicitacao.valor
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Fragment>
                )}
            </Fragment>
          ) : loading ? (
            <ListGroup>
              <ListGroup.Item className='d-flex align-items-center'>
                <Spinner animation='border' />{' '}
                <span className='ml-2'>Carregando...</span>
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <Fragment>
              <h3 className='text-danger'>
                Ocorreu um erro ao consultar o serviço
              </h3>
              {error &&
                error.messages.map(({ code, description }, i) => (
                  <Alert
                    variant={
                      code < 1000
                        ? 'success'
                        : code < 2000
                        ? 'info'
                        : code <= 3000
                        ? 'warning'
                        : 'danger'
                    }
                  >
                    {description}
                  </Alert>
                ))}
              {error && (
                <small>
                  Se o erro persistir encaminhe essa mensagem de erro para{' '}
                  <a
                    href={`mailto:suporte@sysetmainformatica.com.br?subject=${error.subject}&body=${error.body}`}
                  >
                    suporte@systemainformatica.com.br
                  </a>
                </small>
              )}
            </Fragment>
          )}
        </Container>
      </>
    )
  );
}

// class Detail extends Component {
//   state = {
//     servico: null,
//     error: null,
//     loading: false,
//   };

//   componentDidMount() {
//     this.fetchData();
//   }

//   async fetchData() {
//     const { match } = this.props;

//     this.setState({
//       ...this.state,
//       loading: true,
//     });

//     try {
//       let res = await Axios.get(
//         `${config.api_path}/public/v1/${match.params.cliente}/servico/${match.params.id}`
//       );

//       if (!res.data.error) {
//         this.setState({
//           ...this.state,
//           servico: res.data.data,
//           loading: false,
//         });
//       } else {
//         this.setState({
//           ...this.state,
//           error: {
//             subject: 'ERRO: Transparência Governamental (Detalhe de Serviço)',
//             body: '',
//             messages: res.data.messages,
//           },
//           loading: false,
//         });
//       }
//     } catch (err) {
//       if (err.response) {
//         console.log(err.response);
//         let messages = err.response.data.messages;
//         let body = `Olá%2c encontrei um erro ao acessar a página do Portal da Transparência.%0D%0A ${window.location}`;

//         this.setState({
//           ...this.state,
//           error: {
//             subject: 'ERRO: Transparência Governamental (Detalhe de Serviço)',
//             body: body,
//             messages: messages,
//           },
//           loading: false,
//         });
//       }
//     }
//   }

//   render() {
//     const { match, location } = this.props;
//     const { loading, servico, error } = this.state;

//     return (
//       <Container>
//         {(!location.overrideLabel || !location.overrideLabel[match.url]) &&
//           servico && (
//             <Redirect
//               to={{
//                 pathname: match.url,
//                 overrideLabel: {
//                   ...location.overrideLabel,
//                   [parentOf(match.url, 2)]:
//                     servico.estruturaOrganizacional.nome,
//                   [match.url]: servico.nome,
//                 },
//               }}
//             />
//           )}
//         {servico ? (
//           <Fragment>
//             <h2 className='font-weight-light'>{servico.nome}</h2>
//             <p className='lead'>{servico.descricao}</p>
//             <hr />

//             {servico.exigencias && (
//               <div>
//                 <h5>
//                   <FontAwesomeIcon icon='tasks' /> Exigências
//                 </h5>
//                 <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
//                   {servico.exigencias}
//                 </p>
//               </div>
//             )}

//             {servico.documentos && servico.documentos.length > 0 && (
//               <Fragment>
//                 <h5>
//                   <FontAwesomeIcon icon='id-card' /> Documentos
//                 </h5>
//                 <Table>
//                   <thead>
//                     <tr>
//                       <th>Documento</th>
//                       <th>Obrigatório</th>
//                       <th>Observação</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {servico.documentos.map((documento, i) => (
//                       <tr key={i}>
//                         <td>{documento.nome}</td>
//                         <td>{documento.obrigatorio ? 'Sim' : 'Não'}</td>
//                         <td>{documento.observacao || '-'}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>
//               </Fragment>
//             )}

//             <div>
//               <h5>
//                 <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Requer
//                 Agendamento Prévio
//               </h5>
//               <p className='ml-4'>
//                 {servico.agendamentoPrevio ? 'Sim' : 'Não'}
//               </p>
//             </div>

//             <div>
//               <h5>
//                 <FontAwesomeIcon icon='map-marker-alt' /> Atendimento Domiciliar
//               </h5>
//               <p className='ml-4'>
//                 {servico.atendimentoDomiciliar ? 'Sim' : 'Não'}
//               </p>
//             </div>

//             {servico.pagamentoTaxas && (
//               <div>
//                 <h5>
//                   <FontAwesomeIcon icon='money-bill-wave' /> Taxas
//                 </h5>
//                 <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
//                   {servico.pagamentoTaxas}
//                 </p>
//               </div>
//             )}

//             {servico.prazoAtendimento && (
//               <div>
//                 <h5>
//                   <FontAwesomeIcon icon={['far', 'clock']} /> Prazo de
//                   Atendimento
//                 </h5>
//                 <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
//                   {servico.prazoAtendimento}
//                 </p>
//               </div>
//             )}

//             {servico.formaAcompanhamento && (
//               <div>
//                 <h5>
//                   <FontAwesomeIcon icon={'eye'} /> Acompanhamento
//                 </h5>
//                 <p className='ml-4' style={{ whiteSpace: 'pre-wrap' }}>
//                   {servico.formaAcompanhamento}
//                 </p>
//               </div>
//             )}

//             {servico.formasSolicitacao &&
//               servico.formasSolicitacao.length > 0 && (
//                 <Fragment>
//                   <h5>
//                     <FontAwesomeIcon icon={'phone'} /> Formas de
//                     Solicitação/Atendimento
//                   </h5>
//                   <Table>
//                     <tbody>
//                       {servico.formasSolicitacao.map((formaSolicitacao, i) => (
//                         <tr key={i}>
//                           <th>{formaSolicitacao.tipoSolicitacao.nome}</th>
//                           <td>
//                             {formaSolicitacao.tipoSolicitacao.id === 1 ? (
//                               <a
//                                 href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
//                                   formaSolicitacao.valor
//                                 )}`}
//                                 target='_blank'
//                                 rel='noopener noreferrer'
//                               >
//                                 {formaSolicitacao.valor}
//                               </a>
//                             ) : formaSolicitacao.tipoSolicitacao.id === 3 ? (
//                               <a href={`mailto:${formaSolicitacao.valor}`}>
//                                 {formaSolicitacao.valor}
//                               </a>
//                             ) : formaSolicitacao.tipoSolicitacao.id === 4 ? (
//                               <a
//                                 href={formaSolicitacao.valor}
//                                 target='_blank'
//                                 rel='noopener noreferrer'
//                               >
//                                 <FontAwesomeIcon icon={'external-link-alt'} />{' '}
//                                 {formaSolicitacao.valor}
//                               </a>
//                             ) : (
//                               formaSolicitacao.valor
//                             )}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 </Fragment>
//               )}
//           </Fragment>
//         ) : loading ? (
//           <ListGroup>
//             <ListGroup.Item className='d-flex align-items-center'>
//               <Spinner animation='border' />{' '}
//               <span className='ml-2'>Carregando...</span>
//             </ListGroup.Item>
//           </ListGroup>
//         ) : (
//           <Fragment>
//             <h3 className='text-danger'>
//               Ocorreu um erro ao consultar o serviço
//             </h3>
//             {error &&
//               error.messages.map(({ code, description }, i) => (
//                 <Alert
//                   variant={
//                     code < 1000
//                       ? 'success'
//                       : code < 2000
//                       ? 'info'
//                       : code <= 3000
//                       ? 'warning'
//                       : 'danger'
//                   }
//                 >
//                   {description}
//                 </Alert>
//               ))}
//             {error && (
//               <small>
//                 Se o erro persistir encaminhe essa mensagem de erro para{' '}
//                 <a
//                   href={`mailto:suporte@sysetmainformatica.com.br?subject=${error.subject}&body=${error.body}`}
//                 >
//                   suporte@systemainformatica.com.br
//                 </a>
//               </small>
//             )}
//           </Fragment>
//         )}
//       </Container>
//     );
//   }
// }

// export default withRouter(Detail);

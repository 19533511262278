import Axios from "axios";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Col, Form, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import Crud from "../../../components/Crud"
import Input from "../../../components/Input"
import config from '../../../config'
import schema from "./schema"

const columns = [
    { header: "Nome", property: "nome", filter: true },
    { header: "Nome da URL", property: "nomeUrl", filter: true },
    { header: "Cliente", property: "clienteGlobal.nome", filter: true },
]


export default function PortalPage() {

    function handleKeyPress(event) {
        const pattern = /^[a-z]+$/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        };
    };

    function selectCliente(handleChange) {
        return (e) => {
    
            let cliente = e.target ? e.target.value : null;
            let nome = cliente ? cliente.nome : "";

            handleChange({
                target: {
                    id: "clienteGlobal",
                    value: cliente
                }
            });
            

            handleChange({
                target: {
                    id: "nome",
                    value: nome
                }
            })
        };
    };

    return (
        <Crud
            header="Portal"
            columns={columns}
            path={`${config.api_path}/v1/transparencia/portal`}
            rows={20}
            schema={schema}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>

                    <Row>
                        <Col>
                            <Form.Group>
                                <FormLabel>Cliente</FormLabel>
                                <Input.AsyncSelect id="clienteGlobal" value={values.clienteGlobal} onChange={selectCliente(handleChange)}
                                    isInvalid={errors.clienteGlobal}
                                    path={`${config.api_path}/v1/transparencia/clienteglobal`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione um Cliente"
                                />
                                <Form.Control.Feedback type="invalid">{errors.clienteGlobal}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5}>
                            <Form.Group>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nome" value={values.nome} onChange={handleChange} isInvalid={errors.nome} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={5}>
                            <Form.Group>
                                <FormLabel>Nome da URL</FormLabel>
                                <InputGroup>
                                    <Input.Text id="nomeUrl" value={values.nomeUrl} onChange={handleChange} isInvalid={errors.nomeUrl} onKeyPress={handleKeyPress} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.nomeUrl}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>

            )}

        ></Crud>
    )
}
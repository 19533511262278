import { SET_SESSION_LOADING, SET_CONTEXT_LOADING, SET_SESSION, HANDLE_SESSION_ERROR, REMOVE_SESSION } from '../actions/actionTypes';
import { UPDATE_SERVICEWORKER } from '../actions/actionTypes';

const initialState = {
    loading: false,
    loadingContext: false,

    isAuthenticated: false,
    user: null,
    context: null,
    expires_in: 0,
    isOffline: false,

    error: false,
    messages: null,

    serviceworkerUpdated: false,
};

export const SessionReducer = (state = initialState, { type, value }) => {
    switch(type) {
        case SET_SESSION_LOADING: 
            return {
                ...state,
                loading: true,
                error: false,
                messages: null
            }
        case SET_CONTEXT_LOADING:
            return {
                ...state,
                context: null,
                loadingContext: true,
                error: false,
                messages: null
            }
        case SET_SESSION:
            return {
                ...state,
                loading: false,
                loadingContext: false,
               
                isAuthenticated: value.data && value.data.isAuthenticated,
                user: value.data && value.data.user,
                context: value.data && value.data.context,
                expires_in: value.data && value.data.expires_in,
                isOffline: false,

                error: value.error,
                messages: value.messages
            }
        case HANDLE_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                loadingContext: false,
                isOffline: true,
            }
        case REMOVE_SESSION:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                context: null,
                expires_in: 0
            }
        case UPDATE_SERVICEWORKER:
            return {
                ...state,
                serviceworkerUpdated: value
            }
        default:
            return state;
    }
}
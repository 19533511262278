import React, { Component } from 'react';
import { types } from 'react-alert';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AlertTemplate extends Component {

    render() {
        const { options, message, close} = this.props;
        let header = "Alerta";
        let color = "text-dark";
        let icon = "question-circle";
    
        if(options.type === types.INFO) {
            header = "Notificação";
            color = "bg-primary text-white";
            icon = "info";
        } if(options.type === types.SUCCESS){
            header = "Sucesso";
            color = 'bg-success text-white';
            icon = "check";
        } else if(options.type === "warning"){
            header = "Alerta";
            color = 'bg-warning text-white';
            icon = "exclamation-triangle";
        } else if(options.type === types.ERROR){
            header = "Erro";
            color = 'bg-danger text-white';
            icon = "skull-crossbones";
        }
    
        return (
            <Toast onClose={close}>
                <Toast.Header className={color}>
                    <FontAwesomeIcon icon={icon} />
                    <strong className="ml-2 mr-auto">{header}</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        )
    }
}

export default AlertTemplate;
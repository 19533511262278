import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout, removePerson } from '../actions'
import { Container } from 'react-bootstrap';
import config from '../config';

const mapStateToProperties = store => ({
    isAuthenticated: store.sessionState.isAuthenticated
});

const mapDispatchToProperties = dispatch => bindActionCreators({ logout, removePerson }, dispatch);

class LogoutPage extends Component {
    componentDidMount() {
        this.props.logout(() => {
            window.location.href = config.logout_url;
        })
    }
    
    render () {
        return (
            <Container>
                Saindo...
            </Container>
        );
    }
}

export default connect(mapStateToProperties, mapDispatchToProperties)(LogoutPage);
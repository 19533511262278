import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Informe o nome'),
  categoriaPublicacao: Yup.string().required('Informe a categoria'),
  tipoPublicacao: Yup.string().required('Informe o tipo'),
  // "publicacaoArquivos": Yup.array().of(Yup.object({
  //     nome: Yup.string().required("Informe um nome para o arquivo"),
  //     caminho: Yup.string().required("Faça o upload do arquivo")
  // })).required(1, "Pelo menos um arquivo tem que ser enviado")
});

export default schema;

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import React, { Fragment, useRef, useState } from 'react';
import { Button, Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Pergunta', property: 'pergunta', filter: true },
  { header: 'Ordem de Exibição', property: 'ordemExibicao', filter: true },
];

export default function FaqPage() {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  async function descer() {
    setLoading(true);
    await Axios.post(
      '/v1/transparencia/categoria_publicacao/trocar-ordem?direcao=descer',
      selected
    );
  }

  async function subir() {
    setLoading(true);
    await Axios.post(
      '/v1/transparencia/categoria_publicacao/trocar-ordem?direcao=subir',
      selected
    );
  }

  return (
    <Crud
      header='Perguntas e respostas frequentes'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/faq`}
      rows={20}
      schema={schema}
      buttonBar={({ selected, errors }) => (
        <>
          <div>
            <Row>
              <Button onClick={() => subir(selected)}>
                <FontAwesomeIcon icon={faArrowUp} />
                Subir
              </Button>
              <Col>
                <Button onClick={() => descer(selected)}>
                  <FontAwesomeIcon icon={faArrowDown} />
                  Descer
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={5}>
              <Form.Group>
                <FormLabel>Pergunta</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='pergunta'
                    value={values.pergunta}
                    onChange={handleChange}
                    isInvalid={errors.pergunta}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.pergunta}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <FormLabel>Portal</FormLabel>
                <Input.AsyncSelect
                  id='portalCliente'
                  value={values.portalCliente}
                  onChange={handleChange}
                  isInvalid={errors.portalCliente}
                  path={`${config.api_path}/v1/transparencia/portalcliente`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione um portal'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.portalCliente}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <FormLabel>Resposta</FormLabel>
          <Editor
            apiKey='g23uxmawmmddie2rzdpv01jdu0mp43tmtd1g63nf1ok2ec1h'
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={values.respostaHTML}
            onEditorChange={(html) =>
              handleChange({ target: { id: 'respostaHTML', value: html } })
            }
            init={{
              height: 400,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

/*
* Esse arquivo importa todos os nossos reducers e 
* combina eles num unico reducer que será incluido
* na Store.
* 
* Isso previne que tenhamos que importar todos os reducers na store.
*/
import { combineReducers } from 'redux';

//This is where we import our reducers, define
import { SessionReducer } from './sessionReducer'
import { PersonReducer } from './personReducer';
import { ConfigReducer } from './configReducer';

export const Reducers = combineReducers({
  sessionState: SessionReducer,
  personState: PersonReducer,
  configState: ConfigReducer
});
import React from 'react';
import { Alert, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './declaracaoInexistencia.scss';
export default function DeclaracaoInexistenciaAlerta({ informacoes, location }) {
  return (
    <Col className='p-0 w-75'>
      <Alert variant='warning'>
        <h4>Declaração de Inexistência</h4>
        <p className='declaracao'>
          {informacoes.declaracao} no periodo de {informacoes.periodo}
        </p>
        <p>
          Veja as Declarações de Inexistência de Informações{' '}
          <NavLink exact to={`${location}/declaracao-inexistencia-informacao`}>
            (Clique aqui)
          </NavLink>
        </p>
      </Alert>
    </Col>
  );
}

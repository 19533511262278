export const showMessages = (alert) => (messages) => {
    messages.forEach((msg) =>
        msg.code > 2999 ? alert.error(msg.description) :
        msg.code > 1999 ? alert.show(msg.description, {type: 'warning'}) :
        msg.code >  999 ? alert.show(msg.description) :
        alert.success(msg.description) 
    );
}

export const handleResponse = (alert) => (
    (json) => (new Promise((resolve, reject) => {
            showMessages(alert)(json.messages);
            resolve(json);
        })
    )
);

export const handleAxiosResponse = (alert) => (
    (res) => handleResponse(alert)(res.data)
);

export const handleAxiosError = (alert) => {
    return (err) => {
        return new Promise((resolve, reject) => {
            alert.error(err.message);
            reject(err);
        });
    }
}
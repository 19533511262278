import React from 'react';
import { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import TreeNode from './TreeNode';
import Axios from 'axios';
import debounce from 'es6-promise-debounce';

const CancelToken = Axios.CancelToken;

class Tree extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: null,
            loading: 0,
            list: [],
            count: 0,
            errors: null,
        }

        this.refresh = this.refresh.bind(this)
        this.deboncedRefresh = debounce(this.refresh.bind(this), 1000)

        this.setSelected = this.setSelected.bind(this)
        this.getRoots = this.getRoots.bind(this)
    }

    componentDidMount() {
        this.deboncedRefresh();
    }

    refresh() {
        this.setState(oldState => ({
            ...this.state,
            selected: null,
            loading: (oldState.loading + 1),
        }))

        let url = `${this.props.path}?limit=${2000}&offset=${0}`;

        const cancelToken = {
            cancelToken: new CancelToken((c) => { this.cancelRequest = c; })
        };

        return new Promise((resolve, reject) =>
            Axios.get(url, cancelToken)
                .then(res => {
                    this.cancelRequest = undefined
                    var json = res.data
                    if (json.error) {
                        reject(json)
                        this.setState(oldState => ({
                            loading: (oldState.loading - 1),
                            errors: json.messages
                        }));

                    } else {
                        resolve(json.data);
                        this.setState(oldState => ({
                            ...this.state,
                            list: json.data.list,
                            count: json.data.count,
                            loading: oldState.loading - 1,
                            errors: null
                        }));

                    }
                }
                    , err => {
                        console.log(err)
                    }
                )
        )
    }

    setSelected(row) {
        const { onSelect } = this.props;

        this.setState({
            ...this.state,
            selected: row
        });

        if (onSelect) onSelect(row);
    }

    hasSelected() {
        if (!this.state.selected) {
            this.props.alert.show("Selecione um registro", { type: "warning" });
            return false;
        }
        return true;
    }

    getSelected() {
        return this.state.selected
    }

    /* Método alterável de acordo com estrutura da entidade (Parametrizar futuramente) */
    getRoots(data) {
        var result = []
        for (var node in data) {
            if (data[node].estruturaOrganizacionalPai === null || data[node].estruturaOrganizacionalPai === undefined) {
                result.push(data[node])
            }
        }
        return result;
    }

    render() {
        const { columns, allNodes } = this.props;

        return (
            <Fragment>
                <Table>
                    <thead>
                        <tr className="bg-light">
                            {columns.map((column) =>
                                <th>{column.header}</th>
                            )}
                        </tr>
                    </thead>


                    {


                        (allNodes === null || allNodes === undefined) && this.state.count > 0 ?


                            //uses the list got by axios request to a router
                            <tbody>
                                {
                                    this.getRoots(this.state.list).map((node) =>
                                        <TreeNode selected={this.state.selected} getSelected={this.getSelected()} onSelect={this.setSelected} data={node} padding={node.depth} allNodes={this.state.list} depth={0} />
                                    )
                                }
                            </tbody>

                            :

                            //uses the props allNodes to populate the table
                            <tbody>
                                {
                                    this.getRoots(allNodes).map((node) =>
                                        <TreeNode selected={this.state.selected} getSelected={this.getSelected()} onSelect={this.setSelected} data={[node]} padding={node.depth} allNodes={allNodes} />
                                    )
                                }
                            </tbody>

                    }
                </Table>
            </Fragment>
        )
    }

}

export default Tree;
import React from 'react';
import { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TreeNode extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: this.props.selected,
            padding: this.props.padding,
            showChildren: this.props.children ? this.props.children : null,
            depth: this.props.depth ? this.props.depth : 0,
        }

        this.getChildren = this.getChildren.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleChildren = this.handleChildren.bind(this)
    }

    /* Método alterável de acordo com estrutura da entidade (Parametrizar futuramente) */
    getChildren(selectedNode, treeNodes) {
        var result = []
        for (var node in treeNodes) {
            if (treeNodes[node].estruturaOrganizacionalPai && treeNodes[node].estruturaOrganizacionalPai.codigoCompleto === selectedNode.codigoCompleto) {
                result.push(treeNodes[node])
            }
        }
        return result;
    }

    handleSelect(e, node) {

        this.setState({
            ...this.state,
            selected: node || null
        });
        if (this.props.onSelect) this.props.onSelect(node);

    }

    handleChildren(node, treeNodes) {
        if (this.getChildren(node, treeNodes).length > 0) {
            this.setState({
                ...this.state,
                showChildren: !this.state.showChildren
            })
        }
    }

    render() {
        const { data, allNodes, getSelected } = this.props

        return (
            <Fragment>

                <tr key={data.codigo} style={{ cursor: 'pointer' }}
                    className={getSelected === data ? "table-primary" : ""}>

                    <td style={{ paddingLeft: this.state.padding }} >
                        {!this.state.showChildren && this.getChildren(data, allNodes).length > 0 ?
                            <span onClick={(e) => this.handleChildren(data, allNodes)}>
                                <FontAwesomeIcon icon="plus" />
                            </span>
                            :
                            <span onClick={(e) => this.handleChildren(data, allNodes)}>
                                <FontAwesomeIcon icon="minus" />
                            </span>
                        }
                    </td>

                    <td onClick={(e) => this.handleSelect(e, data)} style={{ paddingLeft: this.state.padding }}>
                        {data.codigoCompleto}
                    </td>

                    <td onClick={(e) => this.handleSelect(e, data)}>
                        {data.nome}
                    </td>

                </tr>

                {this.getChildren(data, allNodes).map((childNode, i) => {
                    return (
                        <Fragment>
                            {
                                this.state.showChildren ?
                                    <TreeNode getSelected={getSelected} selected={this.state.selected} onSelect={(e) => this.props.onSelect(e)} data={childNode} padding={20 * (this.state.depth + 1)} allNodes={allNodes} depth={this.state.depth + 1} />
                                    :
                                    null
                            }
                        </Fragment>
                    )
                })}


            </Fragment>
        )
    }

}

export default TreeNode;
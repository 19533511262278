import React from 'react';
export default function (Component) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = { width: window.innerWidth, height: window.innerHeight }
            this.handleChange = this.handleChange.bind(this)
        }

        componentDidMount() {
            window.addEventListener('resize', this.handleChange);
            this.handleChange();
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.handleChange);
        }

        handleChange() {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }

        render() {
            const { width, height } = this.state;
            return <Component {...this.props}
                viewport={{
                    width, height,
                    xs: width < 576,
                    sm: width >= 576 && width < 768,
                    md: width >= 768 && width < 992,
                    lg: width >= 992 && width < 1200,
                    xl: width >= 1200
                }}/>
        }
    }
}
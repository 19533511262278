import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { NavDropdown } from 'react-bootstrap';

import NavbarSubitem from './NavbarSubitem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class NavbarItem extends Component {

    render() {
        const { menu } = this.props;
        
        if(menu.items) return (
            <NavDropdown title={menu.name} rootCloseEvent={"mousedown"} onSelect={() => null}>
                { menu.items.map(subitem => ( <NavbarSubitem key={subitem.id} menu={subitem}/>))}
            </NavDropdown>
        )

        if(menu.href) return (
            <a className="nav-link" href={menu.href}> {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name}</a>
        )

        if(menu.to.startsWith('/http')) return (
            <a className="nav-link" href={menu.to.substring(1)} target="_blank"> {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name} <FontAwesomeIcon icon={faExternalLinkAlt}/></a>
        )

        return (
            <Fragment>
                <NavLink exact className="nav-link" to={menu.to}>
                    {menu.icon && <FontAwesomeIcon icon={menu.icon}/>} {menu.name}
                </NavLink>
            </Fragment>
        );
    }
}

export default NavbarItem;
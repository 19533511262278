import React, { Component, Fragment } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';

import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import InputMask from 'react-input-mask';

import { stringToTime } from '../utils/formatador';
import { timeToString } from '../utils/formatador';

registerLocale(ptBR);

class Time extends Component {
    constructor(props, context) {
        super(props, context);
        let dateString = props.value ? timeToString(props.value) : null;

        this.state = {
            displayValue: dateString ? dateString : '',
            value: !props.value ? null : new window.Date(props.value),
            showCalendar: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleDatePick = this.handleDatePick.bind(this);
    }

    componentDidUpdate(oldProps) {
        const { value } = this.props;
      
        if(value && oldProps.value !== value) {
            let dateString = timeToString(value);
            this.setState({
                ...this.state,
                displayValue: dateString ? dateString : '',
                value: value
            });
        }
        
    }

    handleInputChange(e) {
        const { id, name, onChange } = this.props;
        let value = e.target.value;
        
        let dateValue = stringToTime(value);

        this.setState({
            ...this.state,
            displayValue: value,
            value: dateValue,
        }, () => {
            if(onChange) 
                onChange({ target: { id, name, value: this.state.value ? this.state.value : null }});
        });

        
    }

    handleInputFocus(e) {
        this.setState({
            ...this.state,
            showCalendar: true,
            target: e.target,
        });
    }

    handleInputBlur(e) {
        // this.setState({
        //     ...this.state,
        //     showCalendar: false
        // });
    }

    handleDatePick(date) {
        const { id, name, onChange } = this.props;
        
        this.setState({
            ...this.state,
            displayValue: timeToString(date),
            value: date,
            showCalendar: false
        }, () => {
            if(onChange) 
                onChange({ target: { id, name, value: this.state.value ? this.state.value : null }});
        })

        
    }

    render () {
        const { className, isInvalid } = this.props;
        const { displayValue, showCalendar, target, value } = this.state;

        return (
            <Fragment>
                <Overlay rootClose rootCloseEvent="mousedown" onHide={() => this.setState({...this.state, showCalendar: false})} show={showCalendar} target={target} placement="bottom-start">
                    <Popover>
                        <Popover.Content className="p-0">
                            <DatePicker selected={value} className="border-0" inline dateFormat="hh:mm" locale="pt-BR" 
                                    onChange={this.handleDatePick} showTimeSelect showTimeSelectOnly/>            
                        </Popover.Content>
                    </Popover>
                </Overlay>
                
                <Form.Control as={InputMask} value={displayValue} className={`form-control ${className}`} mask="99:99" 
                    isInvalid={isInvalid} pattern="[0-9]"
                    onChange={this.handleInputChange} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur}/>
            </Fragment>
        );
    }
}

export default Time;
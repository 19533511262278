import React from 'react';
import { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class AboutPage extends Component {
    render () {
        return (
            <Container className="pt-2">
                <h1 className="text-center display-4">Treinamento</h1>
                <p className="text-center lead">
                    Sistema de Treinamento
                </p>
                <p className="text-center">
                    Aplicativo web para treinamento de Desenvolvedores.
                </p>
                <div className="mt-3 text-center">
                    <div>Aplicativo Web Progressivo desenvolvido e mantido por</div>
                    <div><strong>Systema Informática Comércio e Serviço LTDA.</strong></div>
                    <small className="text-muted">
                        Esse aplicativo pode ser <Link to="/help/how-to-install">instalado</Link> no seu Smartfone, Tablet
                        ou Computador
                    </small>
                </div>
            </Container>
        );
    }
}

export default AboutPage;
import Axios from "axios";

const idOf = (object) => object.id ? object.id : object;
const join = (path) => path.map(part => `${part}`.replace(/(^\/|\/$)/g, '')).join('/');
const makeQuery = obj => {
    let query = '';
    for(let i in obj) {
        if(!obj[i]) continue;

        if(!query) query = '?';
        else query += '&';

        query += (`${i}=${obj[i]}`)
    }

    return query;
}

class Service {

    constructor(path, options) {
        this.basePath = path;
        this.options = options;
    }

    request(url, method, data) {
        return new Promise((resolve, reject) => {
            method(url, data).then(res => {
                //TODO: handle API errors
                resolve(res.data);
            }).catch(err => {
                //TODO: handle http errors
                reject(err);
            })
        });
    }

    /*
        limit: limit the number of items that will be returned (defaults to 10),
        offset: 0 based integer to define pagination offset (defaults to 0),
        filter: Object for filter definition,
        sort: Array of strings with columns to define sorting (use :DESC to asign a column to be sorted in descending order. ex.: ["codigo:DESC","nome"] "codigo" will be sorted in descending order and "nome" will be sorted in ascending order)
        columns: Array of columns that will be returned, null or empty will return ALL columns
    */
    list({ limit, offset, filter, sort, columns, params : others }) {
        let params = { 
            limit, 
            offset, 
            filter: filter && JSON.stringify(filter),  
            sort: sort && JSON.stringify(sort), 
            columns: columns && JSON.stringify(columns),
            ...others 
        };
        if(this.options && this.options.params) params = { ...params, ...this.options.params };

        const url = this.basePath + makeQuery(params);
        return this.request(url, Axios.get);
    }

    findById(id) {
        const url = this.basePath + "/" + idOf(id);
        return this.request(url, Axios.get);
    }

    save(data) {
        const url = this.basePath;
        return this.request(url, Axios.post, data);
    }

    update(data) {
        const url = this.basePath;
        return this.request(url, Axios.put, data);
    }

    delete(id) {
        const url = this.basePath + "/" + idOf(id);
        return this.request(url, Axios.delete);
    }

}

export default Service;
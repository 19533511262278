//Rotas do React
//Use esse arquivo para declarar as paginas
import LogoutPage from './pages/LogoutPage';

import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';

import IndexPage from './pages/IndexPage';
import LoadingPage from './pages/LoadingPage';
import CategoriaPublicacaoPage from './pages/private/CategoriaPublicacaoPage';
import CategoriaPublicacaoTipoPage from './pages/private/CategoriaPulbicacaoTipoPage';
import Configuracao_tcepe_liconPage from './pages/private/Configuracao_tcepe_liconPage';
import ConsignadoEmpregadorConfiguracao from './pages/private/ConsignadoEmpregadorConfiguracao';
import ConsignadoRubricaConfiguracao from './pages/private/ConsignadoRubricaConfiguracao';
import DeclaracaoInexistenciaPage from './pages/private/DeclaracaoInexistenciaPage';
import EntidadePage from './pages/private/EntidadePage';
import EstruturaOrganizacionalGestaoPage from './pages/private/EstruturaOrganizacionalGestaoPage';
import EstruturaOrganizacionalPage from './pages/private/EstruturaOrganizacionalPage';
import FaqPage from './pages/private/FaqPage';
import Grupo_Categoria_PublicacaoPage from './pages/private/Grupo_Categoria_PublicacaoPage';
import InscricaoPage from './pages/private/InscricaoPage';
import LayoutPage from './pages/private/LayoutPage';
import LicitacaoPrivatePage from './pages/private/LicitacaoPage';
import MatrizAvaliacaoGrupoPage from './pages/private/MatrizGrupoPage';
import MatrizAvaliacaoItemPage from './pages/private/MatrizItemPage';
import ModeloCategoriaPublicacaoTipoPage from './pages/private/ModeloCategoriaPulbicacaoTipoPage';
import ModeloPortalPage from './pages/private/ModeloPortalPage';
import Modelo_Categoria_PublicacaoPage from './pages/private/Modelo_Categoria_PublicacaoPage';
import Modelo_Grupo_Categoria_PublicacaoPage from './pages/private/Modelo_Grupo_Categoria_PublicacaoPage';
import PortalPage from './pages/private/PortalPage';
import PublicacaoPage from './pages/private/PublicacaoPage';
import ServicoPage from './pages/private/ServicoPage';
import ContratoArquivoPage from './pages/public/ContratoArquivoPae';
import ContratoPage from './pages/public/ContratoPage';
import ConvenioPage from './pages/public/ConvenioPage';
import LicitacaoArquivoPage from './pages/public/LicitacaoArquivoPage';
import LicitacaoPage from './pages/public/LicitacaoPage';

export const routes = [
  {
    path: '/gestao',
    exact: true,
    component: IndexPage,
    isPrivate: true,
    requiresContext: true,
  },
  { path: '/logout', component: LogoutPage },
  { path: '/gestao/loading', component: LoadingPage },

  { path: '/gestao/help/about', component: AboutPage, allowOffline: true },
  {
    path: '/gestao/help/instructions',
    component: HelpPage,
    allowOffline: true,
  },

  {
    path: '/gestao/transparencia/entidade',
    component: EntidadePage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/configuracao_tcepe_licon',
    component: Configuracao_tcepe_liconPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/categoria_publicacao',
    component: CategoriaPublicacaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/categoria_publicacao_tipo',
    component: CategoriaPublicacaoTipoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/grupo_categoria_publicacao',
    component: Grupo_Categoria_PublicacaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/public/transparencia/licitacao',
    component: LicitacaoPage,
    isPrivate: false,
    requiresContext: false,
  },
  {
    path: '/gestao/public/transparencia/licitacao-arquivo',
    component: LicitacaoArquivoPage,
    isPrivate: false,
    requiresContext: false,
  },
  {
    path: '/gestao/public/transparencia/contrato',
    component: ContratoPage,
    isPrivate: false,
    requiresContext: false,
  },
  {
    path: '/gestao/public/transparencia/contrato-arquivo',
    component: ContratoArquivoPage,
    isPrivate: false,
    requiresContext: false,
  },
  {
    path: '/gestao/public/transparencia/convenio',
    component: ConvenioPage,
    isPrivate: false,
    requiresContext: false,
  },
  {
    path: '/gestao/transparencia/portal',
    component: PortalPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/licitacao',
    component: LicitacaoPrivatePage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/modelo/portal',
    component: ModeloPortalPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/modelo/grupo_categoria_publicacao',
    component: Modelo_Grupo_Categoria_PublicacaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/modelo/categoria_publicacao',
    component: Modelo_Categoria_PublicacaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/modelo/categoria_publicacao_tipo',
    component: ModeloCategoriaPublicacaoTipoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/publicacao',
    component: PublicacaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/inscricao',
    component: InscricaoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/matriz_item',
    component: MatrizAvaliacaoItemPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/matriz_grupo',
    component: MatrizAvaliacaoGrupoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/layout',
    component: LayoutPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/faq',
    component: FaqPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/declaracao-inexistencia',
    component: DeclaracaoInexistenciaPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/servico',
    component: ServicoPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/estrutura-organizacional',
    component: EstruturaOrganizacionalPage,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/transparencia/estrutura-organizacional-gestao',
    component: EstruturaOrganizacionalGestaoPage,
    isPrivate: true,
    requiresContext: true,
  },

  {
    path: '/gestao/rh/consignado_empregador_configuracao',
    component: ConsignadoEmpregadorConfiguracao,
    isPrivate: true,
    requiresContext: true,
  },
  {
    path: '/gestao/rh/consignado_rubrica_configuracao',
    component: ConsignadoRubricaConfiguracao,
    isPrivate: true,
    requiresContext: true,
  },
];

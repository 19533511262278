import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Media } from 'react-bootstrap';

import AsyncOutput from './AsyncOutput';

import config from '../config';

const mapStateToProperties = store => ({
    context: store.sessionState.context,
    loading: store.sessionState.loading
});

class ContextCard extends Component {
    render () {
        const { context, loading, ...rest } = this.props;
        
        return (
            <Card {...rest}>
                <Card.Body>
                    <Media>
                        { <img height={80} width={80} className="border rounded-circle" src={`${config.api_path}/logo`} alt="" /> }
                        <Media.Body className="p-2">
                            <AsyncOutput variant={Card.Title} loading={!context} className="mb-1">
                                {context && context.client.nome} {(context && context.exercicio) && <> - {context.exercicio}</>}
                            </AsyncOutput>
                            <AsyncOutput variant="small" loading={!context} style={{display: "block"}}>
                                {context && context.client.cnpj}
                            </AsyncOutput>
                        </Media.Body>
                    </Media>
                </Card.Body>
            </Card>
        );
    }
}

export default connect(mapStateToProperties)(ContextCard);
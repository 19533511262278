import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useState } from 'react';
import { Button, Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import Switch from '../../../components/Input/Switch';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'Nome da URL', property: 'nomeUrl', filter: true },
  { header: 'Ordem de exibição', property: 'ordemExibicao', filter: true },
];

export default function Grupo_Categoria_PublicacaoPage() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  async function descer() {
    setLoading(true);
    await Axios.post(
      '/v1/transparencia/grupo_categoria_publicacao/trocar-ordem?direcao=descer',
      selected
    );
    setLoading(false);
  }
  async function subir() {
    setLoading(true);
    await Axios.post(
      '/v1/transparencia/grupo_categoria_publicacao/trocar-ordem?direcao=subir',
      selected
    );
    setLoading(false);
  }

  return (
    <Crud
      contextMenu={[
        {
          buttons: [
            { icon: 'arrow-up', name: 'Subir', onClick: subir },
            { icon: 'arrow-down', name: 'Descer', onClick: descer },
          ],
        },
      ]}
      onSelect={setSelected}
      header='Grupos de Categoria Publicação'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/grupo_categoria_publicacao`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <Row>
            <Button onClick={() => subir(selected)}>
              <FontAwesomeIcon icon={faArrowUp} />
              Subir
            </Button>
            <Col>
              <Button onClick={() => descer(selected)}>
                <FontAwesomeIcon icon={faArrowDown} />
                Descer
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nome'
                    value={values.nome}
                    onChange={handleChange}
                    isInvalid={errors.nome}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome da URL</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nomeUrl'
                    value={values.nomeUrl}
                    onChange={handleChange}
                    isInvalid={errors.nomeUrl}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {errors.nomeUrl}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col>
              <Switch
                id='navbarPublicacao'
                label={'Listado na navbar'}
                value={values.navbarPublicacao}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

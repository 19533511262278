import { extractValue } from './components/utils/formatador';

export const buildMenuTree = (isAuthenticated, context, user) => {
    let menuTree = [];

    if(isAuthenticated) {
        menuTree = [
            ...menuTree, 
            { id: "home", name: "Inicio", to: "/", icon: "home"}
        ];

        if(context && context.menus) 
            menuTree = [...menuTree, ...getChildren(null, context.menus, context, user)];       
    } else {
        menuTree = [
            ...menuTree, 
            { id: "home", name: "Inicio", to: "/", icon: "home" }
        ];
    }

    return menuTree;
}

const supplant = function (str, o) {
    return str.replace(/\${([^${}]*)}/g,
        (a, b) => {
            var r = extractValue(o, b);
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
};

const getChildren = (parent, managedMenus, context, user) => {  
    const myChilds = (managedMenu) => (managedMenu.menuPai && managedMenu.menuPai.id && managedMenu.menuPai.id === parent.id)
    const noOnesChilds = (managedMenu) => (!managedMenu.menuPai || !managedMenu.menuPai.id)
    
    let children = managedMenus.filter(parent === null ? noOnesChilds : myChilds)
        .map((managedMenu) => {
            let icon = managedMenu.icone;
            if(icon && icon.indexOf(',') >= 0) icon = icon.split(',');
            let child = {
                id: managedMenu.id,
                name: supplant(managedMenu.nome, {context, user}),
                seq: managedMenu.seq || 1,
                icon,
            };
        
            if(managedMenu.acaoSistema && managedMenu.acaoSistema.solicitacao) {
                
                child = {
                    ...child,
                    to: `/${managedMenu.acaoSistema.solicitacao}`
                }
            } else {
                child.items = getChildren(child, managedMenus, context, user);
            }

            return child;
    });

    return children;
}
import React from 'react';
import { Component, Fragment } from 'react';
import { Overlay, Popover, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SimpleContextMenu extends Component {
    render () {
        const { groups, show, target, placement } = this.props;

        return (
            <Overlay show={show} target={target} placement={placement}>
                <Popover>
                    <Popover.Content className="d-flex align-content-center context-menu-container p-1 px-sm-0 py-sm-1">
                        <div>
                            {
                                groups.map((group, i) => (
                                    <Fragment key={i}>
                                        { i > 0 && <Dropdown.Divider />}
                                        {group.header && <Dropdown.Header>{group.header}</Dropdown.Header>}
                                        {
                                            group.buttons.map((btn, j) => {
                                                const { icon, name, as : Component, ...rest } = btn;
                                                if(Component) return (
                                                    <Component key={j} className="dropdown-item text-left" {...rest}>
                                                        <FontAwesomeIcon icon={icon}/> {name}
                                                    </Component>
                                                )

                                                return (
                                                    <Dropdown.Item key={j} className="text-left" {...rest}>
                                                        <FontAwesomeIcon icon={icon}/> {name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Fragment>
                                ))
                            }
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        );
    }
}

export default SimpleContextMenu;
import React, { Fragment } from 'react';
import { Col, Form, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import Crud from "../../../components/Crud"
import Input from "../../../components/Input"
import config from '../../../config';
import Switch from '../../../components/Input/Switch';
import schema from "./schema"



const columns = [
    { header: "Nome do cliente", property: "clienteGlobal.nome", filter: true },
    { header: "UJ", property: "liconUj", filter: true },
    { header: "Diretório", property: "diretorio", filter: true },
    { header: "Ativo", property: "ativo", filter: true, format: (val) => val ? "SIM" : "NÃO" },
    { header: "URL da licitação", property: "liconUrlLicitacao", filter: true},
    { header: "URL do contrato", property: "liconUrlContrato", filter: true },
]

export default function Configuracao_tcepe_liconPage() {
    return (
        <Crud
            header="Configuração TCEPE - Licon"
            columns={columns}
            path={`${config.api_path}/v1/transparencia/cliente`}
            rows={20}

            schema={schema}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>

                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Cliente</FormLabel>
                                <Input.AsyncSelect id="clienteGlobal" value={values.clienteGlobal} onChange={handleChange}
                                    isInvalid={errors.clienteGlobal}
                                    path={`${config.api_path}/v1/transparencia/clienteglobal`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione um Cliente"
                                />
                                <Form.Control.Feedback type="invalid">{errors.clienteGlobal}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>URL de Licitação no Licon</FormLabel>
                                <InputGroup>
                                    <Input.Text id="liconUrlLicitacao" value={values.liconUrlLicitacao} onChange={handleChange} isInvalid={errors.liconUrlLicitacao} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.liconUrlLicitacao}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormLabel>URL de Contrato no Licon</FormLabel>
                            <Form.Group>
                                <InputGroup>
                                    <Input.Text id="liconUrlContrato" value={values.liconUrlContrato} onChange={handleChange} isInvalid={errors.liconUrlContrato} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.liconUrlContrato}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <FormLabel>Nome da UJ na Pesquisa do Licon</FormLabel>
                            <Form.Group>
                                <InputGroup>
                                    <Input.Text id="liconUj" value={values.liconUj} onChange={handleChange} isInvalid={errors.liconUj} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.liconUj}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormLabel>Nome do Diretorio para Gravação do Arquivo</FormLabel>
                            <Form.Group>
                                <InputGroup>
                                    <Input.Text id="diretorio" value={values.diretorio} onChange={handleChange} isInvalid={errors.diretorio} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.diretorio}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Switch id="ativo" label={"Sincronização Ativa"} value={values.ativo} onChange={handleChange} />
                        </Col>
                    </Row>



                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>
            )}
        >

        </Crud>
    )
}
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

import Integer from './Integer';
import Decimal from './Decimal';
import IDate from './Date';
import Mask from './Mask'
import Select from './Select';
import AsyncSelect from './AsyncSelect';
import Time from './Time';
import File from './File';
import Checkbox from './Checkbox';
import Switch from './Switch';
import Radio from './Radio';
import SelectMany from './SelectMany';

class Input extends Component {
    render () {
        return (
            <Form.Control {...this.props} />
        );
    }
}

Input.Text = Input;
Input.Integer = Integer;
Input.Decimal = Decimal;
Input.Date = IDate;
Input.Time = Time;
Input.Mask = Mask;
Input.Select = Select;
Input.AsyncSelect = AsyncSelect;
Input.File = File;
Input.Checkbox = Checkbox;
Input.Switch = Switch;
Input.Radio = Radio;
Input.SelectMany = SelectMany;

export default Input;
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import Input from '../../../../components/Input';
import config from '../../../../config';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import NavbarPortal from '../Navbar/navbar';
import './header.scss';

export default function HeaderPortal() {
  const { portal, grupoComCategoria, portalCliente } = useCliente();
  const [searchInput, setSearchInput] = useState('');

  const currentlyLocation = useLocation();
  const [location, setLocation] = useState();

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
  }, [currentlyLocation]);
  return (
    portal &&
    grupoComCategoria &&
    portalCliente && (
      <Fragment>
        <Container fluid className='p-0 top-bar m-0' id='topo'>
          <Container>
            <Row>
              {/* <Row className='d-flex justify-content-between'> */}
              <div className='top-bar-card'>
                <Image className='card-img-top' src={`${config.api_path}/public/logo/${portal.id}`} width={'100%'} />
              </div>
              <div className='left-shadow'></div>
              <Col className='portal-label'>
                <Row className='mt-3'>
                  <h1>
                    Portal da <br /> Transparência
                  </h1>
                </Row>
                {portalCliente.exibeNomePortal ? (
                  <Row>
                    <h5>{portalCliente.topNomePortal ? portalCliente.topNomePortal : ''}</h5>
                  </Row>
                ) : (
                  ''
                )}
              </Col>
              <Col className='pesquisa col-md-3 col-7 mb-md-0 mb-4 mt-md-0 mt-4'>
                <Input onChange={(e) => setSearchInput(e.target.value)} placeholder={'Buscar no portal'} />
                <Button as={NavLink} to={`${location}/busca/${searchInput.toLocaleLowerCase()}`}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </Col>
              {/* </Row> */}
            </Row>
          </Container>
        </Container>
        <NavbarPortal grupoComCategoria={grupoComCategoria} />
      </Fragment>
    )
  );
}

import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório.'),
  email: Yup.string()
    .email('Email precisa ser válido')
    .required('Campo obrigatório.'),
  cpf: Yup.string().required('Campo obrigatório.'),
  celular: Yup.string().required('Campo obrigatório.'),
  cep: Yup.string().required('Campo obrigatório.'),
  endereco: Yup.string().required('Campo obrigatório.'),
  bairro: Yup.string().required('Campo obrigatório.'),
  municipio: Yup.object().required('Campo obrigatório.'),
});

export default schema;

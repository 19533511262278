export const extractValue = (object, property, separator = ' - ') => {
    var value = null;    
    if(typeof property === 'string'){
        var ppts = property.split('.');        
        var result = object;
        for(var i = 0; i < ppts.length; i++) {
            result = result[ppts[i]];
            if(result === undefined || result === null) return null;
        }
        value = result;
    } else {      
        value = property.reduce((value, ppt) => value ? `${value}${separator}${extractValue(object, ppt)}` : extractValue(object, ppt), value);
    }

    return value;
}

export const monetario = (val,precision=2) => {
    if(val === null) return;

    val = val + "";
    if(val === "") return "";

    var factor = Math.pow(10, precision);
    var tempNumber = val * factor;
    var roundedTempNumber = Math.round(tempNumber);
    var number = roundedTempNumber / factor;
        
    return numberToMonetario(number.toString());
}

export const numberToMonetario = (val) => {

    var signal = "";
    if(val.startsWith('-')) signal = '-';

    var decimal = '';
    var comma = val.indexOf('.');
    if(comma >= 0){
        decimal = val.substring(comma).replace(/\D/g, '');
        val = val.substring(0, comma).replace(/\D/g, '');;
    }
    while(decimal.length < 2) decimal += '0';
        
    val = val.replace(/\D/g, ''); //remover não digitos
    val = val.replace(/(^0{1,})/g, ''); //remover zero a esquerda
    if(val.length === 0) val += '0'; //garantir zero antes da virgula
    while(val.match(/^\d{4}/) != null){ //enquanto tem quatro digitos seguidos sem nenhum ponto ou virgula
        val = val.replace(/^(\d+)(\d{3})/, '$1.$2'); //colcar um ponto entre o primeiro e os tres numeros seguintes
    }
        
    return signal + val + "," + decimal;

}

export const longToDate = (longValue) => {
    if(longValue === null) return null;

    var date = new Date(longValue);
    return date;
}

export const longToStringDate = (longValue) => {
    if(longValue === null || longValue === "" || typeof longValue === 'undefined') return '';

    var date = new Date(longValue);
   
    return dateToString(date)
}

export const dateToString = (date) => {
    if(!date) return "";
    if(typeof date === "number" || typeof date === "string") date = new Date(date);
    return date.toLocaleDateString('pt-br');
}

export const timeToString = (date) => {
    if(!date) return "";
    if(typeof date === "number" || typeof date === "string") date = new Date(date);

    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();  

    return  hours + ":" + minutes;
}


//Retorna Null se a data não estiver consistente ou completa
export const stringToDate = (stringDate) => {
    var dateString = stringDate;

    var dateParts = dateString.split("/");

    if(dateParts && dateParts.length < 3) return null
    if(dateParts[0].length < 2 || isNaN(dateParts[0])) return null
    if(dateParts[1].length < 2 || isNaN(dateParts[1])) return null
    if(dateParts[2].length < 4 || isNaN(dateParts[2])) return null

    //  new Date construtor ([yyyy], [mm], [dd]). OBS: mes em js começa a partir de 0, logo (mes - 1) 
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], 12, 0, 0);
    return dateObject
}

export const stringToTime = (stringTime) => {
    var timeString = stringTime;

    var timeParts = timeString.split(":");

    if(timeParts && timeParts.length < 2) return null
    if(timeParts[0].length < 2 || isNaN(timeParts[0])) return null
    if(timeParts[1].length < 2 || isNaN(timeParts[1])) return null
  
    var timeObject = new Date(null, null, null, timeParts[0], timeParts[1])

    return timeObject
}

export const addMonths = (date, months) => { 
    if(!date) return null;

    var d = date.getDate();
    var result = new Date(date.getTime());
    result.setMonth(date.getMonth() + +months);
    if (result.getDate() !== d) {
     result.setDate(0);
    }
    return result;
}

export const addMonthsToLastMonthDay = (date, months) => { 
    if(!date) return null;

    var d = date.getDate();
    var result = new Date(date.getFullYear(), date.getMonth()+1 + +months, 0);

    return result;
}

export const booleanToString = (boolean) => {   
    if(boolean === null || boolean === 'undefined') return ''
    if(boolean === false) return 'Não'
    if(boolean === true) return 'Sim'
}

export const supplant = function (str, o) {
    return str.replace(/\${([^${}]*)}/g,
        (a, b) => {
            var r = extractValue(o, b);
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
};

export const removeAccents = (str) => str.replace(/[àáãäâ]/g, 'a').replace(/[ÀÁÃÄÂ]/g, 'A')
                                        .replace(/[èéëê]/g, 'e').replace(/[ÈÉËÊ]/g, 'E')
                                        .replace(/[ìíïî]/g, 'i').replace(/[ÌÍÏÎ]/g, 'I')
                                        .replace(/[òóöô]/g, 'o').replace(/[ÒÓÖÔ]/g, 'O')
                                        .replace(/[ùúü]/g, 'u').replace(/[ÙÚÜ]/g, 'U')
                                        .replace(/[ç]/g, 'c').replace(/[Ç]/g, 'C');

export const replaceAccents = (str, replace = '_') => str.replace(/[àáãäâèéëêìíïîòóöôùúüç]/gi, replace);
import React from 'react';
import { Component } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import share from '../components/InstallPromotion/ios-share.svg'
import {withRouter} from 'react-router';

class HelpPage extends Component {

    componentWillMount() {
        
    }

    render () {
        const { location } = this.props;
        return (
            <Container className="py-4">
                <Accordion defaultActiveKey={location.pathname.split('/')[2]}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="first-access">
                            Primeiro Acesso
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="first-access">
                            <Card.Body>
                                <p>
                                    Se você ainda não tem sua senha de acesso clique 
                                    na opção "Primeiro Acesso".
                                </p>
                                <p>
                                    <strong>Na primeira etapa</strong>, será solicitado o seu local de trabalho
                                    atual (se você tem ou teve vinculos com outras entidades,
                                    esses também serão relacionados à sua senha).
                                </p>
                                <p>
                                    <strong>Na segunda etapa</strong>, será solicitado o seu CPF.
                                </p>
                                <p className="alert alert-warning">
                                    <strong>Atenção: </strong> Em casos de re-cadastramento 
                                    alguns CPFs podem ter vindo invalidos da base de dados anterior, 
                                    se você tem um vinculo antigo e o seu CPF não foi encontrado, 
                                    dirija-se ao local de recadastramento designado pelo seu 
                                    municipio, com seus documentos em mãos, para possam corrigir o 
                                    seu cadastro.
                                </p>
                                <p>
                                    <strong>Na terceira etapa</strong>, você deverá confirmar se os seus 
                                    dados estão corretos. Caso você não reconheça os dados apresentados
                                    na tela, clique em "Não" e procure imediatamente o setor de RH para 
                                    correção dos seus dados.
                                </p>
                                <p>
                                    <strong>Na quarta etapa</strong>, você realizará o seu cadastro,
                                    informe seu email e crie a sua senha. Lembre-se que para fazer o login 
                                    você utilizará seu CPF e senha.
                                </p>
                                <p className="text-center">
                                    <strong><span role="img" aria-label="">🎉🎊</span> Tudo Pronto! <span role="img" aria-label="">🎊🎉</span></strong>
                                </p>
                                <p>
                                    Clique no botão "Entrar" para fazer o seu primeiro acesso.
                                    
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="how-to-install">
                            Como instalar o aplicativo
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="how-to-install">
                            <Card.Body>
                                <Card.Title>Instale em um dispositivo iOS</Card.Title>
                                <p className="ml-4">
                                    Toque em <img width="20" src={share} alt=""></img> e depois na opção "Tela de Início"
                                </p>
                                <Card.Title>Instale em um dispositivo Android ou Desktop</Card.Title>
                                <p className="ml-4">
                                    Assim que acessar o sistema, você receberá uma mensagem de instalação, basta clicar nela e confirmar a instalação.
                                </p>
                                <p className="ml-4">
                                    Se você não receber essa mensagem, pode ser que seu navegador não suporte a instalação
                                    de um Aplicativo Web, tente utilizar outro navegador compativel.
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Container>
        );
    }
}

export default withRouter(HelpPage);
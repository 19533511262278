import React, { Component, Fragment, createRef } from 'react'
import './Dropzone.css'
import PropTypes from 'prop-types';
import Progress from './Progress';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { withAlert } from 'react-alert';
import { handleAxiosError } from '../utils/handlers';
 
class Dropzone extends Component {

	static propTypes = {
		id: PropTypes.string.isRequired,
		route: PropTypes.string.isRequired,
		message: PropTypes.string,
        onChange: PropTypes.func,
    }

	constructor(props) {
		super(props);
		this.fileInputRef = createRef()
    
		this.state = {
			message: typeof props.message === 'undefined' ? 'Clique aqui ou arraste um arquivo' : props.message, 
			hightlight: false,
			files: [],
			uploadProgress: {},
			uploading: false,
		};

    	this.uploadFiles = this.uploadFiles.bind(this);

	}

	async sendRequest(file) {

		let url = this.props.route;
		
		const formData = new FormData();		
		formData.append("file", file);

		const serviceInstance = axios.create({
            url: url, 
            timeout: 10000000,
            headers: {
                'Content-Type': 'application/json'
			},
			
			onUploadProgress: (e) => {
				const copy = { ...this.state.uploadProgress };
				copy[file.name] = {
					state: "pending",
					percentage: (e.loaded / e.total) * 100
				};
				this.setState({
					...this.state, 
					uploading: true,
					uploadProgress: copy
				});
			}
        });

		return serviceInstance.post(url,formData);
	}

	openFileDialog() {
		this.fileInputRef.current.click();
	}
	
	onDragOver(evt) {
		evt.preventDefault();  
		this.setState({ 
			...this.state,
			hightlight: true 
		});
	}

	onDragLeave() {
		this.setState({ 
			...this.state,
			hightlight: false 
		});
	}

	onDrop(event) {
		event.preventDefault();	

		this.setState({ 
			...this.state,
			hightlight: false,
			files: [event.dataTransfer.files.item(0)],
			uploadProgress: [{state: "pending", percentage: 2}],
		});

	}

	onFilesAdd(event) {
		event.preventDefault();
		const files = this.state.files;    
		let copy = { ...this.state.uploadProgress };
		
		for (var i = 0; i < event.target.files.length; i++) {
			let match = false;
			for (var j = 0; j < files.length; j++) {				
				if(files[j].name ===  event.target.files.item(i).name){
					match = true;
				}
			}
			if(match === false){
				files.push(event.target.files.item(i));
				copy[event.target.files.item(i).name] = {
					state: "pending",
					percentage: 2
				};
			}			
		}		

		this.setState({ 
			...this.state,
			hightlight: false,
			files: files,
			uploadProgress: copy,
		});

	}

	removeFile(file){

		if(!this.state.uploading){
			let newFiles =this.state.files;
			const index = newFiles.indexOf(file);			
			if(index > -1){
				newFiles.splice(index,1);

				this.setState({
					...this.state,
					files: newFiles,
				});
				
			}
		}
	}

	async uploadFiles() {
		const { onChange, onUpload, alert } = this.props;
		this.setState({ 
			...this.state,
			uploadProgress: {}, 
			uploading: true }
		);

		try {
			for(let file of this.state.files) {
				const res = await this.sendRequest(file);
				if(onChange){
					onChange({ 
						target: { 
							name: this.props.id, 
							value: file.name
						} 
					});
				}
				if(onUpload) onUpload(res, file);
			}
			this.setState({
				...this.state,
				files: [],
				uploading: false
			})
		} catch(err) {
			console.trace(err);
			handleAxiosError(alert)(err);
		}
	}

	renderFiles(){
    	const div = this.state.files.map(file => {
        	return(
				<div key={file.name} className="Row">
					<div>
						<Button variant="link" style={{display: this.state.uploading === true ? 'none' : 'contents'}}
							 onClick={() => this.removeFile(file)}>
							<i className="far fa-times-circle fa-sm"></i>
						</Button>
						<span className="Filename">{file.name}</span>
					</div>
					{this.renderProgress(file)}
				</div>
			);
      }
    );
    return div;
  }

  	renderProgress(file) {
		const uploadProgress = this.state.uploadProgress[file.name];		

		if(typeof uploadProgress != 'undefined'){
			return (
				<div>
					<Progress progress={uploadProgress.percentage}></Progress>					
				</div>				
			);	
		}
			
	}

  render() {
	const { disabled } = this.props
    return (
        <Fragment>						
          	<div className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
                onDragOver={(e) => this.onDragOver(e)}
                onDragLeave={(e) => this.onDragLeave(e)}
				onDrop={(e) => this.onDrop(e)}
				onClick={(e) => this.openFileDialog(e)}
              >            
            	<i className="Icon fas fa-cloud-download-alt fa-2x"></i>
				<input ref={this.fileInputRef} 
					onChange={(e) => this.onFilesAdd(e)} 
					className="FileInput" type="file"/>          
				<span className="Message">{this.state.message}</span>

            </div>

            <div id="file-div" className="Files">{this.renderFiles()}</div>

            <div style={{textAlign: 'center', paddingTop: 10}}>
            	<button type="button" className="btn btn-primary" 
                	disabled={this.state.files.length < 1 || disabled} 
                	onClick={() => this.uploadFiles()}>
                	Enviar
              </button>
            </div>

          </Fragment>
      );  
    }    
}

export default  withAlert()(Dropzone);
import React, { Component } from 'react';

class File extends Component {
    render () {
        const { value, ...rest } = this.props
        return (
            <div className="custom-file">
                <input type="file" className="custom-file-input" id="customFile" {...rest} />
                <label className="custom-file-label" htmlFor="customFile">
                    {value || "Escolher Arquivo"}
                </label>
            </div>
        );
    }
}

export default File
import React, { Component, Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cropper from './Cropper'

class Avatar extends Component {

    constructor(props){
        super(props);

        this.state = {
            uri: null,
            croppedUri: props.uri,   
            showCropper: false,  
            filename: null,   
            error: false,    
        }

        this.onCropImage = this.onCropImage.bind(this);
        this.setImage = this.setImage.bind(this);
    }

    componentDidMount() {
        const { ref } = this.props;
        if(ref) ref({
            setImage: this.setImage
        })
    }

    openFileDialog() {
        if(this.fileInput) this.fileInput.click();
	}

    setImage(dataURI, filename) {
        this.setState({ 
            ...this.state,
            uri: dataURI,
            showCropper: true,
            filename: filename,
        })
    }

    onSelectFile(e){
        const files = e.target.files;
        const filename = e.target.value;

        if (files && files.length > 0) {
            const file = files[0]
            const reader = new FileReader();
            const self = this;

            reader.addEventListener("load", () => { 
                self.setState({ 
                    ...self.state,
                    uri: reader.result,
                    showCropper: true,
                    filename: filename,
                })
            });

            reader.readAsDataURL(file);
            e.target.value = ''
        }
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
      
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
      
        // create a view into the buffer
        var ia = new Uint8Array(ab);
      
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
      
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
      
    }

    onCropImage(croppedUri, blob){
        const { onChange } = this.props;
        const { filename } = this.state;

        if(blob){
            this.setState({
                ...this.state,
                showCropper: false,
                croppedUri: croppedUri,
                error: false,
            }, () => {
                if(onChange) onChange({
                    blob: blob,
                    filename: filename,
                });
            });
        } else{
            this.setState({
                ...this.state,
                showCropper: false,
            });
        }
        
    }

    render () {
        const { height = 100, aspectRatio = 1, square = false, free = false, resize = false, icon = "camera", uploadIcon = "upload", uploadMessage = "Alterar Imagem", editable = true } = this.props; 
        const { croppedUri, uri, showCropper, error } = this.state;
        const width = height * aspectRatio;

        return (
            <Fragment>
                <div className={["avatar", square ? "avatar-square" : "avatar-circle"].join(' ')} style={{width: width, height: height}} onClick={(e) => this.openFileDialog(e)}>
                    { (croppedUri && !error) ?
                        <img src={croppedUri} alt="avatar" className="avatar-image" onError={() => this.setState({...this.state, error: true})}></img> 
                        :
                        <div className="avatar-icon" style={{fontSize: (width/2)}}><FontAwesomeIcon icon={icon} /></div>
                    }
                    {
                        editable &&
                        <Fragment>
                            <div className="avatar-overlay">
                                <div><FontAwesomeIcon icon={uploadIcon}/></div>
                                <div>{uploadMessage}</div>
                            </div>
                            <input ref={(ref) => this.fileInput = ref} className="avatar-input" onChange={(e) => this.onSelectFile(e)} 
                                type="file"  accept="image/x-png,image/png,image/gif,image/jpeg"/>
                        </Fragment>
                    }
                </div>   
                { editable && <Cropper uri={uri} resize={resize} onChange={this.onCropImage} free={free} aspectRatio={aspectRatio}  show={showCropper} circularCrop={!square} ></Cropper> }
            </Fragment>
        );
    }
}

export default Avatar;
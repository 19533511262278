import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FormLabel, ListGroup, Row } from 'react-bootstrap';
import QrcodePix from '../Qrcode/qrcode';
import { Inscricao } from '../utils';
import './styles.css';

export default function Validacao({ userCPF }) {
  const [isQrcode, setIsQrcode] = useState(false);
  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    await Inscricao(userCPF).then((res) => {
      if (res.data.data.length === 1) {
        setUserData(res.data.data[0]);
      }
    });
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isQrcode ? (
    <QrcodePix userId={userData.id} cpf={userData.cpf} userData={userData} />
  ) : (
    <>
      <Row>
        <Col>
          <Card className='info-card'>
            <Card.Body style={{ paddingLeft: 0 }}>
              {/* <ListGroup className='flush'> */}
              {/* <ListGroup.Item> */}
              <Col>
                <FormLabel>
                  <b>Evento</b> - {userData.evento ? userData.evento.nome : ''}
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Data: </b>{' '}
                  {userData.evento
                    ? format(
                        new Date(userData.evento.dataInicialRealizacao),
                        'dd/MM/yyyy'
                      )
                    : ''}
                </FormLabel>
              </Col>
              {/* </ListGroup.Item> */}
              {/* </ListGroup> */}
            </Card.Body>
          </Card>
          <Card className='info-card'>
            <Card.Body style={{ paddingLeft: 0 }}>
              {/* <ListGroup className='flush'> */}
              {/* <ListGroup.Item> */}
              <Col>
                <FormLabel>
                  <b>{userData.nome}</b> ({userData.cpf})
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Email: </b>
                  {userData.email}
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Celular: </b>
                  {userData.celular}
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Endereço: </b>
                  {userData.endereco} - {userData.bairro} -{' '}
                  {userData.municipio ? userData.municipio.nome : ''} -{' '}
                  {userData.municipio ? userData.municipio.uf : ''} - CEP{' '}
                  {userData.cep} - {userData.complementoEndereco}
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Insituição: </b>
                  {userData.instituicaoRepresentada}
                </FormLabel>
              </Col>
              <Col>
                <FormLabel>
                  <b>Status da inscrição: </b>
                  {userData.statusPagamento}
                </FormLabel>
              </Col>
              {/* </ListGroup.Item> */}
              {/* </ListGroup> */}
            </Card.Body>
          </Card>
          {userData.statusPagamento ===
          'Aguardando Envio do Comprovante de Pagamento' ? (
            <Row className='mt-3'>
              <Col md={3}>
                <Button onClick={() => setIsQrcode(!isQrcode)}>
                  Realizar pagamento
                </Button>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </>
  );
}

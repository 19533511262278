import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Service from '../Service';

/**
 * 
 * @param {Service} service qual service vai ser usado para listar os dados
 * @param {number} page numero atual da pagina
 * @param {number} limit limite de linhas por paginas
 * @param {*} filter
 * @param {*} sort
 * @param {string[]} columns array de colunas que serão filtradas
 */
function DataPagination({
  service,
  page = 1, // 1 based
  limit = 10, //limit of items per page
  filter, //data filter definition
  sort, //sorting order
  columns, //columns that will be projected
  children, //child/function component to be rendered

  params, // other parameters for lisitng
  forceUpdate = 0,
}, ref) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const lastPage = Math.ceil(count / limit);

  const refresh = useCallback(function() {
    if(!service) return;

    setLoading(true);
    service.list({ limit, offset: (page - 1) * limit, filter, sort, columns, params })
      .then(res => {
        setData(res.data.list || []);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  }, [service, limit, page, filter, sort, columns, params]);

  useEffect(() => {
    if(!service) return;
    let isValid = true;
    
    setLoading(true);
    service.list({ limit, offset: (page - 1) * limit, filter, sort, columns, params })
      .then(res => {
        if(isValid) {
          setData(res.data.list || []);
          setCount(res.data.count);
          setLoading(false);
        }
      })
      .catch(err => {
        if(isValid) {
          setError(err);
          setLoading(false);
        }
      });

    return () => {
      isValid = false;
    }

  }, [service, limit, page, filter, sort, columns, params]);

  useImperativeHandle(ref, () => ({
    refresh
  }))

  return children({
    data,
    count,
    error,
    loading,
    page,
    lastPage,
    limit,
    filter,
  });
}

export default React.forwardRef(DataPagination);
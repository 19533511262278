import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap';
//eslint-disable-next-line
import PersonCard from '../components/PersonCard';
import ContextCard from '../components/ContextCard';

const mapStateToProperties = store => ({
    context: store.sessionState.context
});

const mapDispatchToProperties = dispatch => bindActionCreators({ }, dispatch);

class IndexPage extends Component {
    
    render () {
        //eslint-disable-next-line
        const { context } = this.props;

        return (
            <Container className="py-4">
                <ContextCard className="mb-4"/>
            </Container>
        );
    }
}

export default connect(mapStateToProperties, mapDispatchToProperties)(IndexPage);
import React, { Fragment } from "react";
import { Col, Form, FormLabel, InputGroup, Row } from "react-bootstrap";
import Crud from "../../../components/Crud";
import Input from "../../../components/Input";
import config from '../../../config';
import schema from "./schema";

const columns = [
    { header: "Id do empregador", property: "empregadorId", filter: true },
    { header: "Empregador codigo consignado", property: "empregadorCodigoConsignado", filter: true },
]

export default function ConsignadoEmpregadorConfiguracao() {

    return (
        <Crud
            header="Consignado Empregador Configuracao"
            columns={columns}
            path={`${config.api_path}/v1/rh/consignado_empregador_configuracao`}
            rows={20}
            schema={schema}
            form={({ state, handleChange, values, touched, isValid, errors }) => (
                <Fragment>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Empregador</FormLabel>
                                <Input.AsyncSelect id="cliente" value={values.empregadorId} onChange={handleChange} isInvalid={errors.empregadorId}
                                    path={`${config.api_path}/v1/transparencia/clienteglobal`}
                                    label={"${nome}"}
                                    rowKey="id" nullable
                                    placeholder="Selecione uma Enteidade"
                                />
                                <Form.Control.Feedback type="invalid">{errors.empregadorId}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group>
                                <FormLabel>Empregador código consignado</FormLabel>
                                <InputGroup>
                                    <Input.Text id="empregadorCodigoConsignado" value={values.empregadorCodigoConsignado} onChange={handleChange} isInvalid={errors.empregadorCodigoConsignado} />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.empregadorCodigoConsignado}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
                </Fragment>

            )}

        ></Crud>
    )
}
import React from 'react';
import { Component } from 'react';

class AsyncOutput extends Component {
    render () {
        const { children, loading, variant, className } = this.props;
        var El = variant || "div";

        const props = {
            ...this.props,
            loading: null,
            children: null,
            variant: null,
            className: ["async-output ", (loading ? "loading" : "visible"), className].join(' ')
        };

        return (
            <El { ...props }>
                { children ? children : <>&nbsp;</> }
            </El>
        );
    }
}

export default AsyncOutput;
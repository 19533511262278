import React, { useState } from 'react';
import { ButtonGroup, Card, Col, Collapse, Container, Form, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import Input from '../../../components/Input';
import Service from '../../../components/Systema/Service';
import config from '../../../config';

import { faFileCsv, faFileExcel, faFilePdf, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import DataPagination from '../../../components/Systema/Data/DataPagination';
import Paginator from '../../../components/Systema/Data/Paginator';

const service = new Service(`${config.api_path}/public/v1/transparencia/licitacao/arquivo`);
const serviceArquivo = new Service(`${config.api_path}/public/v1/transparencia/licitacao/arquivo`);

const sort = { dataArquivo: 'DESC' };

export default function LicitacaoArquivoPage() {
  const [visivel, setVisivel] = useState(false);
  const [page, setPage] = useState(1);
  const [dadosModal, setDadosModal] = useState({});

  const [filtroModalidade, setFiltroModalidade] = useState('');
  const [filtroDocumento, setFiltroDocumento] = useState('');
  const [filtroParticipantes, setFiltroParticipantes] = useState('');
  const [filtroVencedores, setFiltroVencedores] = useState('');
  const anoAtual = new Date().getFullYear();
  const [filtroDataInicial, setFiltroDataInicial] = useState(new Date(anoAtual - 2, 0, 1));
  const [filtroDataFinal, setFiltroDataFinal] = useState(new Date());
  const [licitacaoDataSincronizacao, setLicitacaoDataSincronizacao] = useState();
  const [ultimaLicitacao, setUltimaLicitacao] = useState();
  const [filterTable, setFilterTable] = useState({});
  const [filterTableSemData, setFilterTableSemData] = useState({});
  const [showFiltro, setShowFiltro] = useState();

  const opcoes = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [filtroRequest, setFiltroRequest] = useState({});

  let filter = { 'licitacao.id': { $eq: `long:${dadosModal.id}` } };

  const showLicitacao = (row) => {
    setDadosModal(row);
    setVisivel(true);
  };

  const hide = () => setVisivel(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: licitacaoSync } = await Axios.get(`${config.api_path}/public/v1/transparencia/cliente-tcepe/licitacao-data-sincronizacao/${id}`);
      const { data: dataUltimaLicitacao } = await Axios.get(`${config.api_path}/public/v1/transparencia/licitacao/arquivo/data-registro/${id}`);
      setLicitacaoDataSincronizacao(licitacaoSync.data);
      setUltimaLicitacao(dataUltimaLicitacao.data);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (filtroModalidade || filtroDocumento || filtroVencedores || filtroParticipantes || id || filtroDataInicial || filtroDataFinal) {
      if (Object.keys(filtroRequest).length === 0) {
        setFiltroRequest({
          // dataArquivo:
          //   filtroDataInicial || filtroDataFinal
          //     ? {
          //         $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
          //         $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
          //         $order: -1,
          //       }
          //     : undefined,
          'licitacao.clienteGlobal.identificador': id ? { $eq: id } : undefined,
        });
      }
      setFilterTable({
        modalidade: filtroModalidade ? { $ilike: `${filtroModalidade}` } : undefined,
        objeto: filtroDocumento ? { $ilike: `${filtroDocumento}` } : undefined,
        participantes: filtroParticipantes ? { $ilike: `${filtroParticipantes}` } : undefined,
        vencedores: filtroVencedores ? { $ilike: `${filtroVencedores}` } : undefined,
        dataArquivo:
          filtroDataInicial || filtroDataFinal
            ? {
                $ge: filtroDataInicial ? `date:${filtroDataInicial.getTime()}` : undefined,
                $le: filtroDataFinal ? `date:${filtroDataFinal.getTime()}` : undefined,
                $order: -1,
              }
            : undefined,
        'licitacao.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
      setFilterTableSemData({
        modalidade: filtroModalidade ? { $ilike: `${filtroModalidade}` } : undefined,
        objeto: filtroDocumento ? { $ilike: `${filtroDocumento}` } : undefined,
        participantes: filtroParticipantes ? { $ilike: `${filtroParticipantes}` } : undefined,
        vencedores: filtroVencedores ? { $ilike: `${filtroVencedores}` } : undefined,
        'licitacao.clienteGlobal.identificador': id ? { $eq: id } : undefined,
      });
    } else {
      setFilterTable({});
      setFilterTableSemData({});
    }
  }, [filtroModalidade, filtroDocumento, filtroVencedores, filtroParticipantes, filtroDataInicial, filtroDataFinal, id, filtroRequest]);

  return licitacaoDataSincronizacao && ultimaLicitacao ? (
    <Container fluid>
      <Card className='mt-4 mb-2'>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='font-weight-light my-0'>Arquivos Licitatórios</h3>
            </div>
            <span className='align-items-center' style={{ display: 'grid', gridTemplate: 'auto / auto auto', gap: '.4rem' }}>
              <small className='font-weight-bold'>Data da última Sincronização:</small>
              <small className='text-right'>{new Date(licitacaoDataSincronizacao).toLocaleDateString('pt-BR', opcoes)}</small>

              <small className='font-weight-bold'>Data do último Documento:</small>
              <small className='text-right'>{new Date(ultimaLicitacao).toLocaleDateString('pt-BR', opcoes)}</small>
            </span>
          </div>
        </Card.Body>
      </Card>

      <Card className='my-2'>
        <Card.Header className='py-2' onClick={() => setShowFiltro(!showFiltro)} style={{ cursor: 'pointer' }}>
          <div className='d-flex justify-content-between'>
            <div>
              <Card.Title className='my-0'>Filtros</Card.Title>
            </div>
            <div>
              <FontAwesomeIcon icon={showFiltro ? faMinus : faPlus} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={showFiltro}>
          <div>
            <Card.Body>
              <Row className='mb-3'>
                <Col>
                  <FormLabel>Data Inicial</FormLabel>
                  <Input.Date value={filtroDataInicial} type='date' onChange={(e) => setFiltroDataInicial(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Data Final</FormLabel>
                  <Input.Date value={filtroDataFinal} type='date' onChange={(e) => setFiltroDataFinal(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Modalidade da Licitação</FormLabel>
                  <Form.Control value={filtroModalidade} onChange={(e) => setFiltroModalidade(e.target.value)} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <FormLabel>Participantes</FormLabel>
                  <Form.Control value={filtroParticipantes} onChange={(e) => setFiltroParticipantes(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Vencedores</FormLabel>
                  <Form.Control value={filtroVencedores} onChange={(e) => setFiltroVencedores(e.target.value)} />
                </Col>
                <Col>
                  <FormLabel>Documentos</FormLabel>
                  <Form.Control value={filtroDocumento} onChange={(e) => setFiltroDocumento(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant='outline-primary' onClick={() => setFiltroRequest(filterTable)}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <div>
        <Modal show={visivel} onHide={hide} size='xl'>
          <Modal.Header style={{ backgroundColor: '#074E80', height: '50px' }} closeButton />
          <Modal.Body>
            <Row>
              <Col md={3}>
                <p>
                  <strong>Exercicio: </strong>
                  {dadosModal.exercicio ? dadosModal.exercicio : ''}
                </p>
              </Col>
              <Col md={3}>
                <p>
                  <strong>Processo: </strong>
                  {dadosModal.numeroProcesso ? dadosModal.numeroProcesso : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Valor Adjudicado: </strong>
                  {dadosModal.valorAdjudicado ? dadosModal.valorAdjudicado : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Unidade Gestora: </strong>
                  {dadosModal.clienteGlobal ? dadosModal.clienteGlobal.nome : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Modalidade da licitação: </strong>
                  {dadosModal.modalidade ? dadosModal.modalidade : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Objeto: </strong>
                  {dadosModal.objeto ? dadosModal.objeto : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Participantes: </strong>
                  {dadosModal.participantes ? dadosModal.participantes : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Vencedores: </strong>
                  {dadosModal.vencedores ? dadosModal.vencedores : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Número da Modalidade: </strong>
                  {dadosModal.numero ? dadosModal.numero : ''}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Situação: </strong>
                  {dadosModal.situacao ? dadosModal.situacao : ''}
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <DataPagination sort={sort} service={service} page={page} limit={14} filter={filtroRequest}>
          {({ data, page, lastPage, loading }) => (
            <Fragment>
              <Table className='table-bordered table-hover table-striped'>
                <thead>
                  <tr>
                    <th className='align-middle'>Exercício</th>
                    <th className='align-middle'>Unidade Gestora</th>
                    <th className='align-middle'>Data</th>
                    <th className='align-middle'>Modalidade</th>
                    <th className='align-middle'>Número da Modalidade</th>
                    <th className='align-middle'>Número do Processo</th>
                    <th className='align-middle'>Tipo</th>
                    <th className='align-middle'>Documento</th>
                    <th className='align-middle'>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => {
                    if (row.licitacao.clienteGlobal.identificador === id) {
                      return (
                        <tr>
                          <td>{row.licitacao.exercicio}</td>
                          <td>{row.licitacao.clienteGlobal.nome}</td>
                          <td>{new Date(row.dataArquivo).toLocaleDateString('pt-BR', opcoes)}</td>
                          <td>{row.licitacao.modalidade}</td>
                          <td>
                            <Button variant='link' onClick={() => showLicitacao(row.licitacao)}>
                              {row.licitacao.numero}
                              <FontAwesomeIcon className='ml-3' icon={faSearch} />
                            </Button>
                          </td>
                          <td>
                            <Button variant='link' onClick={() => showLicitacao(row.licitacao)}>
                              {row.licitacao.numeroProcesso}
                              <FontAwesomeIcon className='ml-3' icon={faSearch} />
                            </Button>
                          </td>
                          <td>{row.tipo.nome}</td>
                          <td>{row.nomeArquivo}</td>
                          <td>
                            <Button
                              variant='outline-primary'
                              target='_blank'
                              href={`https://transparencia.systemainformatica.com.br/entidade/licon/${id}/licitacoes/${row.licitacao.exercicio}/${row.nomeArquivo}`}
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> PDF
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                    return '';
                  })}
                </tbody>
              </Table>

              <Paginator page={page} lastPage={lastPage} setPage={setPage} loading={loading} />
            </Fragment>
          )}
        </DataPagination>
      </div>
      {/* <pre>{id}</pre> */}
    </Container>
  ) : (
    <Loading />
  );
}

import {
  faAdjust,
  faFont,
  faMap,
  faUniversalAccess,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useCliente } from '../../../../navigation/context/ClienteContext';
import '../styles.scss';
import './barraAcessibilidade.scss';
export default function BarraAcessibilidade() {
  const [show, setShow] = useState(false);

  const {
    setFontSizeBase,
    aumentarFonte,
    diminuirFonte,
    contraste,
    setContraste,
  } = useCliente();

  const goTomapa = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`acessibilidade ${show ? 'show' : ''}`}>
      <div className='acessibilidade-conteudo'>
        <ul>
          <p className='titulo-acessibilidade'>Barra de acessibilidade</p>
          <li>
            <Button variant='link' onClick={diminuirFonte}>
              <span>
                <FontAwesomeIcon icon={faFont} /> -
              </span>
              <p>Diminuir a fonte</p>
            </Button>
          </li>
          <li>
            <Button variant='link' onClick={() => setFontSizeBase(16)}>
              <span>
                <FontAwesomeIcon
                  icon={faFont}
                  style={{ justifySelf: 'start' }}
                />
              </span>
              <p>Fonte padrão</p>
            </Button>
          </li>
          <li>
            <Button variant='link' onClick={aumentarFonte}>
              <span>
                <FontAwesomeIcon icon={faFont} /> +
              </span>
              <p>Aumentar a fonte</p>
            </Button>
          </li>
          <li>
            <Button variant='link' onClick={() => setContraste(!contraste)}>
              <span>
                <FontAwesomeIcon icon={faAdjust} />
              </span>
              <p>Contraste</p>
            </Button>
          </li>
          <li>
            <Button variant='link' onClick={() => goTomapa('footer-portal')}>
              <span>
                <FontAwesomeIcon icon={faMap} />
              </span>
              <p>Mapa do site</p>
            </Button>
          </li>
        </ul>
      </div>
      <div className='acessibilidade-botao'>
        <Button onClick={() => setShow(!show)}>
          <FontAwesomeIcon icon={faUniversalAccess} size='2x' />
        </Button>
      </div>
    </div>
  );
}

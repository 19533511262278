import React from 'react';
import { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

const percentOptions = {
    style: "decimal", 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
}

const sizeOptions = {
    style: "decimal",
    minimumFractionDigits: 2, 
    maximumFractionDigits: 3
}

const displays = ["percent", "size" ];

const sizes = [
    { order: 1, name: "b" },
    { order: 1024, name: "Kb" },
    { order: 1024*1024, name: "Mb" },
    { order: 1024*1024*1024, name: "Gb" },
]

const getSizeLabel = (length) => {
    for(let size of sizes) {
        let converted = length/size.order;
        if(converted < 1024 && converted >= 1)
            return `${converted.toLocaleString('pt-br', sizeOptions)}${size.name}`;
    }
}

class DownloadProgress extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            display: 0
        }

        this.toggleDisplay = this.toggleDisplay.bind(this);
    }

    toggleDisplay() {
        this.setState({
            ...this.state,
            display: this.state.display >= displays.length ? 0 : this.state.display+1
        });
    }

    getLabel() {
        const { now, max } = this.props;
        switch(displays[this.state.display]) {
            case "size": 
                return ` ${getSizeLabel(now)} de ${getSizeLabel(max)} `;
            
            case "percent":
            default:
                return `${((now/max) * 100).toLocaleString("pt-br", percentOptions)} %`;
        }
    }

    render () {
        const { now, max, variant, animated } = this.props;

        return (
            <ProgressBar onClick={this.toggleDisplay}>
                <ProgressBar variant={variant} animated={animated} max={max} now={now}
                    label={max ? this.getLabel() : false }/>
            </ProgressBar>
        );
    }
}

export default DownloadProgress;

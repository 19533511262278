import React from 'react';
import { Component } from 'react';

class ScrollListener extends Component {
    
    componentDidMount() {
        this.elem.addEventListener('scroll', this.handleScrolling);
    }

    componentWillUnmount() {
        this.elem.removeEventListener('scroll', this.handleScrolling);
    }

    handleScrolling = (event) => {
        if(this.isBottom(event.target) && this.props.onScrollBottom)
            this.props.onScrollBottom();
    }

    isBottom(el) {
        return (el.scrollHeight - el.scrollTop === el.clientHeight);
    }

    render () {
        const { as : Element, ...rest } = this.props;
        
        delete rest.onScrollBottom;
        delete rest.onScrollTop;

        return (
            <Element ref={elem => this.elem = elem} {...rest} />
        );
    }
}

export default ScrollListener;
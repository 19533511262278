import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useCliente } from '../../../../navigation/context/ClienteContext';
import './footer.scss';

export default function Footer() {
  const currentlyLocation = useLocation();
  const [location, setLocation] = useState();
  const { portalCliente, grupoComCategoria, grupoCategoria } = useCliente();

  useEffect(() => {
    const partes = currentlyLocation.pathname.split('/');
    if (partes.length > 2) {
      setLocation('/' + partes[1]);
    } else {
      setLocation(currentlyLocation.pathname);
    }
  }, [currentlyLocation]);

  return (
    portalCliente &&
    grupoComCategoria && (
      <footer
        id='footer-portal'
        className={`footer p-0 m-0${portalCliente.footerBgColor ? '' : 'footer-bg-color'}`}
        style={{ backgroundColor: portalCliente.footerBgColor || '' }}
      >
        <Container className='mapa-do-site' style={{ color: portalCliente.footerFontColor || '' }}>
          <section className='container-top'>
            <h4 style={{ borderBottomColor: portalCliente.footerFontColor || '' }}>Mapa do Site</h4>
          </section>
          <Row className='container-down m-0'>
            {grupoComCategoria &&
              Object.keys(grupoComCategoria).map((grupoNome, index) => (
                <Col className='content-in' key={index}>
                  <h6>{grupoNome}</h6>

                  <ul>
                    {grupoComCategoria[grupoNome].map((eachCategeoria, index) => {
                      const nomeUrl = eachCategeoria.nomeUrl;
                      if (eachCategeoria.listado) {
                        return eachCategeoria.linkExterno ? (
                          <li key={index}>
                            <a
                              href={eachCategeoria.linkUrl}
                              style={{
                                color: portalCliente.footerFontColor || '',
                              }}
                              target='__blank'
                            >
                              {eachCategeoria.nome}
                            </a>
                          </li>
                        ) : (
                          <li key={index}>
                            <NavLink
                              style={{
                                color: portalCliente.footerFontColor || '',
                              }}
                              to={`${location}/detail/${grupoCategoria[grupoNome]}/${nomeUrl}`}
                            >
                              {eachCategeoria.nome}
                            </NavLink>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Col>
              ))}
          </Row>
        </Container>
      </footer>
    )
  );
}

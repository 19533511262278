import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useState } from 'react';
import { Button, Col, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Crud from '../../../components/Crud';
import Input from '../../../components/Input';
import config from '../../../config';
import schema from './schema';

const columns = [
  { header: 'Categoria da publicação', property: 'categoriaPublicacao.nome', filter: true },
  { header: 'Nome', property: 'nome', filter: true },
  { header: 'Nome no plural', property: 'nomePlural', filter: true },
  { header: 'Ordem exibição', property: 'ordemExibicao', filter: true },
];

export default function CategoriaPublicacaoTipoPage() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  async function descer() {
    setLoading(true);
    await Axios.post('/v1/transparencia/categoria_publicacao_tipo/trocar-ordem?direcao=descer', selected);
    setLoading(false);
  }
  async function subir() {
    setLoading(true);
    await Axios.post('/v1/transparencia/categoria_publicacao_tipo/trocar-ordem?direcao=subir', selected);
    setLoading(false);
  }

  return (
    <Crud
      contextMenu={[
        {
          buttons: [
            { icon: 'arrow-up', name: 'Subir', onClick: subir },
            { icon: 'arrow-down', name: 'Descer', onClick: descer },
          ],
        },
      ]}
      onSelect={setSelected}
      header='Tipo por Categoria'
      columns={columns}
      path={`${config.api_path}/v1/transparencia/categoria_publicacao_tipo`}
      rows={20}
      schema={schema}
      buttonBar={({ selected }) => (
        <>
          <Row>
            <Button onClick={() => subir(selected)}>
              <FontAwesomeIcon icon={faArrowUp} />
              Subir
            </Button>
            <Col>
              <Button onClick={() => descer(selected)}>
                <FontAwesomeIcon icon={faArrowDown} />
                Descer
              </Button>
            </Col>
          </Row>
        </>
      )}
      form={({ handleChange, values, errors }) => (
        <Fragment>
          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <Input.Text id='nome' value={values.nome} onChange={handleChange} isInvalid={errors.nome} />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.nome}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <FormLabel>Nome no plural</FormLabel>
                <InputGroup>
                  <Input.Text
                    id='nomePlural'
                    value={values.nomePlural}
                    onChange={handleChange}
                    isInvalid={errors.nomePlural}
                  />
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.nomePlural}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group>
                <FormLabel>Categoria Publicação</FormLabel>
                <Input.AsyncSelect
                  id='categoriaPublicacao'
                  value={values.categoriaPublicacao}
                  onChange={handleChange}
                  isInvalid={errors.categoriaPublicacao}
                  path={`${config.api_path}/v1/transparencia/categoria_publicacao`}
                  label={'${nome}'}
                  rowKey='id'
                  nullable
                  placeholder='Selecione uma categoria'
                />
                <Form.Control.Feedback type='invalid'>{errors.categoriaPublicacao}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
        </Fragment>
      )}
    ></Crud>
  );
}

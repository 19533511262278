import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function Paginator({ page, setPage, lastPage, loading }) {
  return (
    
        <div className="d-flex w-100 justify-content-center">
            <Pagination>
                { 
                    loading ?
                    <Pagination.Item disabled><FontAwesomeIcon icon="cog" spin/> Carregando...</Pagination.Item>
                    : lastPage <= 0 ?
                    <Pagination.Item disabled>Nenhum registro encontrado</Pagination.Item>
                    :
                    <>
                        <Pagination.First onClick={() => setPage(1)} disabled={loading || page === 1}/>
                        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={loading || page === 1}/>
                        <Pagination.Item disabled>{page} de {lastPage}</Pagination.Item>
                        <Pagination.Next onClick={() => setPage(page+1)} disabled={loading || (page >= lastPage)}/>
                        <Pagination.Last onClick={() => setPage(lastPage)} disabled={loading || (page >= lastPage)}/>
                    </>
                }
            </Pagination>
        </div>

  )
}
